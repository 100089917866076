import React, { Component } from 'react';
import { Trans, WithTranslation } from 'react-i18next';
import { TeamDTO } from '../../../common/api/dtos/Team';
import dayjsHelper from '../../../common/helpers/dayjsHelper';
import { withStyledTranslation } from '../StyledTranslation/StyledTranslation';
import SubscriptionMessage from '../Billing/SubscriptionMessage';

interface Props extends WithTranslation {
  data: TeamDTO | null;
  status: string;
  canceled?: string;
  history: any;
}

interface State {}

class TeamPlanRow extends Component<Props, State> {
  constructor(props: Props) {
    super(props);
  }

  renderBadge = () => {
    const { t, data, canceled } = this.props;

    return (
      <ul className="control-list-component">
        <li>
          <span className="badge-text accent-blue">
            <span className="text-sm">{t('teamPlans.teamBadge')}</span>
          </span>
        </li>
        <li>
          {data?.subscription.deleteAt ? (
            <span className="flag-text accent-red">
              <span className="text-sm">{t('teamPlans.expiredBadge')}</span>
            </span>
          ) : canceled || data?.subscription.cancelAt ? (
            <span className="flag-text accent-yellow">
              <span className="text-sm">{t('teamPlans.cancelledBadge')}</span>
            </span>
          ) : (
            <span className="flag-text accent-green">
              <span className="text-sm">{t('teamPlans.activeBadge')}</span>
            </span>
          )}
        </li>
      </ul>
    );
  };

  render() {
    const { t } = this.props;
    const { data, canceled } = this.props;

    return (
      <ul className="control-list-component vertical">
        <li>
          <span>
            <Trans
              i18nKey="subscriptions:teamPlans.planStatusLabel"
              values={{
                teamName: data?.name || '',
              }}
              components={{ strong: <strong /> }}
            />
          </span>
        </li>
        <li>{this.renderBadge()}</li>
        {data?.subscription.deleteAt ? (
          <>
            <li>
              <SubscriptionMessage badge="danger">
                <Trans
                  i18nKey="subscriptions:teamPlans.expirationWarning"
                  values={{
                    deleteDate: dayjsHelper(data?.subscription.deleteAt).format(
                      'MMMM Do, YYYY',
                    ),
                  }}
                  components={{ strong: <strong /> }}
                />
              </SubscriptionMessage>
            </li>
            <li className="pt-xs">
              <form
                target="_blank"
                method="POST"
                action={`${process.env.REACT_APP_API_BASE_URL}/team/${data?.id}/checkout`}
              >
                <button
                  className="secondary-button"
                  disabled={!Boolean(data?.owned)}
                >
                  <span className="text">
                    {t('teamPlans.reactivateButton')}
                  </span>
                  <span className="far fa-external-link icon"></span>
                </button>
              </form>
            </li>
          </>
        ) : canceled || data?.subscription.cancelAt ? (
          <>
            <li>
              <SubscriptionMessage badge="warning">
                <Trans
                  i18nKey="subscriptions:teamPlans.cancellationNotice"
                  values={{
                    expirationDate: dayjsHelper(
                      data?.subscription.cancelAt,
                    ).format('MMMM Do, YYYY'),
                  }}
                  components={{ strong: <strong /> }}
                />
              </SubscriptionMessage>
            </li>
            <li>
              <form
                target="_blank"
                method="POST"
                action={`${process.env.REACT_APP_API_BASE_URL}/team/${data?.id}/changeBilling`}
              >
                <button
                  className="secondary-button"
                  disabled={!Boolean(data?.owned)}
                >
                  <span className="text">
                    {t('teamPlans.manageSubscriptionButton')}
                  </span>
                  <span className="far fa-external-link icon"></span>
                </button>
              </form>
            </li>
          </>
        ) : (
          <>
            <li>
              <form
                target="_blank"
                method="POST"
                action={`${process.env.REACT_APP_API_BASE_URL}/team/${data?.id}/changeBilling`}
              >
                <button
                  className="secondary-button"
                  disabled={!Boolean(data?.owned)}
                >
                  <span className="text">
                    {t('teamPlans.manageSubscriptionButton')}
                  </span>
                  <span className="far fa-external-link icon"></span>
                </button>
              </form>
            </li>
          </>
        )}
      </ul>
    );
  }
}

export default withStyledTranslation('subscriptions')(TeamPlanRow);
