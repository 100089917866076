import './vendors/wavemyth/conduit/scss/borddo.scss';
import React, { Component } from 'react';
import Login from './components/pages/Auth/Login/Login';
import {
  NavLink,
  Redirect,
  Route,
  RouteComponentProps,
  Switch,
} from 'react-router-dom';
import Topbar from './components/partials/Topbar/Topbar';
import { TRequestStatus } from './common/types/RequestStatus';
import { getLoggedUser, logout } from './common/api/endpoints/user';
import AppContext, * as Context from './common/contexts/AppContext';
import Registration from './components/pages/Auth/Registration/Registration';
import Home from './components/pages/Home/Home';
import Account from './components/pages/User/Account/Account';
import AppSettings from './components/pages/User/Settings/AppSettings';
import BoardAdd from './components/pages/Board/BoardAdd/BoardAdd';
import BoardArchive from './components/pages/Board/BoardArchive/BoardArchive';
import BoardDetail from './components/pages/Board/BoardDetail/BoardDetail';
import ChangePass from './components/pages/Auth/ResetPass/ResetPass';
import ForgotPass from './components/pages/Auth/ForgotPass/ForgotPass';
import BoardArchiveCards from './components/pages/Board/BoardArchive/BoardArchiveCards';
import BoardSettings from './components/pages/Board/BoardSettings/BoardSettings';
import Notification from './components/partials/Notification/Notification';
import { UserDTO } from './common/api/dtos/User';
import {
  ANONYMOUS_ROUTES,
  SESSION_VALIDATE_INTERVAL_MS,
} from './common/configs/appDefaults';
import { dynamicFavicon } from './common/helpers/dynamicFavicon';
import PageMessage from './components/partials/PageMessage/PageMessage';
import AccountConfirmation from './components/pages/Auth/AccountConfirmation/AccountConfirmation';
import {
  getUserSettings,
  updateUserSettings,
} from './common/api/endpoints/userSettings';
import { showErrorNotifications } from './common/helpers/showNotifications';
import ResendConfirmation from './components/pages/Auth/ResendConfirmation/ResendConfirmation';
import Tooltip from './components/controls/ContextMenu/Tooltip';
import BoardProvider from './components/pages/Board/BoardProvider/BoardProvider';
import ConfirmEmailChange from './components/pages/User/Account/ConfirmEmailChange';
import ConfirmAccountDeletion from './components/pages/User/Account/ConfirmAccountDeletion';
import TeamAdd from './components/pages/Team/TeamAdd/TeamAdd';
import LoginModal from './components/pages/Auth/LoginModal/LoginModal';
import TeamSettings from './components/pages/Team/TeamSettings/TeamSettings';
import ErrorNotFound from './components/pages/Error/ErrorNotFound';
import TeamInvitation from './components/partials/Team/TeamInvitation';
import Onboarding from './components/pages/Auth/Onboarding/Onboarding';
import Tutorial from './components/partials/Onboarding/Tutorial/Tutorial';
import i18n from './i18n';
import { IClientData } from './common/interfaces/ClientData';
import {
  NotificationsProvider,
  NotificationMessage,
} from './common/contexts/NotificationsContext';
import { IBoard } from './common/interfaces/BoardContext';

interface Props extends RouteComponentProps<any> {}

interface State {
  HTMLTag: HTMLElement | null;
  token?: string | null;
  sessionExpirationMs?: string | null;
  loggedUser?: UserDTO;
  appLogo: string;
  status: TRequestStatus;
  accent: string;
  completedOnboarding: boolean;
  sessionExpired: boolean;
  disconnected: boolean;
  pathChanged: boolean;
  appContext: Context.IAppContext;
  location: {
    prevPath: string;
  };
  transitioning: boolean;
  showTranslucency: boolean;
  isDragging: boolean;
  lockedAccount: boolean;
  deleteAt: string;
}

class App extends Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      ...this.getLocalStorageData(),
      ...this.createDefaultState(),
    };
  }

  private createDefaultState = (): State => {
    return {
      HTMLTag: document.querySelector('html'),
      appLogo: '',
      status: 'loading' as TRequestStatus,
      accent: '',
      completedOnboarding: false,
      sessionExpired: true,
      disconnected: false,
      pathChanged: false,
      appContext: {
        color: undefined,
        highContrast: false,
        theme: 'light',
        showPatterns: false,
        pattern: undefined,
        hour: undefined,
        language: 'en_US',
        loggedUser: undefined,
        notifications: {
          messages: [],
          setMessages: this.setNotifications,
        },
        appSettings: Context.computeAppSettings(),
        setLoggedUser: this.setLoggedUser,
        setDirectionalButtonId: this.setDirectionalButtonId,
        updateLoggedUser: this.updateLoggedUser,
        setIsDragging: this.setIsDragging,
        updateClientData: this.updateClientData,
      },
      location: {
        prevPath: '',
      },
      transitioning: false,
      showTranslucency: true,
      isDragging: false,
      lockedAccount: false,
      deleteAt: '',
    };
  };

  computeConduit = (board?: IBoard): string => {
    return [
      'CONDUIT',
      this.evaluateTheme(),
      Boolean(board && board.color)
        ? `${board?.color!}-accent has-board-context`
        : `green-accent`,
      this.state.appContext.appSettings.reducedMotion ? 'reduced-motion' : '',
      this.state.appContext.appSettings.colorBlindHelpers
        ? 'color-blind-helpers'
        : '',
      !this.state.isDragging ? '' : 'is-dragging',
      this.state.showTranslucency &&
      this.state.appContext.loggedUser?.localSettings.experiments!
        .showTranslucency === true
        ? ''
        : 'out-of-focus',
    ].join(' ');
  };

  handleContextMenu = (e: MouseEvent) => {
    e.preventDefault(); // Prevent the default context menu
  };

  computeLanguage = () => {
    i18n.changeLanguage(this.state.appContext.appSettings.language);
  };

  componentDidMount() {
    this.state.HTMLTag!.className = this.computeConduit();
    document.addEventListener('contextmenu', this.handleContextMenu, true);

    this.unlockApplication();
    this.closeSession();

    window.addEventListener('focus', () => {
      this.setState({
        showTranslucency: true,
      });
    });

    window.addEventListener('blur', () => {
      this.setState({
        showTranslucency: false,
      });
    });

    window.addEventListener('logout', () => {
      if (!localStorage.getItem('session_expiration_ms')) {
        this.handleLogout();
        this.setState({
          disconnected: true,
          sessionExpired: true,
        });
      }
    });

    this.props.history.listen((location, action) => {
      if (action === 'POP' && this.state.disconnected) {
        this.setState({
          pathChanged: true,
        });
      }
    });
  }

  componentDidUpdate(prevProps: Readonly<Props>, prevState: Readonly<State>) {
    if (
      this.state.appContext.appSettings !== prevState.appContext.appSettings
    ) {
      this.state.HTMLTag!.className = this.computeConduit();
      this.computeLanguage();
    }
    // if (this.state.showTranslucency !== prevState.showTranslucency) {
    //   this.state.HTMLTag!.className = this.computeConduit();
    // }
    if (
      prevProps.location.pathname !== this.props.location.pathname &&
      !this.props.location.pathname.includes('/board/')
    ) {
      let favicon: HTMLLinkElement | null =
        document.querySelector("link[rel~='icon']");
      favicon!.href = dynamicFavicon('');
      document.title = 'borddo - create tickets, track work, finish tasks';
      let metaTag = document.querySelector("meta[name='description']");
      if (metaTag !== null) {
        metaTag.setAttribute('content', 'borddo by wavemyth');
      }
    }
    if (this.props.location.pathname !== prevProps.location.pathname) {
      // window.scrollTo(window.scrollX, 0);
    }
  }

  componentWillUnmount(): void {
    //Unlisten history on unmount
    this.props.history.listen((location) => {});
    window.removeEventListener('focus', () => {});
    document.removeEventListener('contextmenu', this.handleContextMenu, true);

    window.removeEventListener('blur', () => {});
  }

  isPasswordExpired = (loggedUser: UserDTO): boolean => {
    return false; // false for now, as we don't have password details in eschere

    // const now = (new Date()).getTime();
    // const lastPasswordUpdate = loggedUser.lastPasswordUpdate ? new Date(loggedUser.lastPasswordUpdate!).getTime() : 0;
    // if(now - lastPasswordUpdate > parseInt(process.env.REACT_APP_PASS_MAX_TIME || "0") || loggedUser.mustUpdatePassword) {
    //   return true;
    // }
    // return false;
  };

  private getLocalStorageData() {
    return {
      sessionExpirationMs: localStorage.getItem('session_expiration_ms'),
    };
  }

  unlockApplication = () => {
    this.setState(
      {
        ...this.getLocalStorageData(),
      },
      async () => {
        if (Date.now() < Number(this.state.sessionExpirationMs)) {
          this.setState({
            sessionExpired: false,
            pathChanged: false,
          });

          const data = await this.fetchFirstTimeLoggedUser();

          if (data?.user.onboarding.completed) {
            await this.fetchLoggedUser();
          }
        } else {
          this.setState(
            {
              sessionExpired: true,
            },
            () => {
              this.handleLogout();
            },
          );
        }
      },
    );
  };

  lockApplication = () => {};

  handleLogout = async () => {
    let expiration = localStorage.getItem('session_expiration_ms');
    if (!expiration) return;

    localStorage.removeItem('session_expiration_ms');

    try {
      await logout();

      this.setState({
        ...this.getLocalStorageData(),
        ...this.createDefaultState(),
        loggedUser: undefined,
      });

      this.lockApplication();
    } catch (error) {
      console.error(error);
    }
  };

  setAccountSettings = async (settings: Partial<Context.AccountSettings>) => {
    try {
      const updatedSettings = await updateUserSettings(settings);
      this.setState((prevState) => {
        return {
          appContext: Context.updateAccountSettings(
            prevState.appContext,
            updatedSettings,
          ),
        };
      });
    } catch (error) {
      showErrorNotifications(error, this.setNotifications);
    }
  };

  setLocalSettings = (settings: Partial<Context.LocalSettings>) => {
    this.setState((prevState) => {
      return {
        appContext: Context.updateLocalSettings(prevState.appContext, settings),
      };
    });
  };

  fetchFirstTimeLoggedUser = async () => {
    this.setState({
      status: 'loading',
    });

    try {
      const data = await getLoggedUser();

      if (!data.user.onboarding.completed) {
        this.props.history.push('/onboarding');
      }

      if (data.user.locked && data.user.deleteAt) {
        this.setState({
          lockedAccount: true,
          deleteAt: data.user.deleteAt,
          status: 'success',
        });
        this.props.history.push('/');
      } else {
        this.setState({
          lockedAccount: false,
        });
      }

      this.setState({
        completedOnboarding: data.user.onboarding.completed,
        status: 'success',
      });

      return data;
    } catch (err) {
      console.debug(err);
    }
  };

  fetchLoggedUser = async () => {
    this.setState({
      status: 'loading',
    });

    try {
      const [data, userSettings] = await Promise.all([
        getLoggedUser(),
        getUserSettings(),
      ]);
      const user = data.user;

      i18n.changeLanguage(
        localStorage.getItem(user.id + '-language') || 'en_US',
      );

      const loggedUser: UserDTO = {
        id: user.id,
        email: user.email,
        name: user.name,
        avatar: user.avatar,
        emailConsent: user.emailConsent,
        confirmed: user.confirmed,
        accountSettings: {
          theme: userSettings.theme,
          highContrast: userSettings.highContrast,
          showPatterns: false,
          reducedMotion: userSettings.reducedMotion,
          colorBlindHelpers: userSettings.colorBlindHelpers,
          timeFormat: userSettings.timeFormat,
          language: userSettings.language,
        },
        onboarding: user.onboarding,
        pendingChangeEmail: user.pendingChangeEmail,
        pendingDelete: user.pendingDelete,
        subscription: user.subscription,
        clientData: user.clientData,
      };

      this.setState((prevState) => {
        const newCtx = {
          ...prevState.appContext,
          loggedUser: {
            ...loggedUser,
            settings: {
              ...loggedUser.accountSettings,
            },
            localSettings: Context.computeLocalSettings(user.id),
            setAccountSettings: this.setAccountSettings,
            setLocalSettings: this.setLocalSettings,
          },
        };
        newCtx.appSettings = Context.computeAppSettings(newCtx.loggedUser);

        return {
          status: 'success',
          appContext: newCtx,
          accent: 'green-accent',
          loggedUser,
        };
      });
    } catch (error) {
      console.error(error);
    }
  };

  setSessionExpired = (sessionExpired: boolean) => {
    this.setState({
      sessionExpired: sessionExpired,
    });
  };

  setLockedAccount = (lockedAccount: boolean) => {
    this.setState({
      lockedAccount,
    });
  };

  closeSession() {
    setInterval(() => {
      let expiration = localStorage.getItem('session_expiration_ms');
      if (expiration && Date.now() > Number(expiration)) {
        this.handleLogout();
      }
    }, SESSION_VALIDATE_INTERVAL_MS);
  }

  evaluateTheme(): string {
    if (this.state.appContext.appSettings.highContrast) {
      return 'high-contrast';
    }

    if (this.state.appContext.appSettings.theme === 'system') {
      return window.matchMedia &&
        window.matchMedia('(prefers-color-scheme: dark)').matches
        ? 'dark-theme'
        : 'light-theme';
    } else {
      return this.state.appContext.appSettings.theme;
    }
  }

  setLoggedUser = (loggedUser: UserDTO) => {
    this.setState((prevState) => {
      return {
        loggedUser,
        appContext: {
          ...prevState.appContext,
          loggedUser: {
            ...prevState.appContext.loggedUser!,
            ...loggedUser,
          },
        },
      };
    });
  };

  setIsDragging = (value: boolean) => {
    this.setState({
      isDragging: value,
    });
  };

  updateLoggedUser = (newUserData: Partial<UserDTO>) => {
    this.setState((prevState) => {
      return {
        loggedUser: {
          ...prevState.loggedUser!,
          ...newUserData,
        },
        appContext: {
          ...prevState.appContext,
          loggedUser: {
            ...prevState.appContext.loggedUser!,
            ...newUserData,
          },
        },
      };
    });
  };

  updateClientData = (newClientData: Partial<IClientData>) => {
    this.setState((prevState) => {
      return {
        loggedUser: {
          ...prevState.loggedUser!,
          clientData: newClientData,
        },
        appContext: {
          ...prevState.appContext,
          loggedUser: {
            ...prevState.appContext.loggedUser!,
            clientData: newClientData,
          },
        },
      };
    });
  };

  removeLoggedUser = () => {
    this.setState((prevState) => {
      return {
        loggedUser: undefined,
        appContext: {
          ...prevState.appContext,
          loggedUser: undefined,
        },
      };
    });
  };

  setNotifications = (
    messages: NotificationMessage | NotificationMessage[],
  ) => {
    const newStatusList = Array.isArray(messages) ? messages : [messages];

    this.setState((prevState: State) => {
      const mergedStatusMessages = [
        ...prevState.appContext.notifications.messages,
        ...newStatusList,
      ];

      // in case we want unique errors (no duplicates in the list) - in this case the unique id should be the api endpoint
      // keep this code until after demo / POC
      // const uniqueServerErrors = mergedServerErros.filter((serverError, index) => {
      //   // comparing string object in order to remove duplicates
      //   // this is a  *brute* solution for performance
      //   // TODO: should revisit in case performance is bad
      //   const stringServerError = JSON.stringify(serverError);
      //   const existingServerErrorIndex = mergedServerErros.findIndex(serverError => {
      //     return JSON.stringify(serverError) === stringServerError;
      //   })

      //   return index === existingServerErrorIndex;
      // });

      return {
        appContext: {
          ...prevState.appContext,
          notifications: {
            ...prevState.appContext.notifications,
            messages: mergedStatusMessages,
          },
        },
      };
    });
  };

  clearStatusMessage = (id: string) => {
    this.setState((prevState) => {
      const messages = prevState.appContext.notifications.messages;
      const positionToRemove = messages.findIndex(
        (message) => message.id === id,
      );
      messages.splice(positionToRemove, 1);

      return {
        appContext: {
          ...prevState.appContext,
          notifications: {
            ...prevState.appContext.notifications,
            messages: messages,
          },
        },
      };
    });
  };

  setDirectionalButtonId = (id: string) => {
    this.setState((prevState) => {
      if (prevState.appContext.directionalButtonId !== id) {
        return {
          appContext: {
            ...prevState.appContext,
            directionalButtonId: id,
          },
        };
      } else {
        return {
          appContext: prevState.appContext,
        };
      }
    });
  };

  renderAuthRouter = () => {
    const boardPath = '/board/:routeBoardId';
    return (
      <Switch>
        {Boolean(localStorage.getItem('session_expiration_ms')) && (
          <Route
            exact
            path="/"
          >
            <Home
              lockedAccount={this.state.lockedAccount}
              deleteAt={this.state.deleteAt}
              unlockApplication={this.unlockApplication}
              setLockedAccount={this.setLockedAccount}
            />
          </Route>
        )}
        <Route
          exact
          path="/confirm/:token"
          render={(props: RouteComponentProps<{ token: string }>) => {
            return <AccountConfirmation {...props} />;
          }}
        />
        <Route
          exact
          path="/login"
          render={() => {
            return <Redirect to="/" />;
          }}
        />
        <Route
          exact
          path="/team-add"
          render={(props: RouteComponentProps) => {
            return <TeamAdd {...props} />;
          }}
        />
        <Route
          exact
          path={['/board-add', '/board-add/:id/:name']}
          render={(props: RouteComponentProps<any>) => {
            return <BoardAdd {...props} />;
          }}
        />
        <Route
          exact
          path="/unknown-error"
          render={(props: RouteComponentProps) => {
            return (
              <PageMessage>
                <h1 className="primary-title normalcase pb-xs">
                  Something went wrong
                </h1>
                <br />
                <p>An unknown error has occurred.</p>
                <ul className="control-list-component flex-h-center">
                  <li>
                    <NavLink
                      to="/"
                      className={'secondary-button'}
                    >
                      Return to homepage
                    </NavLink>
                  </li>
                </ul>
              </PageMessage>
            );
          }}
        />
        <Route
          path={boardPath}
          render={(props: RouteComponentProps<{ routeBoardId: string }>) => {
            return (
              <BoardProvider
                computeConduit={this.computeConduit}
                HTMLTag={this.state.HTMLTag}
                {...props}
              >
                <Switch>
                  <Route
                    exact
                    path={[
                      `${boardPath}/board-archived-cards`,
                      `${boardPath}/board-archived-cards/view/card/:cardId?/:cardSlug?`,
                    ]}
                    render={(
                      props: RouteComponentProps<{ routeBoardId: string }>,
                    ) => {
                      return <BoardArchiveCards {...props} />;
                    }}
                  />
                  <Route
                    path={`${boardPath}/board-settings`}
                    render={(
                      props: RouteComponentProps<{ routeBoardId: string }>,
                    ) => {
                      return <BoardSettings {...props} />;
                    }}
                  />
                  <Route
                    exact
                    path={boardPath}
                    render={({
                      match,
                    }: RouteComponentProps<{ routeBoardId: string }>) => (
                      <Redirect
                        to={`/board/${match.params.routeBoardId}/view`}
                      />
                    )}
                  />
                  <Route
                    exact
                    path={[
                      `${boardPath}/view/:boardSlug?`,
                      `${boardPath}/view/card/:cardId?/:cardSlug?`,
                      `${boardPath}/invite`,
                    ]}
                    render={(
                      props: RouteComponentProps<{ routeBoardId: string }>,
                    ) => {
                      return (
                        <BoardDetail
                          // This only gets rendered once we have the logged user
                          loggedUser={this.state.loggedUser!}
                          {...props}
                        />
                      );
                    }}
                  />
                  <Route
                    path="/not-found"
                    render={() => <ErrorNotFound />}
                  ></Route>
                </Switch>
              </BoardProvider>
            );
          }}
        />
        <Route
          path="/account"
          render={(props) =>
            this.state.lockedAccount ? (
              <Redirect to="/" />
            ) : (
              <Account
                setLoggedUser={this.setLoggedUser}
                loggedUser={this.state.loggedUser!}
                setLockedAccount={this.setLockedAccount}
                lockedAccount={this.state.lockedAccount}
                {...props}
              />
            )
          }
        />
        <Route
          path={['/team/:id/invite']}
          render={(props: RouteComponentProps<{ id: string }>) => (
            <TeamInvitation {...props} />
          )}
        />
        <Route
          path={['/team/:id']}
          render={(props: RouteComponentProps<{ id?: string }>) => (
            <TeamSettings {...props} />
          )}
        />
        <Route
          exact
          path={['/change-email/:token']}
          render={(props: RouteComponentProps<{ token: string }>) => (
            <ConfirmEmailChange
              setLoggedUser={this.setLoggedUser}
              {...props}
            />
          )}
        />
        <Route
          exact
          path={['/delete-account/:token']}
          render={(props: RouteComponentProps<{ token: string }>) => (
            <ConfirmAccountDeletion
              removeLoggedUser={this.removeLoggedUser}
              setSessionExpired={this.setSessionExpired}
              {...props}
            />
          )}
        />
        <Route
          path="/settings"
          render={(props) =>
            this.state.lockedAccount ? (
              <Redirect to="/" />
            ) : (
              <AppSettings {...props} />
            )
          }
        />
        <Route path="*">
          {/* redirect to "/" only if we are logged in and NOT on article routes */}
          {ANONYMOUS_ROUTES.includes(this.props.location.pathname) && (
            <Redirect to="/" />
          )}
          {!ANONYMOUS_ROUTES.includes(this.props.location.pathname) &&
            !this.state.lockedAccount && (
              <PageMessage>
                <h1 className="primary-title normalcase pb-xs">Error 404</h1>
                <br />
                <p>Resource not found.</p>
                <ul className="control-list-component flex-h-center">
                  <li>
                    <NavLink
                      to="/"
                      className={'secondary-button'}
                    >
                      Return to homepage
                    </NavLink>
                  </li>
                </ul>
              </PageMessage>
            )}
        </Route>
      </Switch>
    );
  };

  render() {
    return (
      <NotificationsProvider>
        <AppContext.Provider value={this.state.appContext}>
          <React.Fragment>
            <Tooltip />
            <div>
              <div className="tooltip-structure"></div>
              <div className="context-menu-structure"></div>
              <div className="dropdown-structure"></div>
              <div className="dialog-structure"></div>
              {!this.state.sessionExpired &&
                this.state.status === 'loading' && (
                  <div className="card-board-component">
                    <span className="loader mt-md flex-h-center-self text-2xl"></span>
                  </div>
                )}
              {this.state.sessionExpired && this.state.disconnected && (
                <>
                  <LoginModal
                    unlockApp={this.unlockApplication}
                    {...this.props}
                  />
                  <Route path="*">
                    {this.state.pathChanged && <Redirect to="/" /> &&
                      this.setState({ disconnected: false })}
                  </Route>
                </>
              )}
              {this.state.sessionExpired &&
                !this.state.disconnected &&
                !ANONYMOUS_ROUTES.some((route) =>
                  this.props.location.pathname.includes(route),
                ) && (
                  // <div className="oobe-component">
                  // <div className="oobe-inner">
                  <Login
                    unlockApp={this.unlockApplication}
                    {...this.props}
                  />
                  // </div>
                  // </div>
                )}
              {this.state.sessionExpired && (
                <Switch>
                  <Route
                    exact
                    path="/registration"
                    render={(props: RouteComponentProps) => {
                      return (
                        <Registration
                          unlockApp={this.unlockApplication}
                          {...props}
                        />
                      );
                    }}
                  />
                  <Route
                    exact
                    path="/resend-confirmation"
                    render={(props: RouteComponentProps) => {
                      return <ResendConfirmation {...props} />;
                    }}
                  />
                  <Route
                    exact
                    path="/forgot-password"
                    render={(props: RouteComponentProps) => {
                      return <ForgotPass {...props} />;
                    }}
                  />
                  <Route
                    exact
                    path="/reset-password/:token"
                    render={(props: RouteComponentProps<{ token: string }>) => {
                      return <ChangePass {...props} />;
                    }}
                  />
                </Switch>
              )}

              {((!this.state.sessionExpired &&
                Boolean(this.state.loggedUser) &&
                this.state.completedOnboarding) ||
                (this.state.sessionExpired && this.state.disconnected)) && (
                <React.Fragment>
                  {Boolean(this.state.appContext.loggedUser) && (
                    <>
                      {!ANONYMOUS_ROUTES.includes(
                        this.props.location.pathname,
                      ) ? (
                        <header className="topbar-component hide-print">
                          <div className="corner corner-left">
                            <div className="corner-inner"></div>
                          </div>
                          <div className="corner corner-right">
                            <div className="corner-inner"></div>
                          </div>
                          <div className="bar-content">
                            <Topbar
                              lockedAccount={this.state.lockedAccount}
                              handleLogout={this.handleLogout}
                            />
                          </div>
                        </header>
                      ) : (
                        <header className="topbar-component hide-print">
                          <div className="bar-content">
                            <div
                              id="toolbarControls"
                              className="toolbar-component bar-content-start"
                            >
                              <a
                                href="/"
                                className="brand-wrapper"
                              >
                                <div
                                  className={`dynamic-logo-component ${process.env.REACT_APP_FAVICON}`}
                                ></div>
                                {/* <img
                                alt="logo"
                                className="brand brand-contain-v"
                                src={
                                  process.env.PUBLIC_URL +
                                  '/' +
                                  process.env.REACT_APP_LOGO
                                }
                              /> */}
                              </a>
                            </div>
                          </div>
                        </header>
                      )}
                      <main className="main-component">
                        <div id="imageViewer"></div>
                        {!this.state.lockedAccount &&
                          this.state.status !== 'loading' && <Tutorial />}
                        <div className="pt-2xs">{this.renderAuthRouter()}</div>
                      </main>
                    </>
                  )}
                </React.Fragment>
              )}

              {!this.state.completedOnboarding && (
                <Switch>
                  <Route
                    exact
                    path="/onboarding"
                    render={(props: RouteComponentProps) => {
                      return (
                        <Onboarding
                          handleLogout={this.handleLogout}
                          unlockApp={this.unlockApplication}
                          {...props}
                        />
                      );
                    }}
                  />
                </Switch>
              )}
              <div
                className="toaster-component"
                style={{ overflowY: 'auto', maxHeight: '100vh - 48px' }}
              >
                {this.state.appContext.notifications.messages.map(
                  (notification, index) => {
                    return (
                      <Notification
                        key={notification.id}
                        id={index}
                        notificationData={notification}
                        onDestroy={() =>
                          this.clearStatusMessage(notification.id)
                        }
                      />
                    );
                  },
                )}
              </div>
            </div>
          </React.Fragment>
        </AppContext.Provider>
      </NotificationsProvider>
    );
  }
}

export default App;
