import { InfoNotificationPayload } from './infoNotificationPayload';
import { showInfoNotifications } from './showNotifications';

export function copyToClipboard(
  text: string,
  info?: string,
  setMessages?: (messages: InfoNotificationPayload[]) => void,
) {
  try {
    navigator.clipboard.writeText(text);

    if (info && setMessages) {
      showInfoNotifications(info, setMessages);
    }
  } catch (error) {
    console.error(
      'Copy to clipboard failed. The caller does not have permission to write to the clipboard.',
    );
  }
}
