export const CARD_DATA_LABELS = {
  priority: 'priority',
  cardNumber: 'cardNumber',
  description: 'description',
  subtask: 'subtask',
  subtaskProgress: 'subtaskProgress',
  attachment: 'attachment',
  comment: 'comment',
  member: 'member',
  tag: 'tag',
};

export const CARD_DATA_ICONS = {
  priority: 'far fa-circle',
  cardNumber: 'far fa-hashtag',
  description: 'far fa-align-left',
  subtask: 'far fa-tasks',
  subtaskProgress: 'far fa-water-rise',
  attachment: 'far fa-paperclip',
  comment: 'far fa-comment',
  member: 'far fa-user',
  tag: 'far fa-tag',
};
