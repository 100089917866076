import React from 'react';
import PriorityMenu from './PriorityMenu';
import PriorityOperationsMenu from './PriorityOperationsMenu';
import { TContextMenu } from '../../../common/types/ContextMenu';
import ContextMenu from '../../controls/ContextMenu/ContextMenu';
import { TContextAlignment } from '../../../common/types/ContextAlignment';
import { PriorityDTO } from '../../../common/api/dtos/Priority';
import PriorityDetailMenu from './PriorityDetailMenu';
import { getColorSymbol } from '../../../common/helpers/getColorSymbol';
import { PRIORITY_COLORS } from '../../../common/configs/priority';
import { WithTranslation } from 'react-i18next';
import { withStyledTranslation } from '../../partials/StyledTranslation/StyledTranslation';
import { TColor } from '../../../common/types/Color';

interface Props extends WithTranslation {
  disabled: boolean;
  contextMenuClassName: TContextAlignment;
  handleSelectPriority?: (priority: PriorityDTO | null) => void;
  cardId?: string;
  selectedPriority?: PriorityDTO | null;
  triggerClassDefault?: string;
  triggerClassActive?: string;
}

interface State {
  selectedContext: TContextMenu;
  selectedPriority: PriorityDTO | null;
}

class PriorityContext extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      selectedContext: 'priorities',
      selectedPriority: null,
    };
  }

  setSelectedContext = (context: TContextMenu) => {
    this.setState({
      selectedContext: context,
    });
  };

  setSelectedPriority = (priority: PriorityDTO) => {
    this.setState({
      selectedPriority: priority,
    });
  };

  evaluateDept = (): number => {
    switch (this.state.selectedContext) {
      case 'priorities':
        return 0;
      case 'managePriorities':
        return 1;
      case 'editPriority':
      case 'addPriority':
        return 2;
      default:
        return 0;
    }
  };

  render() {
    const { t } = this.props;
    const priority: TColor | null = this.props.selectedPriority
      ? PRIORITY_COLORS.find(
          (p) => p.id === this.props.selectedPriority?.color,
        ) ?? null
      : null;
    const contextComponents: { [key: string]: React.ReactElement } = {
      priorities: (
        <>
          <PriorityMenu
            directionalButton={{
              text: t('managePriorities'),
              direction: 'right',
              onClick: () => this.setSelectedContext('managePriorities'),
            }}
            handleSelectPriority={this.props.handleSelectPriority}
            selectedPriority={this.props.selectedPriority?.id || null}
            cardId={this.props.cardId}
          />
        </>
      ),
      managePriorities: (
        <PriorityOperationsMenu
          directionalButton={{
            text: t('backToPriorities'),
            direction: 'left',
            onClick: () => this.setSelectedContext('priorities'),
          }}
          fixedPositionParent
          setSelectedContext={this.setSelectedContext}
          setSelectedPriority={this.setSelectedPriority}
        />
      ),
      addPriority: (
        <PriorityDetailMenu
          directionalButton={{
            text: t('backToManagePriorities'),
            direction: 'left',
            onClick: () => this.setSelectedContext('managePriorities'),
          }}
          selectedContext={this.state.selectedContext}
          setSelectedContext={this.setSelectedContext}
          legendText={t('addPriority')}
        />
      ),
      editPriority: (
        <PriorityDetailMenu
          directionalButton={{
            text: t('backToManagePriorities'),
            direction: 'left',
            onClick: () => this.setSelectedContext('managePriorities'),
          }}
          selectedContext={this.state.selectedContext}
          setSelectedContext={this.setSelectedContext}
          selectedPriority={this.state.selectedPriority}
          legendText={t('editPriority')}
        />
      ),
    };
    return (
      <ContextMenu
        dept={this.evaluateDept()}
        context={this.state.selectedContext}
        resetSelectedContext={() => this.setSelectedContext('priorities')}
        contextMenuClassName={this.props.contextMenuClassName}
        title={t('changePriority')}
        forceLTR={true}
        triggerClassDefault={
          this.props.triggerClassDefault
            ? this.props.triggerClassDefault
            : 'ghost-button'
        }
        triggerClassActive={
          this.props.triggerClassDefault
            ? this.props.triggerClassDefault
            : 'secondary-button'
        }
        isDisabled={this.props.disabled}
        triggerContent={
          !priority ? (
            <span className="fal fa-circle"></span>
          ) : (
            <>
              <span
                className={`accent-text-${priority.color}`}
                title={this.props.selectedPriority?.name}
              >
                <span className={priority.symbol}></span>
              </span>
            </>
          )
        }
      >
        {contextComponents[this.state.selectedContext]}
      </ContextMenu>
    );
  }
}

export default withStyledTranslation('priorityContext')(PriorityContext);
