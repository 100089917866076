import React from 'react';

export default class HorizontalDrag extends React.Component {
  isDragging: boolean;
  lastX: number;
  lastY: number;
  scrollLeft: number;
  scrollTop: number;
  isRightClick: boolean;
  scrollVelocity: number;
  scrollTarget: number | null;
  isScrolling: boolean;
  animationFrameId: number;

  constructor(props: {}) {
    super(props);
    this.isDragging = false;
    this.lastX = 0;
    this.lastY = 0;
    this.scrollLeft = 0;
    this.scrollTop = 0;
    this.isRightClick = false;
    this.scrollVelocity = 0;
    this.scrollTarget = null;
    this.isScrolling = false;
    this.animationFrameId = 0;
  }

  handleMouseDown = (e: MouseEvent, element: HTMLElement) => {
    if (e.buttons === 2) {
      return;
    }

    if (e.buttons !== 1) return;

    let HTMLTag = document.querySelector('html') as HTMLElement;

    this.isDragging = true;
    this.lastX = e.clientX;
    this.lastY = e.clientY;
    this.scrollLeft = HTMLTag.scrollLeft;
    this.scrollTop = HTMLTag.scrollTop;
  };

  handleMouseUp = () => {
    if (this.isRightClick) {
      this.isRightClick = false;
      return;
    }
    if (this.isDragging) {
      this.isDragging = false;
      return;
    }
  };

  handleMouseLeave = () => {
    this.isDragging = false;
    this.isRightClick = false;
  };

  handleMouseMove = (e: MouseEvent) => {
    if (!this.isDragging) return;
    let HTMLTag = document.querySelector('html') as HTMLElement;
    const deltaX = e.clientX - this.lastX;
    const deltaY = e.clientY - this.lastY;
    // Set the new scroll position based on the mouse movement
    HTMLTag.scrollLeft = this.scrollLeft - deltaX;
    HTMLTag.scrollTop = this.scrollTop - deltaY;
  };

  handleWindowScroll = (e: WheelEvent) => {
    if (!this.isRightClick) return;

    e.preventDefault(); // Prevent the default scrolling behavior

    // Adjust scroll velocity based on wheel delta with throttling
    const delta = e.deltaY;
    this.scrollVelocity += delta * 0.05; // Smaller multiplier for finer control

    // Limit velocity to prevent excessive speeds
    const maxVelocity = 10; // Adjust for smoother speed
    this.scrollVelocity = Math.max(
      -maxVelocity,
      Math.min(this.scrollVelocity, maxVelocity),
    );

    // Start animation if not already running
    if (!this.isScrolling) {
      this.isScrolling = true;
      this.animateScroll();
    }
  };

  animateScroll = () => {
    let HTMLTag = document.querySelector('html') as HTMLElement;

    // Update the scroll position
    HTMLTag.scrollLeft += this.scrollVelocity;

    // Apply smoother friction for gradual slowdown
    const friction = 0.9; // Higher friction for more controlled deceleration
    this.scrollVelocity *= friction;

    // Cap the scroll position within the scrollable bounds
    const maxScrollLeft = HTMLTag.scrollWidth - HTMLTag.clientWidth;
    if (HTMLTag.scrollLeft < 0) {
      HTMLTag.scrollLeft = 0;
      this.scrollVelocity = 0;
    } else if (HTMLTag.scrollLeft > maxScrollLeft) {
      HTMLTag.scrollLeft = maxScrollLeft;
      this.scrollVelocity = 0;
    }

    // Continue animation if velocity is above a minimal threshold
    if (Math.abs(this.scrollVelocity) > 0.2) {
      this.animationFrameId = requestAnimationFrame(this.animateScroll);
    } else {
      this.isScrolling = false;
      this.scrollVelocity = 0;
    }
  };

  handleWindowMouseDown = (e: MouseEvent) => {
    if (e.buttons === 2) {
      e.preventDefault();
      this.isRightClick = true;
      return;
    }
  };

  handleWindowMouseUp = () => {
    if (this.isRightClick) {
      this.isRightClick = false;
      return;
    }
    if (this.isDragging) {
      this.isDragging = false;
      return;
    }
  };

  componentDidMount(): void {
    const scrollElement = document.querySelector(
      '.fe-scroll-container',
    ) as HTMLDivElement;
    const mainElement = document.querySelector(
      '.main-component',
    ) as HTMLDivElement;

    if (scrollElement) {
      scrollElement.addEventListener('mousedown', (e: MouseEvent) => {
        const elements = Array.from(
          document.querySelectorAll(
            '.board-column-card > [data-rbd-draggable-id], .list-drag-head',
          ),
        );

        // Check if the target element or any of its ancestors have the class 'drag-card-button'
        let isDraggingCard = elements.some((element) =>
          element.contains(e.target as Node),
        );

        // Prevent horizontal drag if dragging a card
        if (!isDraggingCard) {
          this.handleMouseDown(e, scrollElement);
        }
      });
    }

    window.addEventListener('wheel', this.handleWindowScroll, {
      passive: false,
    });

    window.addEventListener('mousedown', this.handleWindowMouseDown);
    window.addEventListener('mouseup', this.handleWindowMouseUp);

    if (mainElement) {
      mainElement.addEventListener('mouseup', this.handleMouseUp);
      mainElement.addEventListener('mouseleave', this.handleMouseLeave);

      // Add an event listener for when the user moves the mouse while dragging
      mainElement.addEventListener('mousemove', this.handleMouseMove);
    }
  }

  componentWillUnmount() {
    const scrollElement = document.querySelector(
      '.fe-scroll-container',
    ) as HTMLDivElement;
    const mainElement = document.querySelector(
      '.main-component',
    ) as HTMLDivElement;

    if (scrollElement) {
      scrollElement.removeEventListener('mousedown', (e: MouseEvent) =>
        this.handleMouseDown(e, scrollElement),
      );
    }

    window.removeEventListener('wheel', this.handleWindowScroll);
    window.removeEventListener('mousedown', this.handleWindowMouseDown);
    window.removeEventListener('mouseup', this.handleWindowMouseUp);

    if (mainElement) {
      mainElement.removeEventListener('mouseup', this.handleMouseUp);
      mainElement.removeEventListener('mouseleave', this.handleMouseLeave);
      mainElement.removeEventListener('mousemove', this.handleMouseMove);
    }

    // Cancel any ongoing animations
    if (this.animationFrameId) {
      cancelAnimationFrame(this.animationFrameId);
    }
  }

  render() {
    return <>{this.props.children}</>;
  }
}
