import React, { Component } from 'react';
import { cancelDeleteSelf } from '../../../common/api/endpoints/user';
import PageMessage from '../../partials/PageMessage/PageMessage';
import dayjsHelper from '../../../common/helpers/dayjsHelper';
import { TRequestStatus } from '../../../common/types/RequestStatus';
import { WithTranslation } from 'react-i18next';
import { withStyledTranslation } from '../../partials/StyledTranslation/StyledTranslation';
import Button from '../../controls/Button/Button';

interface Props extends WithTranslation {
  deleteAt: string;
  unlockApplication: () => void;
  setLockedAccount: (lockedAccount: boolean) => void;
  removeServerErrors: () => void;
}

interface State {
  cancelDeleteAccountStatus: TRequestStatus;
}

class DeletedAccount extends Component<Props, State> {
  cancelDeleteAccount = async (e: React.MouseEvent) => {
    e.preventDefault();
    this.setState({
      cancelDeleteAccountStatus: 'loading',
    });

    try {
      await cancelDeleteSelf();
      this.setState({
        cancelDeleteAccountStatus: 'success',
      });

      this.props.unlockApplication();
      this.props.setLockedAccount(false);
      this.props.removeServerErrors();
    } catch (err) {
      this.setState({
        cancelDeleteAccountStatus: 'error',
      });
      console.debug(err);
    }
  };

  render() {
    const { t } = this.props;

    return (
      <PageMessage>
        <h1 className="primary-title normalcase pb-xs">{t('title')}</h1>
        <br />
        <p>
          {t('description', {
            date: dayjsHelper(this.props.deleteAt).format('MMMM Do'),
          })}
        </p>
        <ul className="control-list-component flex-h-center">
          <li>
            <Button
              className={'secondary-button mb-sm'}
              onClick={this.cancelDeleteAccount}
            >
              {t('cancelButton')}
            </Button>
          </li>
        </ul>
        <p className="mb-sm">
          {t('supportText')}{' '}
          <a
            href="mailto:contact@borddo.com"
            className="link-button"
          >
            <span className="text">{t('supportEmail')} </span>
          </a>
        </p>
      </PageMessage>
    );
  }
}

export default withStyledTranslation('deletedAccount')(DeletedAccount);
