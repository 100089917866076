import {
  $getListDepth,
  $isListItemNode,
  $isListNode,
  ListNode,
  ListItemNode,
} from '@lexical/list';
import { useLexicalComposerContext } from '@lexical/react/LexicalComposerContext';
import {
  $getSelection,
  $isElementNode,
  $isRangeSelection,
  INDENT_CONTENT_COMMAND,
  COMMAND_PRIORITY_HIGH,
  RangeSelection,
  ElementNode,
} from 'lexical';
import { useEffect } from 'react';

const DEFAULT_MAX_DEPTH = 7;

function getElementNodesInSelection(
  selection: RangeSelection,
): Set<ElementNode> {
  const nodesInSelection = selection.getNodes();

  if (nodesInSelection.length === 0) {
    return new Set([
      selection.anchor.getNode().getParentOrThrow(),
      selection.focus.getNode().getParentOrThrow(),
    ]);
  }

  return new Set(
    nodesInSelection
      .map((n) => ($isElementNode(n) ? n : n.getParentOrThrow()))
      .filter((node): node is ElementNode => node !== null), // type guard for non-null nodes
  );
}

function isIndentPermitted(maxDepth: number): boolean {
  const selection = $getSelection();

  if (!$isRangeSelection(selection)) {
    return false;
  }

  const elementNodesInSelection = getElementNodesInSelection(selection);
  let totalDepth = 0;

  for (const elementNode of elementNodesInSelection) {
    if ($isListNode(elementNode)) {
      totalDepth = Math.max(
        $getListDepth(elementNode as ListNode) + 1,
        totalDepth,
      );
    } else if ($isListItemNode(elementNode)) {
      const parent = elementNode.getParent();
      if (!$isListNode(parent)) {
        throw new Error(
          'ListMaxIndentLevelPlugin: A ListItemNode must have a ListNode for a parent.',
        );
      }
      totalDepth = Math.max($getListDepth(parent as ListNode) + 1, totalDepth);
    }
  }

  return totalDepth <= maxDepth;
}

interface ListMaxIndentLevelPluginProps {
  maxDepth?: number;
}

export default function ListMaxIndentLevelPlugin({
  maxDepth = DEFAULT_MAX_DEPTH,
}: ListMaxIndentLevelPluginProps): null {
  const [editor] = useLexicalComposerContext();

  useEffect(() => {
    return editor.registerCommand(
      INDENT_CONTENT_COMMAND,
      () => {
        const isPermitted = isIndentPermitted(maxDepth);
        return !isPermitted;
      },
      COMMAND_PRIORITY_HIGH,
    );
  }, [editor, maxDepth]);

  return null;
}
