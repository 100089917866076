import React from 'react';

const CircleOff: React.FC = () => {
  return (
    <svg
      width="1"
      height="1"
      viewBox="0 0 24 24"
      xmlns="http://www.w3.org/2000/svg"
      preserveAspectRatio="xMidYMid meet"
      className="icon"
    >
      <circle
        cx="12"
        cy="12"
        r="6"
        stroke="currentColor"
        strokeWidth="2"
        className="circle_off_circle"
        strokeDasharray="48"
        strokeDashoffset="48"
      />
      <style>
        {`
          .circle_off_circle {
            fill: transparent;
            stroke-dashoffset: 0;
          }
        `}
      </style>
    </svg>
  );
};

export default CircleOff;
