import React, { Component, ReactElement, RefObject } from 'react';
import ReactDOM from 'react-dom';
import { unite } from '../../../common/helpers/unite';
import { TRequestStatus } from '../../../common/types/RequestStatus';
import RequestStatus from '../../partials/RequestStatus/RequestStatus';
import Button from '../Button/Button';

interface Props {
  size?: 'small' | 'medium' | 'large';
  title: string;
  message?: string | ReactElement;
  confirmText?: string;
  cancelText?: string;
  info?: JSX.Element;
  onConfirm: () => void;
  onCancel: () => void;
  focusableItem?: HTMLElement;
  status?: TRequestStatus;
}

interface State {
  confirmed: boolean;
}

class Dialog extends Component<Props, State> {
  dialogRef: RefObject<HTMLDivElement>;
  cancelRef: RefObject<HTMLButtonElement>;
  firstFocusableElement: HTMLElement | null = null;
  lastFocusableElement: HTMLElement | null = null;
  previouslyFocusedElement: Element | null = null;
  previouslyFocusedElementConfirmed: HTMLElement | null = null;

  constructor(props: Props) {
    super(props);
    this.dialogRef = React.createRef();
    this.cancelRef = React.createRef();
    this.state = {
      confirmed: false,
    };
  }

  componentDidMount() {
    this.previouslyFocusedElement = document.activeElement;
    if (this.props.focusableItem) {
      this.previouslyFocusedElementConfirmed = this.props.focusableItem;
    }
    document.addEventListener('keydown', this.handleKeyDown);
    this.setFocusableElements();
  }

  componentWillUnmount() {
    document.removeEventListener('keydown', this.handleKeyDown);
    requestAnimationFrame(() => {
      this.previouslyFocusedElement instanceof HTMLElement &&
        this.previouslyFocusedElement.focus();
    });

    if (this.state.confirmed) {
      this.previouslyFocusedElementConfirmed?.focus();
    }
  }

  setFocusableElements() {
    if (this.dialogRef.current) {
      const focusableElements =
        'button, [href], input, select, textarea, [tabindex]:not([tabindex="-1"])';
      const focusableContent =
        this.dialogRef.current.querySelectorAll(focusableElements);
      this.firstFocusableElement = focusableContent[0] as HTMLElement;
      this.lastFocusableElement = focusableContent[
        focusableContent.length - 1
      ] as HTMLElement;

      // Set initial focus to the cancel element
      requestAnimationFrame(() => {
        this.cancelRef.current?.focus();
      });
    }
  }

  handleKeyDown = (e: KeyboardEvent) => {
    const TAB_KEY = 9;
    const ESC_KEY = 'Escape';
    const isTabPressed = e.key === 'Tab' || e.keyCode === TAB_KEY;
    const isEscPressed = e.key === ESC_KEY;

    // Handle Escape press
    if (isEscPressed) {
      e.preventDefault(); // Prevent the default action to ensure consistent behavior across browsers
      this.props.onCancel(); // Trigger the onCancel method when Escape is pressed
      return; // Exit the function early as no further processing is needed
    }

    // Existing tab navigation logic follows
    if (!isTabPressed) return;

    if (e.shiftKey) {
      // if shift key pressed for shift + tab combination
      if (document.activeElement === this.firstFocusableElement) {
        e.preventDefault();
        requestAnimationFrame(() => {
          this.lastFocusableElement?.focus(); // move focus to the last focusable element
        });
      }
    } else {
      // if tab key is pressed
      if (document.activeElement === this.lastFocusableElement) {
        e.preventDefault();
        requestAnimationFrame(() => {
          this.firstFocusableElement?.focus(); // move focus to the first focusable element
        });
      }
    }
  };

  handleOnClick = () => {
    this.props.onConfirm();

    this.setState({
      confirmed: true,
    });
  };

  renderDialog() {
    return (
      <div
        className="dialog-structure"
        ref={this.dialogRef}
        tabIndex={-1} // Makes the div focusable
      >
        <div className={unite('dialog-component', this.props.size ?? '')}>
          <h2 className="primary-title normalcase mb-xs">{this.props.title}</h2>
          <hr className="separator mt-xs mb-md" />
          {this.props.message && (
            <p className="py-xs text-break-word">{this.props.message}</p>
          )}
          {this.props.children}
          {this.props.info}
          <div className="dialog-actions">
            <ul className="control-list-component">
              {this.props.confirmText && (
                <li>
                  <Button
                    className="primary-button"
                    onClick={this.handleOnClick}
                    disabled={this.props.status === 'loading'}
                  >
                    {this.props.status && (
                      <RequestStatus status={this.props.status} />
                    )}
                    <span className="text">{this.props.confirmText}</span>
                  </Button>
                </li>
              )}
              {this.props.cancelText && (
                <li>
                  <Button
                    className="secondary-button dialogCancel"
                    higherRef={this.cancelRef}
                    onClick={this.props.onCancel}
                    disabled={this.props.status === 'loading'}
                  >
                    {this.props.cancelText}
                  </Button>
                </li>
              )}
            </ul>
          </div>
        </div>
      </div>
    );
  }

  render() {
    return ReactDOM.createPortal(
      this.renderDialog(),
      document.querySelector('.dialog-structure')!,
    );
  }
}

export default Dialog;
