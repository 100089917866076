import React, { Component } from 'react';
import Accordion from '../../controls/Accordion/Accordion';
import dayjsHelper from '../../../common/helpers/dayjsHelper';
import { TRequestStatus } from '../../../common/types/RequestStatus';
import AppContext from '../../../common/contexts/AppContext';
import { TeamDTO } from '../../../common/api/dtos/Team';
import { withStyledTranslation } from '../StyledTranslation/StyledTranslation';
import { WithTranslation } from 'react-i18next';
import { listTeams } from '../../../common/api/endpoints/team'; // Import the listTeams function
import TeamPlanRow from '../Team/TeamPlanRow';
import dayjs from 'dayjs';
import SubscriptionMessage from './SubscriptionMessage';

interface Props extends WithTranslation {
  history: any;
}

interface State {
  teams: TeamDTO[];
  status: TRequestStatus;
  serverErrors: string[];
  loadingMore: boolean;
  nextPage: string | null;
}

class Subscriptions extends Component<Props, State> {
  context!: React.ContextType<typeof AppContext>;

  constructor(props: Props) {
    super(props);

    this.state = {
      teams: [],
      status: 'loading',
      serverErrors: [],
      loadingMore: false,
      nextPage: null,
    };
  }

  componentDidMount() {
    this.fetchAllTeams();

    window.addEventListener('scroll', this.handleScroll); // Add scroll listener
    window.addEventListener('resize', this.handleResize); // Add resize listener

    // Check if we need to load more teams on mount
    this.checkIfMoreContentNeeded();
  }

  componentWillUnmount() {
    window.removeEventListener('scroll', this.handleScroll); // Clean up scroll listener
    window.removeEventListener('resize', this.handleResize); // Clean up resize listener
  }

  checkIfMoreContentNeeded = () => {
    if (
      document.documentElement.scrollHeight - 100 <= window.innerHeight &&
      this.state.nextPage
    ) {
      this.fetchMoreTeams();
    }
  };

  handleResize = () => {
    this.checkIfMoreContentNeeded(); // Trigger content check on resize
  };

  handleScroll = () => {
    const { loadingMore, nextPage } = this.state;

    if (loadingMore || !nextPage) return;

    if (
      window.innerHeight + document.documentElement.scrollTop >=
      document.documentElement.offsetHeight - 100
    ) {
      this.fetchMoreTeams();
    }
  };

  fetchMoreTeams = async () => {
    const { nextPage, teams } = this.state;

    this.setState({ loadingMore: true });

    try {
      const { teams: newTeams, nextPage: newNextPage } = await listTeams(
        1,
        nextPage,
      );

      this.setState(
        (prevState) => ({
          teams: [...prevState.teams, ...newTeams],
          loadingMore: false,
          nextPage: newNextPage,
        }),
        () => {
          // Check if more content is needed after updating state
          this.checkIfMoreContentNeeded();
        },
      );
    } catch (err) {
      this.setState({
        loadingMore: false,
        serverErrors: [err as string],
      });
    }
  };

  fetchAllTeams = async () => {
    this.setState({ status: 'loading' });
    try {
      const { teams, nextPage } = await listTeams(1);

      this.setState(
        {
          teams,
          status: 'success',
          nextPage,
        },
        () => {
          // Check if more content is needed after the first fetch
          this.checkIfMoreContentNeeded();
        },
      );
    } catch (err) {
      this.setState({
        status: 'error',
        serverErrors: [err as string],
      });
    }
  };

  renderPlan(plan: 'free' | 'pro') {
    switch (plan) {
      case 'pro':
        return (
          <>
            <span className="badge-text accent-yellow">
              <span className="black-text text-sm">PRO</span>
            </span>
          </>
        );
      case 'free':
      default:
        return (
          <>
            <span className="badge-text accent-green">
              <span className="black-text text-sm">FREE</span>
            </span>
          </>
        );
    }
  }

  render() {
    const { t } = this.props;
    const { teams, status, serverErrors, loadingMore } = this.state;

    let subscription:
      | 'subscribed'
      | 'unsubscribed'
      | 'willCancel'
      | 'willDelete'
      | 'none' = 'none';

    if (this.context.loggedUser!.subscription) {
      if (this.context.loggedUser!.subscription.cancelAt) {
        subscription = 'willCancel';
      } else if (this.context.loggedUser!.subscription.deleteAt) {
        subscription = 'willDelete';
      } else {
        subscription = 'subscribed';
      }
    } else {
      subscription = 'unsubscribed';
    }

    const currentDate = dayjs();

    const deleteDate = this.context.loggedUser?.subscription?.deleteAt
      ? new Date(this.context.loggedUser?.subscription?.deleteAt)
      : null;

    const cancelDate = this.context.loggedUser?.subscription?.cancelAt
      ? new Date(this.context.loggedUser?.subscription?.cancelAt)
      : null;

    const deleteDateDayJs = this.context.loggedUser?.subscription?.deleteAt
      ? dayjs(this.context.loggedUser?.subscription?.deleteAt)
      : null;

    const deleteDiff = deleteDateDayJs?.diff(currentDate);

    return (
      <>
        <div className="flex-row fill">
          <div className="column pt-0">
            <Accordion
              accordionSlug="personal-plan"
              isOpen={true}
              iconClasses="fal fa-stars icon"
              title={t('personalPlan.title')}
              subheading={t('personalPlan.subheading')}
              disabled={true}
              softDisabled={true}
              hideArrow={true}
              loading={status === 'loading'}
            >
              <div className="accordion-row py-sm">
                <ul className="control-list-component vertical">
                  <li>{t('personalPlan.boardsPlanLabel')}</li>
                  <ul className="control-list-component">
                    <li>
                      {this.renderPlan(
                        (deleteDiff && deleteDiff >= 0) ||
                          subscription === 'subscribed' ||
                          (cancelDate && !deleteDate)
                          ? 'pro'
                          : 'free',
                      )}
                    </li>
                    {deleteDiff && deleteDiff >= 0 ? (
                      <li>
                        <span className="flag-text accent-red">
                          <span className="text-sm">
                            {t('teamPlans.expiredBadge')}
                          </span>
                        </span>
                      </li>
                    ) : cancelDate && !deleteDate ? (
                      <li>
                        <span className="flag-text accent-yellow">
                          <span className="text-sm">
                            {t('teamPlans.cancelledBadge')}
                          </span>
                        </span>
                      </li>
                    ) : null}
                  </ul>
                  {deleteDiff && deleteDiff >= 0 && (
                    <li>
                      <li>
                        <SubscriptionMessage badge="danger">
                          {t('personalPlan.deletionMessage', {
                            date: dayjsHelper(deleteDate).format('MMMM Do'),
                          })}
                        </SubscriptionMessage>
                      </li>
                    </li>
                  )}
                  {cancelDate && !deleteDate && (
                    <li>
                      <SubscriptionMessage badge="warning">
                        {t('personalPlan.cancelingMessage', {
                          date: dayjsHelper(cancelDate).format('MMMM Do'),
                        })}
                      </SubscriptionMessage>
                    </li>
                  )}
                  <li className="pt-xs">
                    {subscription === 'unsubscribed' ? (
                      <form
                        target="_blank"
                        method="POST"
                        action={`${process.env.REACT_APP_API_BASE_URL}/self/upgrade`}
                      >
                        <button className="secondary-button">
                          <span className="text">
                            {t('personalPlan.upgradeButton')}
                          </span>
                          <span className="far fa-external-link icon"></span>
                        </button>
                      </form>
                    ) : subscription === 'subscribed' ||
                      (deleteDiff && deleteDiff < 0) ? (
                      <form
                        target="_blank"
                        method="POST"
                        action={`${process.env.REACT_APP_API_BASE_URL}/self/changeBilling`}
                      >
                        <button className="secondary-button">
                          <span className="text">
                            {t('personalPlan.manageSubscriptionButton')}
                          </span>
                          <span className="far fa-external-link icon"></span>
                        </button>
                      </form>
                    ) : (
                      ((cancelDate && !deleteDate) ||
                        subscription === 'willCancel' ||
                        subscription === 'willDelete') && (
                        <form
                          target="_blank"
                          method="POST"
                          action={
                            subscription === 'willDelete'
                              ? `${process.env.REACT_APP_API_BASE_URL}/self/upgrade`
                              : `${process.env.REACT_APP_API_BASE_URL}/self/changeBilling`
                          }
                        >
                          <button className="secondary-button">
                            <span className="text">
                              {t('personalPlan.manageSubscriptionButton')}
                            </span>
                            <span className="far fa-external-link icon"></span>
                          </button>
                        </form>
                      )
                    )}
                  </li>
                </ul>
              </div>
            </Accordion>
            <Accordion
              accordionSlug="team-plans"
              isOpen={true}
              iconClasses="fal fa-users icon"
              title={t('teamPlans.title')}
              subheading={t('teamPlans.subheading')}
              disabled={true}
              softDisabled={true}
              hideArrow={true}
              loading={status === 'loading'}
            >
              {!teams.filter((team) => team.owned).length && (
                <div className="accordion-row py-sm">
                  <p className="faint-text">{t('teamPlans.noTeamsMessage')}</p>
                </div>
              )}

              {teams
                .filter((team) => team.owned)
                .map((team, index) => (
                  <div
                    key={index}
                    className="accordion-row py-sm"
                  >
                    <TeamPlanRow
                      data={team}
                      status={this.state.status}
                      history={this.props.history}
                    />
                  </div>
                ))}
            </Accordion>
          </div>
        </div>
        {/* Infinite Scroll Loader */}
        {loadingMore && <div className="loader"></div>}
      </>
    );
  }
}

export default withStyledTranslation('subscriptions')(Subscriptions);
Subscriptions.contextType = AppContext;
