import React, { createContext } from 'react';
import { UserAccountSettingsDTO, UserDTO } from '../api/dtos/User';
import { IClientData } from '../interfaces/ClientData';
import { NotificationMessage } from './NotificationsContext';
import { setLocalStorage } from '../helpers/localStorageHelpers';
import { getDefaultExperiments } from '../helpers/experiments';

type Nullable<T> = {
  [P in keyof T]: T[P] | null;
};

export interface AccountSettings extends UserAccountSettingsDTO {}
export interface LocalSettings extends Nullable<UserAccountSettingsDTO> {}

export interface LoggedUser extends UserDTO {
  localSettings: LocalSettings;
  setAccountSettings: (settings: Partial<AccountSettings>) => Promise<void>;
  setLocalSettings: (settings: Partial<LocalSettings>) => void;
}

export interface IAppContext {
  color?: string;
  highContrast?: boolean;
  theme?: string;
  showPatterns?: boolean;
  pattern?: string;
  hour?: string;
  language?: string;
  loggedUser?: LoggedUser;
  appSettings: UserAccountSettingsDTO;
  setLoggedUser?: (user: UserDTO) => void;
  directionalButtonId?: string;
  setDirectionalButtonId?: (id: string) => void;
  updateLoggedUser?: (newUserData: Partial<UserDTO>) => void;
  setIsDragging?: (value: boolean) => void;
  updateClientData?: (clientData: Partial<IClientData>) => void;
  notifications: {
    messages: NotificationMessage[];
    setMessages?: (
      messages: NotificationMessage | NotificationMessage[],
    ) => void;
  };
}

/**
 * Create a new context object with updated account and app settings.
 */
export function updateAccountSettings(
  ctx: IAppContext,
  settings: Partial<UserAccountSettingsDTO>,
): IAppContext {
  if (!ctx.loggedUser) {
    return ctx;
  }

  const loggedUser = {
    ...ctx.loggedUser,
    accountSettings: {
      ...ctx.loggedUser.accountSettings,
      ...settings,
    },
  };

  return {
    ...ctx,
    loggedUser,
    appSettings: computeAppSettings(loggedUser),
  };
}

/**
 * Create a new context object with updated local and app settings, as well as updating the local storage.
 */
export function updateLocalSettings(
  ctx: IAppContext,
  settings: Partial<Nullable<UserAccountSettingsDTO>>,
): IAppContext {
  if (!ctx.loggedUser) {
    return ctx;
  }
  const userId = ctx.loggedUser.id;

  for (const key in settings) {
    if (Object.prototype.hasOwnProperty.call(settings, key)) {
      const value = (settings as Record<string, unknown>)[key];
      setLocalStorage(userId + '-' + key, value);
    }
  }

  const loggedUser = {
    ...ctx.loggedUser,
    localSettings: {
      ...ctx.loggedUser.localSettings,
      ...settings,
    },
  };

  return {
    ...ctx,
    loggedUser,
    appSettings: computeAppSettings(loggedUser),
  };
}

/**
 * Compute the application settings based on the existence of a user, the settings stored
 * locally on the device, and the settings stored on the server.
 */
export function computeAppSettings(
  loggedUser?: LoggedUser,
): UserAccountSettingsDTO {
  const defaultSettings = {
    theme: 'light',
    highContrast: false,
    showPatterns: false,
    reducedMotion: false,
    colorBlindHelpers: false,
    timeFormat: 12,
    language: 'en_US',
    experiments: getDefaultExperiments(),
  };

  if (!loggedUser) {
    return defaultSettings;
  }

  const userId = loggedUser.id;
  const localSettings = computeLocalSettings(userId);
  const accountSettings = loggedUser.accountSettings;

  return {
    theme: localSettings.theme ?? accountSettings.theme,
    highContrast: localSettings.highContrast ?? accountSettings.highContrast,
    showPatterns: false,
    reducedMotion: localSettings.reducedMotion ?? accountSettings.reducedMotion,
    colorBlindHelpers:
      localSettings.colorBlindHelpers ??
      accountSettings.colorBlindHelpers ??
      false,
    language: localSettings.language ?? accountSettings.language ?? 'en_US',
    timeFormat: localSettings.timeFormat ?? accountSettings.timeFormat ?? '12',
  };
}

/**
 * Compute the local settings based on the provided user id and the content in the local storage.
 */
export function computeLocalSettings(
  userId: string,
): Nullable<UserAccountSettingsDTO> {
  const experiments =
    mapNonNull(localStorage.getItem(userId + '-experiments'), (v) => {
      const userExperiments = JSON.parse(v);
      return { ...getDefaultExperiments(), ...(userExperiments[userId] || {}) };
    }) || getDefaultExperiments();

  return {
    language: localStorage.getItem(userId + '-language'),
    timeFormat: Number(localStorage.getItem(userId + '-timeFormat')),
    theme: localStorage.getItem(userId + '-theme'),
    highContrast: mapNonNull(
      localStorage.getItem(userId + '-highContrast'),
      (v) => v === 'true',
    ),
    showPatterns: null,
    reducedMotion: mapNonNull(
      localStorage.getItem(userId + '-reducedMotion'),
      (v) => v === 'true',
    ),
    colorBlindHelpers: mapNonNull(
      localStorage.getItem(userId + '-colorBlindHelpers'),
      (v) => v === 'true',
    ),
    experiments: experiments,
  };
}

function mapNonNull<T, P>(value: T | null, fn: (val: T) => P): P | null {
  if (value === null || value === undefined) {
    return null;
  }
  return fn(value);
}

// Context default values
const AppContext = createContext<IAppContext>({
  appSettings: computeAppSettings(), // Default app settings
  updateLoggedUser: (newUserData: Partial<UserDTO>) => {
    (AppContext as any).current.setLoggedUser?.((prevLoggedUser: UserDTO) => ({
      ...prevLoggedUser,
      ...newUserData,
    }));
  },
  notifications: {
    messages: [], // Provide an empty array as the default value for messages
    setMessages: (messages: NotificationMessage | NotificationMessage[]) => {
      // Default no-op function for setMessages
    },
  },
});

export default AppContext;
