import React from 'react';
import { NavLink } from 'react-router-dom';

interface BreadcrumbItem {
  label: React.ReactNode;
  to?: string;
  isCurrent?: boolean;
}

interface BreadcrumbProps {
  items: BreadcrumbItem[];
  separator?: React.ReactNode;
  className?: string;
}

const Breadcrumb: React.FC<BreadcrumbProps> = ({
  items,
  separator = '›',
  className = '',
}) => {
  return (
    <nav
      aria-label="Breadcrumb"
      className={`breadcrumb ${className}`}
    >
      <ul className="control-list-component">
        {items.map((item, index) => (
          <React.Fragment key={index}>
            <li>
              {item.to ? (
                <NavLink
                  to={item.to}
                  exact
                  className="link-button h3 primary-title"
                  aria-current={item.isCurrent ? 'page' : undefined}
                >
                  {item.label}
                </NavLink>
              ) : (
                <span
                  className={`primary-title h3 ${
                    item.isCurrent ? 'normalcase' : ''
                  }`}
                  aria-current={item.isCurrent ? 'page' : undefined}
                >
                  {item.label}
                </span>
              )}
            </li>
            {index < items.length - 1 && (
              <li className="separator">{separator}</li>
            )}
          </React.Fragment>
        ))}
      </ul>
    </nav>
  );
};

export default Breadcrumb;
