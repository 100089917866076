import React, { PureComponent, KeyboardEvent } from 'react';
import {
  removeMemberFromBoard,
  updateMemberRole,
} from '../../../common/api/endpoints/member';
import { TRequestStatus } from '../../../common/types/RequestStatus';
import Button from '../../controls/Button/Button';
import ComboBox from '../../controls/ComboBox/ComboBox';
import Thumbnail from '../../partials/Thumbnail/Thumbnail';
import AppContext, { IAppContext } from '../../../common/contexts/AppContext';
import { NotificationMessage } from '../../../common/contexts/NotificationsContext';
import { IRole } from '../../../common/interfaces/Role';
import { InviteeDTO, MemberDTO } from '../../../common/api/dtos/Member';
import {
  showErrorNotifications,
  showSuccessNotifications,
} from '../../../common/helpers/showNotifications';
import { DEFAULT_AVATAR } from '../../../common/configs/appDefaults';
import { ROLE_OPTIONS } from '../../../common/configs/roleOptions';
import { withContextAdapters } from '../../partials/ContextAdapter/withContextAdapter';
import BoardContext from '../../../common/contexts/BoardContext';
import Dialog from '../../controls/Dialog/Dialog';
import { withStyledTranslation } from '../../partials/StyledTranslation/StyledTranslation';
import { Trans, WithTranslation } from 'react-i18next';
import {
  IBoardContext,
  UpdateMemberData,
} from '../../../common/interfaces/BoardContext';

interface AppContextProps {
  setMessages: (messages: NotificationMessage | NotificationMessage[]) => void;
}
interface BoardContextProps {
  currentBoardUser: { id: string; role: string };
  setCurrentBoardUser: (user: { id: string; role: string }) => void;
  activeBoardMembersStatus?: string[];
  setActiveBoardMembersStatus: (userIds: string[]) => void;
  members: (MemberDTO | InviteeDTO)[];
  updateMember: (id: string, data: UpdateMemberData) => void;
  removeMember: (id: string) => void;
}
interface ExternalProps {
  member: MemberDTO | InviteeDTO;
  searchUsers: (MemberDTO | InviteeDTO)[] | null;
  setSearchUsers: (users: (MemberDTO | InviteeDTO)[] | null) => void;
  inviteMember: (memberId: string) => void;
  focusableItem?: HTMLElement;
}
interface Props
  extends ExternalProps,
    AppContextProps,
    BoardContextProps,
    WithTranslation {}

interface State {
  status: TRequestStatus;
  role: number | string | undefined | { label: string; value: string };
  roleList: IRole[];
  serverErrors: string[];
  showDeleteButtons: boolean;
  showRemovalDialog: boolean;
}

class MemberManageMenu extends PureComponent<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      status: 'idle',
      role: this.props.member?.role
        ? this.props.member?.role.toLowerCase()
        : '',
      roleList: [],
      serverErrors: [],
      showDeleteButtons: false,
      showRemovalDialog: false,
    };
  }

  setRole = (
    ev: React.MouseEvent<HTMLLIElement> | KeyboardEvent<Element>,
    role: number | string | undefined | { label: string; value: string },
  ) => {
    const memberRole = role as string;
    this.setState({ role: memberRole });
    this.elevateMember(memberRole);
  };

  componentDidUpdate(prevProps: Readonly<Props>): void {
    if (prevProps.member.role !== this.props.member.role) {
      this.setState({
        role: this.props.member.role,
      });
    }
  }

  elevateMember = async (role: string) => {
    this.setState({
      status: 'loading',
    });

    let activeBoardMembersStatus;

    if (this.props.activeBoardMembersStatus !== undefined) {
      activeBoardMembersStatus = [
        ...this.props.activeBoardMembersStatus,
        this.props.member.id,
      ];
    } else {
      activeBoardMembersStatus = [this.props.member.id];
    }
    this.props.setActiveBoardMembersStatus(activeBoardMembersStatus);

    try {
      if (this.props.member.id === this.props.currentBoardUser.id) {
        const currentUser = {
          id: this.props.currentBoardUser.id,
          role,
        };
        this.props.setCurrentBoardUser(currentUser);
      }

      await updateMemberRole(this.props.member.id, role);
      this.props.updateMember(this.props.member.id, { role });

      this.setState(
        {
          status: 'success',
        },
        () => {
          showSuccessNotifications(
            ['Member role has been changed succesfully!'],
            this.props.setMessages,
          );
        },
      );
    } catch (err) {
      const error = Array.isArray(err) ? err : [err];
      showErrorNotifications(error, this.props.setMessages);
      this.setState({
        status: 'error',
      });
    } finally {
      const activeBoardMembersStatus = [
        ...(this.props.activeBoardMembersStatus || []),
      ];
      const index = activeBoardMembersStatus.findIndex(
        (userId: string) => userId === this.props.member.id,
      );

      if (index !== -1) {
        activeBoardMembersStatus.splice(index, 1);
      }

      this.props.setActiveBoardMembersStatus(activeBoardMembersStatus);
    }
  };

  removeMember = async () => {
    this.setState({
      status: 'loading',
    });

    try {
      await removeMemberFromBoard(this.props.member.id);
      this.props.removeMember(this.props.member.id);

      // if a member is removed while a search query is active, remove the member from search results
      if (this.props.searchUsers && this.props.searchUsers.length > 0) {
        const newSearchUsers = this.props.searchUsers.filter(
          (user) => user.id !== this.props.member.id,
        );
        this.props.setSearchUsers(newSearchUsers);
      }

      this.setState({
        status: 'success',
      });
    } catch (err) {
      const error = Array.isArray(err) ? err : [err];
      showErrorNotifications(error, this.props.setMessages);

      this.setState({
        status: 'error',
      });
    }
  };

  getMemberAvatar = (id: string) => {
    const member = this.props.members.find((m) => m.id === id);

    return member && member.avatar ? member.avatar : DEFAULT_AVATAR;
  };

  renderAvatar = () => {
    const { member } = this.props;
    return (
      <Thumbnail
        classes="size-24"
        avatarData={this.getMemberAvatar(member.id)}
        title={this.getMemberName(member.id)}
      />
    );
  };

  getMemberName = (id: string | undefined) => {
    const member = this.props.members.find((m) => m.id === id);
    return (
      member &&
      (member.name
        ? member.name
        : (member as InviteeDTO).email
        ? (member as InviteeDTO).email
        : undefined)
    );
  };

  render() {
    const { t } = this.props;
    const isLoading =
      this.props.activeBoardMembersStatus !== undefined
        ? this.props.activeBoardMembersStatus.find(
            (id: string) => id === this.props.member.id,
          )
        : undefined;

    const isILoggedUserMember = this.props.currentBoardUser.role === 'member';
    const isDisabled =
      isILoggedUserMember ||
      this.props.member.role === 'owner' ||
      isLoading !== undefined ||
      this.props.currentBoardUser.id === this.props.member.id;
    const filterRoles = isDisabled
      ? ROLE_OPTIONS
      : ROLE_OPTIONS.filter((option) => option.value !== 'owner');

    return (
      <>
        <li key={this.props.member.id}>
          <Thumbnail
            classes="size-24 mr-xs"
            avatarData={
              this.props.member.avatar
                ? this.props.member.avatar
                : DEFAULT_AVATAR
            }
            title={
              this.props.member.name || (this.props.member as InviteeDTO).email
            }
          />
          {'email' in this.props.member && (
            <span className="text">{this.props.member.email}</span>
          )}
          {'name' in this.props.member && (
            <span className="text">{this.props.member.name}</span>
          )}
          {'invited' in this.props.member && (
            <span className="text faint-text">
              {this.props.member.invited ? '(invited)' : ''}
            </span>
          )}
          {!Boolean(this.state.role) ? (
            <ul className="control-list-component flex-h-end no-wrap">
              <li className="my-0">
                <Button
                  className="primary-button"
                  onClick={() => this.props.inviteMember(this.props.member.id)}
                  disabled={isDisabled}
                  title="Add member"
                >
                  <span className="pe-none">Add</span>
                </Button>
              </li>
            </ul>
          ) : null}
          {!this.state.showDeleteButtons && Boolean(this.state.role) ? (
            <ul className="control-list-component flex-h-end no-wrap">
              <li className="my-0">
                <ComboBox
                  id="userRoles"
                  value={this.state.role}
                  disabled={isDisabled}
                  options={filterRoles}
                  onChange={this.setRole}
                  getValue={(op) => op?.value}
                  getLabel={(op) => t(op?.value)}
                  formGroupClassname="mb-0"
                  title={'User role'}
                />
              </li>
              <li className="my-0">
                <Button
                  className="ghost-button"
                  onClick={() => this.setState({ showRemovalDialog: true })}
                  disabled={isDisabled}
                  title="Remove member"
                >
                  <span className="fas fa-times pe-none"></span>
                </Button>
              </li>
            </ul>
          ) : null}
          {this.state.showRemovalDialog && (
            <Dialog
              title={t('memberDeletion')}
              message={
                <>
                  <Trans
                    i18nKey="memberManageMenu:memberDeletionConfirmation"
                    components={[
                      <span
                        key="0"
                        className="no-wrap"
                      >
                        {this.renderAvatar()}
                        <span
                          className="pl-2xs faint-text"
                          style={{ lineHeight: '1.5rem' }}
                        >
                          {this.getMemberName(this.props.member.id)}
                        </span>
                      </span>,
                    ]}
                  />
                </>
              }
              info={
                <p className="text-sm faint-text">
                  <span className="accent-text-red fas fa-exclamation-circle"></span>{' '}
                  <span>{t('operationIrreversible')}</span>
                </p>
              }
              cancelText={t('cancel')}
              confirmText={t('permanentlyDelete')}
              onCancel={() => this.setState({ showRemovalDialog: false })}
              onConfirm={this.removeMember}
            />
          )}
        </li>
      </>
    );
  }
}

const AppContextAdapter = {
  ctx: AppContext,
  adapt: (ctx: IAppContext): AppContextProps => {
    return {
      setMessages: ctx.notifications.setMessages!,
    };
  },
};
const BoardContextAdapter = {
  ctx: BoardContext,
  adapt: (ctx: IBoardContext): BoardContextProps => {
    return {
      activeBoardMembersStatus: ctx.activeBoardMembersStatus,
      setActiveBoardMembersStatus: ctx.setActiveBoardMembersStatus!,

      currentBoardUser: ctx.board.user,
      members: ctx.board.members,
      setCurrentBoardUser: ctx.setCurrentBoardUser!,

      removeMember: ctx.removeMember,
      updateMember: ctx.updateMember,
    };
  },
};

export default withContextAdapters<
  ExternalProps,
  IAppContext,
  AppContextProps,
  IBoardContext,
  BoardContextProps
>(
  withStyledTranslation('memberManageMenu')(MemberManageMenu),
  AppContextAdapter,
  BoardContextAdapter,
);
