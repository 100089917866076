import React, { createContext, useState, useContext } from 'react';
import { ErrorNotificationPayload } from '../helpers/errorNotificationPayload';
import { SuccessNotificationPayload } from '../helpers/successNotificationPayload';
import { InfoNotificationPayload } from '../helpers/infoNotificationPayload';

export type NotificationMessage =
  | SuccessNotificationPayload
  | InfoNotificationPayload
  | ErrorNotificationPayload;

interface NotificationsContextType {
  messages: NotificationMessage[];
  setMessages: (messages: NotificationMessage | NotificationMessage[]) => void;
}

const NotificationsContext = createContext<
  NotificationsContextType | undefined
>(undefined);

export const useNotifications = () => {
  const context = useContext(NotificationsContext);
  if (!context) {
    throw new Error(
      'useNotifications must be used within a NotificationsProvider',
    );
  }
  return context;
};

export const NotificationsProvider: React.FC = ({ children }) => {
  const [messages, setMessages] = useState<NotificationMessage[]>([]);

  const addMessages = (
    newMessages: NotificationMessage | NotificationMessage[],
  ) => {
    setMessages((prevMessages) => [
      ...prevMessages,
      ...(Array.isArray(newMessages) ? newMessages : [newMessages]),
    ]);
  };

  return (
    <NotificationsContext.Provider
      value={{ messages, setMessages: addMessages }}
    >
      {children}
    </NotificationsContext.Provider>
  );
};
