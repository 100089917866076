/**
 * Helper function to set or remove items from localStorage.
 */
export function setLocalStorage(key: string, value: unknown) {
  if (value === null) {
    localStorage.removeItem(key);
  } else {
    if (typeof value === 'object') {
      localStorage.setItem(key, JSON.stringify(value));
    } else {
      localStorage.setItem(key, String(value));
    }
  }
}

/**
 * Retrieve an item from localStorage and parse if it is JSON.
 */
export function getLocalStorage<T>(key: string): T | null {
  const item = localStorage.getItem(key);
  if (!item) {
    return null;
  }

  try {
    return JSON.parse(item) as T;
  } catch {
    return item as unknown as T;
  }
}
