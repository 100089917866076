import { EditorState } from 'lexical';
import { useEffect } from 'react';
import { AutoFocusPlugin } from '@lexical/react/LexicalAutoFocusPlugin';
import { LexicalComposer } from '@lexical/react/LexicalComposer';
import { RichTextPlugin } from '@lexical/react/LexicalRichTextPlugin';
import { ContentEditable } from '@lexical/react/LexicalContentEditable';
import { HistoryPlugin } from '@lexical/react/LexicalHistoryPlugin';
import { LexicalErrorBoundary } from '@lexical/react/LexicalErrorBoundary';
import CONDUIT_BRIDGE from '../LexicalControl/themes/LexicalTheme';
import ToolbarPlugin from '../LexicalControl/plugins/ToolbarPlugin';
import { CheckListPlugin } from '@lexical/react/LexicalCheckListPlugin';
import { useLexicalComposerContext } from '@lexical/react/LexicalComposerContext';
import { useLexicalIsTextContentEmpty } from '@lexical/react/useLexicalIsTextContentEmpty';
import { HeadingNode, QuoteNode } from '@lexical/rich-text';
import { TableCellNode, TableNode, TableRowNode } from '@lexical/table';
import { ListItemNode, ListNode } from '@lexical/list';
import { CodeHighlightNode, CodeNode } from '@lexical/code';
import { AutoLinkNode, LinkNode } from '@lexical/link';
import ListMaxIndentLevelPlugin from '../LexicalControl/plugins/ListMaxIndentLevelPlugin';
import { ListPlugin } from '@lexical/react/LexicalListPlugin';
import { LinkPlugin } from '@lexical/react/LexicalLinkPlugin';
import CodeHighlightPlugin from '../LexicalControl/plugins/CodeHighlightPlugin';
import { ClearEditorPlugin } from '@lexical/react/LexicalClearEditorPlugin';

const theme = CONDUIT_BRIDGE;

interface EditorControlProps {
  onChange: (value: string | null) => void;
  initialEditorState?: string | null;
  namespace?: string;
}

function onError(error: unknown) {
  console.error(error);
}

function ContentChangedPlugin({
  onChange,
}: {
  onChange: (editorState: EditorState) => void;
}) {
  const [editor] = useLexicalComposerContext();
  useEffect(() => {
    return editor.registerUpdateListener(({ editorState }) => {
      onChange(editorState);
    });
  }, [editor, onChange]);
  return null;
}
export default function EditorControl({
  onChange,
  initialEditorState,
  namespace,
}: EditorControlProps) {
  const initialConfig = initialEditorState
    ? {
        namespace: namespace ?? 'EditorControl',
        theme,
        onError,
        editorState: (editor: any) =>
          editor.parseEditorState(JSON.parse(initialEditorState)),
        editable: true,
        nodes: [
          HeadingNode,
          ListNode,
          ListItemNode,
          QuoteNode,
          CodeNode,
          CodeHighlightNode,
          TableNode,
          TableCellNode,
          TableRowNode,
          AutoLinkNode,
          LinkNode,
        ],
      }
    : {
        namespace: namespace ?? 'EditorControl',
        theme,
        onError,
        nodes: [
          HeadingNode,
          ListNode,
          ListItemNode,
          QuoteNode,
          CodeNode,
          CodeHighlightNode,
          TableNode,
          TableCellNode,
          TableRowNode,
          AutoLinkNode,
          LinkNode,
        ],
      };

  function handleChange(editorState: EditorState | null) {
    if (editorState == null) {
      onChange(null);
    } else {
      const editorStateJSON = editorState.toJSON();
      onChange(JSON.stringify(editorStateJSON));
    }
  }

  return (
    <div className="flex-row fill">
      <div className="column pb-2xs">
        <LexicalComposer initialConfig={initialConfig}>
          <EditorContent
            editorState={initialEditorState}
            handleChange={handleChange}
          />
        </LexicalComposer>
      </div>
    </div>
  );
}

function EditorContent({
  editorState,
  handleChange,
}: {
  editorState?: string | null;
  handleChange: (editorState: EditorState | null) => void;
}) {
  const [editor] = useLexicalComposerContext();
  const isEmpty = useLexicalIsTextContentEmpty(editor);

  useEffect(() => {
    if (editorState && editor) {
      editor.update(() => {
        const parsedState = editor.parseEditorState(JSON.parse(editorState));
        editor.setEditorState(parsedState);
      });
    }
  }, [editorState, editor]);

  useEffect(() => {
    // Trigger handleChange based on the empty state
    handleChange(isEmpty ? null : editor.getEditorState());
  }, [isEmpty, editor, handleChange]);

  return (
    <div className="lexical-control">
      <div className="editor-inner">
        <ToolbarPlugin />
        <RichTextPlugin
          contentEditable={
            <ContentEditable
              style={{
                marginTop: '-10px',
                paddingTop: '30px',
                minHeight: '100px',
              }}
              className="card editor-input opaque"
              spellCheck={true}
              disabled={false}
            />
          }
          ErrorBoundary={LexicalErrorBoundary}
        />
        <AutoFocusPlugin />
        <ClearEditorPlugin />
        <CodeHighlightPlugin />
        <HistoryPlugin />
        <LinkPlugin />
        <ListMaxIndentLevelPlugin maxDepth={7} />
        <ListPlugin />
        <CheckListPlugin />
        <ContentChangedPlugin
          onChange={(editorState: EditorState) => {
            // This ensures the change handling logic is centralized.
            return isEmpty ? handleChange(null) : handleChange(editorState);
          }}
        />
      </div>
    </div>
  );
}
