import React, { PureComponent } from 'react';
import classNames from 'classnames';
import { IDirectionalButton } from '../../../common/interfaces/DirectionalButton';
import AppContext from '../../../common/contexts/AppContext';

interface Props {
  directionalButton: IDirectionalButton;
  className: string;
}

interface State {}

export default class DirectionalButton extends PureComponent<Props, State> {
  static contextType = AppContext;

  // Memoized ID computation to avoid recalculating on every render
  get directionalButtonId() {
    return `#${this.props.directionalButton.text
      .toLowerCase()
      .replaceAll(' ', '-')}-directional-button`;
  }

  handleClick = () => {
    const { onClick, direction } = this.props.directionalButton;

    onClick();

    if (direction === 'right') {
      // New id is only set when moving forward
      this.context.setDirectionalButtonId(this.directionalButtonId);
    }
  };

  render() {
    const { directionalButton, className } = this.props;
    const { disabled, direction, text } = directionalButton;

    return (
      <button
        disabled={disabled}
        className={classNames('ghost-button fill', className)}
        onClick={!disabled ? this.handleClick : undefined}
        id={this.directionalButtonId}
        aria-label={`${direction} directional button`}
      >
        {direction === 'left' && (
          <span className={`icon fas fa-angle-${direction}`}></span>
        )}
        <span className="text">{text}</span>
        {direction === 'right' && (
          <span className={`icon fas fa-angle-${direction}`}></span>
        )}
      </button>
    );
  }
}
