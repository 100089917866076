import React from 'react';
import { LexicalEditor } from 'lexical';
import {
  updateComment,
  deleteComment,
} from '../../../../common/api/endpoints/comment';
import { CommentDTO } from '../../../../common/api/dtos/Comment';
import Button from '../../../controls/Button/Button';
import { IBoardCurrentUser } from '../../../../common/interfaces/BoardCurrentUser';
import Dialog from '../../../controls/Dialog/Dialog';
import getTime from '../../../../common/helpers/getTime';
import { Trans, WithTranslation } from 'react-i18next';
import { withStyledTranslation } from '../../StyledTranslation/StyledTranslation';
import { InviteeDTO, MemberDTO } from '../../../../common/api/dtos/Member';
import Thumbnail from '../../Thumbnail/Thumbnail';
import { DEFAULT_AVATAR } from '../../../../common/configs/appDefaults';
import EditorControl from '../../../controls/EditorControl/EditorControl';
import EditorViewer from '../../../controls/EditorControl/EditorViewer';
import AppContext from '../../../../common/contexts/AppContext';
import RequestStatus from '../../RequestStatus/RequestStatus';
import { TRequestStatus } from '../../../../common/types/RequestStatus';

interface Props extends WithTranslation {
  disabled: boolean;
  boardCard: BoardCard;
  boardUser: IBoardCurrentUser;
  comment: CommentDTO;
  updateComment: (commentData: Partial<CommentDTO>) => void;
  removeComment: (commentData: Partial<CommentDTO>) => void;
  handleDeleteComment?: () => void;
  members: (MemberDTO | InviteeDTO)[];
}

export interface BoardCard {
  id: string;
  title: string;
  number: number;
  assigneeIds: string[];
  description: string;
  tagIds: string[];
  priorityId: string | null;
}

interface State {
  form: {
    comment: string | null;
  };
  editor: LexicalEditor | null;
  showLexical: boolean;
  cancelComment: boolean;
  showDeletionPrompt: boolean;
  status: TRequestStatus;
}

class UserComment extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      editor: null,
      form: {
        comment: this.props.comment.content,
      },
      showLexical: false,
      cancelComment: false,
      showDeletionPrompt: false,
      status: 'idle',
    };
  }

  setComment = (value: string | null) => {
    this.setState({
      form: {
        comment: value,
      },
      status: 'idle',
    });
  };

  setEditor = (editor: LexicalEditor | null) => {
    this.setState({ editor });
  };

  updateComment = async () => {
    if (this.state.form.comment !== null) {
      this.setState({ status: 'loading' });
      try {
        const comment = await updateComment(
          this.props.comment.id,
          this.state.form.comment,
        );

        this.setState({
          form: {
            comment: null,
          },
          status: 'success',
        });

        this.props.updateComment(comment);
        this.setShowLexical(false);
      } catch (err) {
        console.debug(err);
        this.context.notifications.setMessages(err);
        this.setState({
          status: 'error',
        });
      }
    }
  };

  getMemberAvatar = (id: string) => {
    const member = this.props.members.find((m) => m.id === id);
    return member?.avatar ?? DEFAULT_AVATAR;
  };

  getMemberName = (id: string | undefined) => {
    const member = this.props.members.find((m) => m.id === id);
    return member?.name ?? (member as InviteeDTO)?.email;
  };

  renderAvatar = () => {
    const { t, comment } = this.props;
    const memberName = this.getMemberName(comment.authorId);
    return (
      <Thumbnail
        classes="size-24 icon"
        avatarData={this.getMemberAvatar(comment.authorId)}
        title={memberName ?? t('unknown')}
      />
    );
  };

  handleUpdate = () => {
    if (this.state.form.comment !== this.props.comment.content) {
      this.updateComment();
    }
  };

  handleCancel = () => {
    this.setState({
      showLexical: false,
      cancelComment: true,
      status: 'idle',
      form: {
        comment: this.props.comment.content,
      },
    });
  };

  setShowLexical = (value: boolean) => {
    this.setState({ showLexical: value }, () => {
      if (!value) {
        this.setState({ cancelComment: true });
      }
    });
  };

  setShowDeletionPrompt = (showDeletionPrompt: boolean) => {
    this.setState({ showDeletionPrompt });
  };

  deleteComment = async (comment: CommentDTO) => {
    try {
      await deleteComment(comment.id);
      const deletedComment = {
        ...comment,
        deletedBy: this.props.boardUser.id,
        deletedAt: new Date().toISOString(),
      };
      this.props.removeComment(deletedComment);
      this.props.handleDeleteComment?.();
      this.setShowLexical(false);
    } catch (err) {
      console.debug(err);
    }
  };

  renderDeletionDialog = () => {
    const { t, comment } = this.props;
    const { showDeletionPrompt } = this.state;
    if (!showDeletionPrompt) return null;

    return (
      <Dialog
        title={t('commentDeletion')}
        message={
          <Trans
            i18nKey="userComment:commentDeletionConfirmation"
            values={{
              avatar: this.renderAvatar(),
              author: this.getMemberName(comment.authorId),
              time: getTime(comment.createdAt),
            }}
            components={[
              this.renderAvatar(),
              <strong key="1"></strong>,
              <strong key="2"></strong>,
            ]}
          />
        }
        info={
          <p className="text-sm faint-text">
            <span className="accent-text-red fas fa-exclamation-circle"></span>{' '}
            <span>{t('operationIrreversible')}</span>
          </p>
        }
        cancelText={t('cancel')}
        confirmText={t('permanentlyDelete')}
        onCancel={() => this.setShowDeletionPrompt(false)}
        onConfirm={() => {
          this.setShowDeletionPrompt(false);
          this.deleteComment(this.props.comment);
        }}
      />
    );
  };

  render() {
    const { t, comment, boardUser, disabled } = this.props;
    const { state } = this;
    const canEditOrDelete =
      (boardUser.role === 'owner' ||
        boardUser.role === 'admin' ||
        boardUser.id === comment.authorId) &&
      !disabled;

    return (
      <>
        {!state.showLexical && (
          <>
            <div className="flex-row fill">
              <div className="column pb-2xs">
                <EditorViewer
                  editorState={state.form.comment ?? comment.content}
                  namespace={`comment-viewer-${comment.id}`}
                />
              </div>
            </div>
            {canEditOrDelete && (
              <ul className="control-list-component mb-sm">
                {boardUser.id === comment.authorId && (
                  <li>
                    <Button
                      className="secondary-button lexical-button-trigger"
                      onClick={() => this.setShowLexical(true)}
                    >
                      <span className="text">{t('edit')}</span>
                    </Button>
                  </li>
                )}
                <li>
                  <Button
                    className="secondary-button"
                    onClick={() => this.setShowDeletionPrompt(true)}
                  >
                    <span className="text">{t('delete')}</span>
                  </Button>
                  {this.renderDeletionDialog()}
                </li>
              </ul>
            )}
          </>
        )}
        {state.showLexical && (
          <div>
            {comment.content && (
              <EditorControl
                onChange={this.setComment}
                namespace={`comment-editor-${comment.id}`}
                initialEditorState={comment.content}
              />
            )}
            <ul className="control-list-component pt-2xs">
              <li>
                <Button
                  disabled={
                    this.state.status === 'loading' ||
                    this.props.comment.content == state.form.comment ||
                    this.state.form.comment === null
                  }
                  className="primary-button"
                  onClick={() => {
                    this.handleUpdate();
                  }}
                >
                  <RequestStatus status={state.status} />
                  <span className="text">{t('updateComment')}</span>
                </Button>
              </li>
              <li>
                <Button
                  className="secondary-button"
                  onClick={this.handleCancel}
                >
                  <span className="text">{t('cancel')}</span>
                </Button>
              </li>
            </ul>
          </div>
        )}
      </>
    );
  }
}

export default withStyledTranslation('userComment')(UserComment);
UserComment.contextType = AppContext;
