export const getFilteredItems = <T>(
  filterValue: string,
  availableFilters: string[],
  itemList: T[],
  cardNrPrefix?: string,
) => {
  let filteredItems = itemList;
  const normalizedValue = (value: string) =>
    value
      .toLowerCase()
      .trim()
      .normalize('NFD')
      .replace(/\p{Diacritic}/gu, '');
  filterValue = normalizedValue(filterValue);

  if (filterValue !== '') {
    filteredItems = itemList.filter((entity: T) => {
      return availableFilters.some((filterEntry) => {
        if (filterEntry === 'cardNumber') {
          filterEntry = 'number';
        }
        const nestedEntry = filterEntry.split('.');

        if (nestedEntry.length === 1) {
          let entityPropertyValue: any = entity[filterEntry as keyof T];

          if (filterEntry === 'number' && cardNrPrefix) {
            entityPropertyValue = `${cardNrPrefix}-${entityPropertyValue}`;
          }

          if (entityPropertyValue) {
            return normalizedValue(String(entityPropertyValue)).includes(
              filterValue,
            );
          }
          return false;
        } else if (
          nestedEntry.length === 2 &&
          entity[nestedEntry[0] as keyof T]
        ) {
          let entityPropertyValue =
            entity[nestedEntry[0] as keyof T][
              nestedEntry[1] as keyof T[keyof T]
            ];
          return entityPropertyValue
            ? normalizedValue(String(entityPropertyValue)).includes(filterValue)
            : false;
        } else {
          return false;
        }
      });
    });
  }

  return filteredItems;
};
