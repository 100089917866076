import { Component } from 'react';
import { RouteComponentProps } from 'react-router-dom';
import { TRequestStatus } from '../../../../common/types/RequestStatus';
import MenuTabs from '../../../controls/MenuTabs/MenuTabs';
import DeviceOverrides from '../../../partials/Settings/DeviceOverrides';
import { WithTranslation } from 'react-i18next';
import { withStyledTranslation } from '../../../partials/StyledTranslation/StyledTranslation';
import Experiments from '../../../partials/Settings/Experiments';
import GlobalScope from '../../../partials/Settings/GlobalScope';
import CircleOff from '../../../icons/circle_off';
import CircleOn from '../../../icons/circle_on';

interface RouteParams {
  id?: string;
}

type Props = WithTranslation & RouteComponentProps<RouteParams> & {};

interface State {
  formStatus: TRequestStatus;
  invalidPasswordMatch: boolean;
  status: TRequestStatus;
}

class AppSettings extends Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      status: 'loading',
      invalidPasswordMatch: true,
      formStatus: 'idle',
    };
  }

  render() {
    const { t } = this.props;

    return (
      <>
        <div className="flex-row fill">
          <div className="column pt-0">
            <div className="flex-row">
              <div className="column pb-xs">
                <h2 className="primary-title h3 normalcase">
                  {t('AppSettings')}
                </h2>
              </div>
            </div>
            <MenuTabs
              parentUrl={this.props.match.url}
              vertical={true}
              tabs={[
                {
                  id: 'global-scope',
                  label: t('GlobalScope'),
                  icon: 'fal fa-circle',
                  animatedOffIcon: <CircleOff />,
                  animatedOnIcon: <CircleOn />,
                  content: <GlobalScope {...this.props} />,
                },
                {
                  id: 'device-overrides',
                  label: t('DeviceOverrides'),
                  icon: 'fal fa-mobile',
                  content: <DeviceOverrides {...this.props} />,
                },
                {
                  id: 'experiments',
                  label: t('Experiments'),
                  icon: 'fal fa-flask',
                  content: <Experiments {...this.props} />,
                },
              ]}
              defaultTab="global-scope"
              {...this.props}
            />
          </div>
        </div>
      </>
    );
  }
}

export default withStyledTranslation('appSettings')(AppSettings);
