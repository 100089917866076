import { TThumbnails } from '../types/Thumbnails';

export const USER_AVATARS: TThumbnails[] = [
  {
    value: '',
    label: '',
  },
  {
    value: 'business_woman.jpeg',
    label: 'The Achiever',
  },
  {
    value: 'designer.jpeg',
    label: 'The Artist',
  },
  {
    value: 'aspirer.jpeg',
    label: 'The Aspirer',
  },
  {
    value: 'devops.jpeg',
    label: 'The Engineer',
  },
  {
    value: 'workshop.jpeg',
    label: 'The Mentor',
  },
  {
    value: 'businessman_with_coffee.jpeg',
    label: 'The Visionary',
  },
  {
    value: 'outdoor_casual_man.jpeg',
    label: 'The Explorer',
  },
  {
    value: 'young_woman_with_short_hair.jpeg',
    label: 'The Innovator',
  },
  {
    value: 'curly_haired_woman.jpeg',
    label: 'The Dreamer',
  },
  {
    value: 'smiling_young_man.jpeg',
    label: 'The Adventurer',
  },
  {
    value: 'professional_woman_brunette.jpeg',
    label: 'The Strategist',
  },
  {
    value: 'woman_with_blue_pink_hair.jpeg',
    label: 'The Enigma',
  },
  {
    value: 'confident_woman_with_curl.jpeg',
    label: 'The Confidant',
  },
  {
    value: 'woman_with_purple_hair_and_earrings.jpeg',
    label: 'The Maverick',
  },
  {
    value: 'man_in_suit_with_hologram.jpeg',
    label: 'The Futurist',
  },
  {
    value: 'young_woman_with_ponytail.jpeg',
    label: 'The Analyst',
  },
  {
    value: 'smiling_boy_with_hoodie.jpeg',
    label: 'The Prodigy',
  },
  {
    value: 'smiling_man_with_glasses.jpeg',
    label: 'The Scholar',
  },
];
