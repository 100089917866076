import React, { Component, FormEvent } from 'react';
import { TContextMenu } from '../../../common/types/ContextMenu';
import { IDirectionalButton } from '../../../common/interfaces/DirectionalButton';
import Button from '../../controls/Button/Button';
import DirectionalButton from '../../controls/DirectionalButton/DirectionalButton';
import { Trans, WithTranslation } from 'react-i18next';
import { withStyledTranslation } from '../../partials/StyledTranslation/StyledTranslation';
import { TRequestStatus } from '../../../common/types/RequestStatus';
import RequestStatus from '../../partials/RequestStatus/RequestStatus';
import LinkButton from '../../controls/LinkButton/LinkButton';
import BoardContext from '../../../common/contexts/BoardContext';
import NumberControl from '../../controls/NumberControl/NumberControl';

interface State {
  limit: number | undefined;
  formStatus: TRequestStatus;
}

interface Props extends WithTranslation {
  directionalButton?: IDirectionalButton;
  setSelectedContext?: (context: TContextMenu) => void;
  handleSubmitLimit: (e: FormEvent) => Promise<void>;
  setLimit: (value: number) => void;
  totalCards: number;
  limit?: number;
  columnCardLimitState: string;
}

class ColumnLimit extends Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      formStatus: 'idle',
      limit: this.props.limit ? this.props.limit : undefined,
    };
  }

  setLimit = (e: React.ChangeEvent<HTMLInputElement> | number) => {
    if (typeof e === 'number') {
      this.setState({
        limit: e,
      });
      this.props.setLimit(e);
    } else {
      this.setState({
        limit: Number(e.target.value),
      });
      this.props.setLimit(Number(e.target.value));
    }
  };

  handleSubmit = (e: React.FormEvent) => {
    this.setState({
      formStatus: 'loading',
    });
    this.props.handleSubmitLimit(e);

    this.setState(
      {
        formStatus: 'success',
      },
      () => {
        return this.props.setSelectedContext!('manageColumn');
      },
    );
  };

  render() {
    const { t, columnCardLimitState } = this.props;

    return (
      <>
        {this.props.directionalButton && (
          <li>
            <DirectionalButton
              directionalButton={{
                text: this.props.directionalButton.text,
                direction: this.props.directionalButton.direction,
                onClick: this.props.directionalButton.onClick,
              }}
              className="flex-h-start"
            />
          </li>
        )}
        <li>
          <hr />
        </li>
        <li>
          <div className="flex-row fill">
            <div className="column py-0">
              {columnCardLimitState !== 'disabled' ? (
                <form
                  autoComplete="off"
                  onSubmit={this.handleSubmit}
                >
                  <NumberControl
                    id="listLimit"
                    name="limit"
                    onChange={(e) => {
                      this.setLimit(e);
                      this.setState({ formStatus: 'idle' });
                    }}
                    value={Number(this.state.limit) || undefined}
                    minValue={0}
                    maxValue={9999}
                    label={t('editLimit')}
                    br={true}
                    className="mb-xs"
                  />
                  <div className="pb-sm">
                    <small>
                      <Trans
                        i18nKey="columnLimit:noteLimit"
                        values={{
                          columnCardLimitState: t(columnCardLimitState),
                        }}
                        components={{
                          a: (
                            <LinkButton
                              to={`/board/${this.context.board.id}/board-settings`}
                              className="link-button"
                            ></LinkButton>
                          ),
                          span: <span className="text"></span>,
                          strong: <strong></strong>,
                        }}
                      />
                    </small>
                  </div>
                  <ul className="control-list-component">
                    <li>
                      <Button className="primary-button">
                        <RequestStatus status={this.state.formStatus} />
                        {t('saveLimitButton')}
                      </Button>
                    </li>
                    <li>
                      <Button
                        className="ghost-button"
                        title={t('closeFormButton')}
                        onClick={() =>
                          this.props.setSelectedContext &&
                          this.props.setSelectedContext('manageColumn')
                        }
                      >
                        <span className="fas fa-times pe-none"></span>
                      </Button>
                    </li>
                  </ul>
                </form>
              ) : (
                <div className="pb-sm">
                  <small style={{ whiteSpace: 'normal', maxWidth: '245px' }}>
                    <Trans
                      i18nKey="columnLimit:noteDisabled"
                      values={{
                        columnCardLimitState: t(columnCardLimitState),
                      }}
                      components={{
                        a: (
                          <LinkButton
                            to={`/board/${this.context.board.id}/board-settings`}
                            className="link-button"
                          ></LinkButton>
                        ),
                        span: <span className="text"></span>,
                        strong: <strong></strong>,
                      }}
                    />
                  </small>
                </div>
              )}
            </div>
          </div>
        </li>
      </>
    );
  }
}

export default withStyledTranslation('columnLimit')(ColumnLimit);
ColumnLimit.contextType = BoardContext;
