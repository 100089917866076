import React, { Component } from 'react';
import ReactDOM from 'react-dom';
import Draggable, { DraggableData, DraggableEvent } from 'react-draggable';
import { unite } from '../../../common/helpers/unite';
import AppContext from '../../../common/contexts/AppContext';

interface Config {
  bounds: { top: number; bottom: number; left: number; right: number };
  vibrationDuration: number;
  detachDistance: number;
  scaleDivisor: number;
  flyoutStyles: {
    detached: React.CSSProperties;
    dragging: React.CSSProperties;
    default: React.CSSProperties;
  };
}

const config: Config = {
  bounds: { top: 0, bottom: 372, left: -1510, right: -10 },
  vibrationDuration: 10,
  detachDistance: 30,
  scaleDivisor: 1000,
  flyoutStyles: {
    detached: {
      maxWidth: '400px',
      height: '50dvh',
      animation: 'none',
      pointerEvents: 'auto',
      opacity: 1,
    },
    dragging: {
      animation: 'none',
      opacity: 1,
      pointerEvents: 'auto',
    },
    default: {
      pointerEvents: 'auto',
    },
  },
};

interface Props {
  showFlyout?: boolean;
  flyoutAnimation?: boolean;
  onPaste?: (event: React.ClipboardEvent<HTMLDivElement>) => void;
  isClosing?: boolean;
}

interface State {
  position: { x: number; y: number };
  startPosition: { x: number; y: number };
  deltaPosition: { x: number; y: number };
  isDetached: boolean;
  isDragging: boolean;
  isStretched: boolean;
  transformOrigin: string;
}

class Flyout extends Component<Props, State> {
  private root: HTMLDivElement | null;
  private animationFrame: number | null = null;
  private resetTimeout: number | null = null;

  constructor(props: Props) {
    super(props);
    this.root = document.querySelector('.main-component') as HTMLDivElement;
    this.state = {
      position: { x: 0, y: 0 },
      startPosition: { x: 0, y: 0 },
      deltaPosition: { x: 0, y: 0 },
      isDetached: false,
      isDragging: false,
      isStretched: false,
      transformOrigin: 'center center',
    };
  }

  componentDidMount(): void {
    const loggedUser = this.context.loggedUser;
    if (loggedUser) {
      const savedState = loggedUser.localSettings.experiments.detachableFlyout
        ? JSON.parse(
            localStorage.getItem(`flyoutState-${loggedUser.id}`) ?? '{}',
          )
        : JSON.parse('{}');
      if (savedState.position) {
        this.setState({
          position: savedState.position,
          isDetached: savedState.isDetached || false,
        });
      }
    }
  }

  componentWillUnmount(): void {
    if (this.animationFrame) {
      cancelAnimationFrame(this.animationFrame);
    }
    if (this.resetTimeout) {
      clearTimeout(this.resetTimeout);
    }
  }

  handleStart = (e: DraggableEvent, data: DraggableData): void => {
    this.setState({
      startPosition: { x: data.x, y: data.y },
      isDragging: true,
      isStretched: false,
    });
    if (window.navigator.vibrate) {
      window.navigator.vibrate(config.vibrationDuration);
    }
    if (this.resetTimeout) {
      clearTimeout(this.resetTimeout);
      this.resetTimeout = null;
    }
  };

  handleDrag = (e: DraggableEvent, data: DraggableData): void => {
    const { x, y } = data;
    const distance = Math.sqrt(x * x + y * y);
    const deltaX = x - this.state.startPosition.x;
    const deltaY = y - this.state.startPosition.y;

    let transformOrigin = { x: 'left', y: 'top' };
    if (deltaX < 0) {
      transformOrigin.x = 'right';
    }
    if (deltaY < 0) {
      transformOrigin.y = 'bottom';
    }

    if (this.animationFrame) {
      cancelAnimationFrame(this.animationFrame);
    }

    this.animationFrame = requestAnimationFrame(() => {
      this.setState({
        deltaPosition: { x: deltaX, y: deltaY },
        isStretched: distance <= config.detachDistance,
        isDetached:
          distance > config.detachDistance ||
          localStorage.getItem(`flyoutState-${this.context.loggedUser.id}`) !==
            null,
        transformOrigin: `${transformOrigin.y} ${transformOrigin.x}`,
      });
    });

    if (this.resetTimeout) {
      clearTimeout(this.resetTimeout);
      this.resetTimeout = null;
    }
  };

  handleStop = (e: DraggableEvent, data: DraggableData): void => {
    const { x, y } = this.state.deltaPosition;
    const distance = Math.sqrt(x * x + y * y);
    if (distance === 0) {
      return;
    }
    const newPosition = {
      x: this.state.position.x + x,
      y: this.state.position.y + y,
    };

    const clampedPosition = {
      x: Math.max(
        config.bounds.left,
        Math.min(newPosition.x, config.bounds.right),
      ),
      y: Math.max(
        config.bounds.top,
        Math.min(newPosition.y, config.bounds.bottom),
      ),
    };

    if (window.navigator.vibrate) {
      window.navigator.vibrate(config.vibrationDuration);
    }
    if (distance > config.detachDistance || this.state.isDetached) {
      this.resetTimeout = window.setTimeout(() => {
        this.setState(
          {
            position: clampedPosition,
            deltaPosition: { x: 0, y: 0 },
            isDragging: false,
            isStretched: false,
            isDetached: true,
          },
          () => {
            localStorage.setItem(
              `flyoutState-${this.context.loggedUser.id}`,
              JSON.stringify({
                position: clampedPosition,
                isDetached: true,
              }),
            );
          },
        );
        this.resetTimeout = null;
      }, 200);
    } else {
      this.setState(
        {
          deltaPosition: { x: 0, y: 0 },
          isDragging: false,
          isStretched: false,
        },
        () => {
          localStorage.removeItem(`flyoutState-${this.context.loggedUser.id}`);
        },
      );
    }
  };

  handleReset = (): void => {
    localStorage.removeItem(`flyoutState-${this.context.loggedUser.id}`);
    if (this.resetTimeout) {
      clearTimeout(this.resetTimeout);
    }
    this.setState({
      position: { x: 0, y: 0 },
      deltaPosition: { x: 0, y: 0 },
      isStretched: false,
      isDetached: false,
    });
  };

  render() {
    const experiments = this.context.loggedUser?.localSettings.experiments;

    const {
      position,
      deltaPosition,
      isDetached,
      isDragging,
      isStretched,
      transformOrigin,
    } = this.state;
    const currentPos = {
      x: position.x + deltaPosition.x,
      y: position.y + deltaPosition.y,
    };

    const scaleX = 1 + Math.abs(deltaPosition.x) / config.scaleDivisor;
    const scaleY = 1 + Math.abs(deltaPosition.y) / config.scaleDivisor;

    const stretchStyle = isStretched
      ? {
          transform: `scale(${scaleX}, ${scaleY})`,
          transformOrigin: transformOrigin,
        }
      : {
          transform: 'scale(1, 1)',
        };

    return this.root
      ? ReactDOM.createPortal(
          this.props.showFlyout && (
            <Draggable
              position={currentPos}
              onStart={this.handleStart}
              onDrag={this.handleDrag}
              onStop={this.handleStop}
              bounds={config.bounds}
              handle=".drag-handle"
            >
              <div
                onPaste={this.props.onPaste ?? undefined}
                className={unite(
                  'card-flyout-component',
                  this.props.showFlyout === true &&
                    this.props.isClosing !== true
                    ? 'show-flyout'
                    : 'hide-flyout',
                  {
                    instant: this.props.flyoutAnimation === false,
                  },
                )}
                style={
                  isDetached
                    ? { ...config.flyoutStyles.detached, ...position }
                    : isDragging
                    ? config.flyoutStyles.dragging
                    : config.flyoutStyles.default
                }
              >
                <div
                  className={`card pt-xs ${isDetached ? 'detached' : ''} ${
                    isStretched ? 'stretched' : ''
                  }`}
                  style={
                    isDragging && !isDetached
                      ? {
                          ...stretchStyle,
                        }
                      : {}
                  }
                >
                  {isDetached && (
                    <button
                      onClick={this.handleReset}
                      className="secondary-button top right"
                      style={{ transform: 'translate(calc(-100% - 4px), 3px)' }}
                    >
                      <span className="fas fa-lock"></span>
                    </button>
                  )}
                  {experiments.detachableFlyout && (
                    <span
                      className="fas fa-horizontal-rule center drag-handle py-xs px-xs"
                      style={{
                        top: '4px',
                        cursor: isDragging ? 'grabbing' : 'grab',
                        zIndex: 10,
                      }}
                    ></span>
                  )}
                  {this.props.showFlyout && this.props.children}
                </div>
              </div>
            </Draggable>
          ),
          this.root,
        )
      : null;
  }
}

Flyout.contextType = AppContext;

export default Flyout;
