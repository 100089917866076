import React, { Component, ChangeEvent, createRef } from 'react';
import { withStyledTranslation } from '../../../partials/StyledTranslation/StyledTranslation';
import { WithTranslation } from 'react-i18next';
import Button from '../../../controls/Button/Button';
import NumberControl from '../../../controls/NumberControl/NumberControl';

interface Props extends WithTranslation {
  uxStep: number;
  onNext: (
    familiarity: 'exploring' | 'usedBefore' | 'expert' | undefined,
    organizingWith: 'justMe' | 'team' | undefined,
    goal: 'personal' | 'work' | undefined,
    peopleCount: number,
  ) => void;
}

interface State {
  familiarity: 'exploring' | 'usedBefore' | 'expert' | undefined;
  organizingWith: 'justMe' | 'team' | undefined;
  goal: 'personal' | 'work' | undefined;
  peopleCount: number;
}

class StepIntro extends Component<Props, State> {
  familiarityRef = createRef<HTMLDivElement>();
  organizingWithRef = createRef<HTMLDivElement>();
  peopleCountRef = createRef<HTMLDivElement>();
  goalRef = createRef<HTMLDivElement>();

  constructor(props: Props) {
    super(props);
    this.state = {
      familiarity: undefined,
      organizingWith: undefined,
      peopleCount: 2,
      goal: undefined,
    };
  }

  handleRadioChange = (event: ChangeEvent<HTMLInputElement>) => {
    const { name, value } = event.target;
    this.setState(
      { [name]: value } as unknown as Pick<State, keyof State>,
      () => {
        if (name === 'familiarity' && this.organizingWithRef.current) {
          this.organizingWithRef.current.scrollIntoView({ behavior: 'smooth' });
        }
        if (name === 'organizingWith' && this.peopleCountRef.current) {
          this.peopleCountRef.current.scrollIntoView({ behavior: 'smooth' });
        }
        if (
          (name === 'organizingWith' || name === 'goal') &&
          this.goalRef.current
        ) {
          this.goalRef.current.scrollIntoView({ behavior: 'smooth' });
        }
      },
    );
  };

  handleSeatsChange = (value: ChangeEvent<HTMLInputElement> | number) => {
    const seatsValue =
      typeof value === 'number' ? value : Number(value.target.value);
    this.setState({ peopleCount: seatsValue }, () => {
      if (this.goalRef.current) {
        this.goalRef.current.scrollIntoView({ behavior: 'smooth' });
      }
    });
  };

  handleInputBlur = () => {
    const { peopleCount } = this.state;
    if (peopleCount < 2) {
      this.setState({ peopleCount: 2 });
    }
  };

  handleNext = () => {
    const { onNext } = this.props;
    const { familiarity, organizingWith, goal, peopleCount } = this.state;
    const validatedPeopleCount = peopleCount < 2 ? 2 : peopleCount;
    onNext(familiarity, organizingWith, goal, validatedPeopleCount);
  };

  render() {
    const { t } = this.props;
    const { familiarity, organizingWith, peopleCount, goal } = this.state;

    return (
      <div className="flex-row fill">
        <div className="column flex-v-center flex-h-center">
          <span className="oobe-icon icon bg-text fad fa-tasks"></span>
        </div>
        <div className="column large text-left flex-v-center pb-0">
          <div>
            <h2>{t('step1.title', { count: this.props.uxStep })}</h2>
            <div className="scrollable-content">
              <p>{t('step1.familiarity.question')}</p>
              <div className="radio-container">
                <input
                  type="radio"
                  id="familiarity-exploring"
                  name="familiarity"
                  value="exploring"
                  checked={familiarity === 'exploring'}
                  onChange={this.handleRadioChange}
                />
                <label htmlFor="familiarity-exploring">
                  <div className="flex-row">
                    <div className="column icon-column smallest">
                      <span className="text-2xl fas fa-compass"></span>
                    </div>
                    <div className="column text-column largest flex-v-center">
                      {t('step1.familiarity.exploring')}
                    </div>
                  </div>
                </label>
              </div>
              <div className="radio-container">
                <input
                  type="radio"
                  id="familiarity-usedBefore"
                  name="familiarity"
                  value="usedBefore"
                  checked={familiarity === 'usedBefore'}
                  onChange={this.handleRadioChange}
                />
                <label htmlFor="familiarity-usedBefore">
                  <div className="flex-row">
                    <div className="column icon-column smallest">
                      <span className="text-2xl fas fa-check"></span>
                    </div>
                    <div className="column text-column largest flex-v-center">
                      {t('step1.familiarity.usedBefore')}
                    </div>
                  </div>
                </label>
              </div>
              <div className="radio-container">
                <input
                  type="radio"
                  id="familiarity-expert"
                  name="familiarity"
                  value="expert"
                  checked={familiarity === 'expert'}
                  onChange={this.handleRadioChange}
                />
                <label htmlFor="familiarity-expert">
                  <div className="flex-row">
                    <div className="column icon-column smallest">
                      <span className="text-2xl fas fa-graduation-cap"></span>
                    </div>
                    <div className="column text-column largest flex-v-center">
                      {t('step1.familiarity.expert')}
                    </div>
                  </div>
                </label>
              </div>
              {familiarity && (
                <>
                  <div
                    className="reveal-up-1 mt-md"
                    ref={this.organizingWithRef}
                  >
                    <p>{t('step1.organizingWith.question')}</p>
                    <div className="radio-container">
                      <input
                        type="radio"
                        id="organizingWith-justMe"
                        name="organizingWith"
                        value="justMe"
                        checked={organizingWith === 'justMe'}
                        onChange={this.handleRadioChange}
                      />
                      <label htmlFor="organizingWith-justMe">
                        <div className="flex-row">
                          <div className="column icon-column smallest">
                            <span className="text-2xl fas fa-user"></span>
                          </div>
                          <div className="column text-column largest flex-v-center">
                            {t('step1.organizingWith.justMe')}
                          </div>
                        </div>
                      </label>
                    </div>
                    <div className="radio-container">
                      <input
                        type="radio"
                        id="organizingWith-team"
                        name="organizingWith"
                        value="team"
                        checked={organizingWith === 'team'}
                        onChange={this.handleRadioChange}
                      />
                      <label htmlFor="organizingWith-team">
                        <div className="flex-row">
                          <div className="column icon-column smallest">
                            <span className="text-2xl fas fa-users"></span>
                          </div>
                          <div className="column text-column largest flex-v-center">
                            {t('step1.organizingWith.team')}
                          </div>
                        </div>
                      </label>
                    </div>
                  </div>

                  {organizingWith === 'team' && (
                    <div
                      className="reveal-up-1 mt-md"
                      ref={this.peopleCountRef}
                    >
                      <p>{t('step1.peopleCount.question')}</p>
                      <NumberControl
                        label={t('step1.peopleCount.question')}
                        name="seats"
                        id="seats"
                        minValue={2}
                        maxValue={99}
                        placeholder={t('numberOfSeatsPlaceholder')}
                        onChange={this.handleSeatsChange}
                        value={peopleCount}
                        required={true}
                        br={true}
                        srOnly={true}
                      />
                    </div>
                  )}

                  {organizingWith && (
                    <div
                      className="reveal-up-1 mt-md"
                      ref={this.goalRef}
                    >
                      <p>
                        {t(
                          organizingWith === 'justMe'
                            ? 'step1.goal.justMe'
                            : 'step1.goal.team',
                        )}
                      </p>
                      <div className="radio-container">
                        <input
                          type="radio"
                          id="goal-personal"
                          name="goal"
                          value="personal"
                          checked={goal === 'personal'}
                          onChange={this.handleRadioChange}
                        />
                        <label htmlFor="goal-personal">
                          <div className="flex-row">
                            <div className="column icon-column smallest">
                              <span className="text-2xl fas fa-heart"></span>
                            </div>
                            <div className="column text-column largest flex-v-center">
                              {t(
                                organizingWith === 'justMe'
                                  ? 'step1.goal.personalJustMe'
                                  : 'step1.goal.personalTeam',
                              )}
                            </div>
                          </div>
                        </label>
                      </div>
                      <div className="radio-container">
                        <input
                          type="radio"
                          id="goal-work"
                          name="goal"
                          value="work"
                          checked={goal === 'work'}
                          onChange={this.handleRadioChange}
                        />
                        <label htmlFor="goal-work">
                          <div className="flex-row">
                            <div className="column icon-column smallest">
                              <span className="text-2xl fas fa-briefcase"></span>
                            </div>
                            <div className="column text-column largest flex-v-center">
                              {t(
                                organizingWith === 'justMe'
                                  ? 'step1.goal.workJustMe'
                                  : 'step1.goal.workTeam',
                              )}
                            </div>
                          </div>
                        </label>
                      </div>
                    </div>
                  )}
                </>
              )}
            </div>

            {goal && organizingWith === 'team' && (
              <div className="trial-card">
                <p className="mb-0">{t('step1.teamTrialMessage')}</p>
                <div className="button-container mt-0">
                  <Button
                    className="primary-button"
                    onClick={this.handleNext}
                  >
                    {t('activateTrial')}
                  </Button>
                </div>
              </div>
            )}

            <div className="button-container">
              <Button
                className="primary-button"
                onClick={this.handleNext}
                disabled={!goal || !organizingWith}
              >
                {t('next')}{' '}
                {goal && organizingWith === 'team' && t('skipTrial')}
              </Button>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default withStyledTranslation('stepIntro')(StepIntro);
