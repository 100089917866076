import React from 'react';
import { BoardItemDTO } from '../../../common/api/dtos/Board';
import BoardListingCard from '../board/BoardListing/BoardListingCard';
import {
  DragDropContext,
  Draggable,
  DraggableProvided,
  DraggableStateSnapshot,
  DropResult,
  Droppable,
  DroppableProvided,
  DroppableStateSnapshot,
} from '../../../vendors/wavemyth/react-beautiful-dnd/src';
import QuickAccessDraggable from './QuickAccessDraggable';
import BoardListingDraggable from '../board/BoardListing/BoardListingDraggable';
import { moveQuickAccess } from '../../../common/api/endpoints/board';
import { unite } from '../../../common/helpers/unite';

interface Props {
  elements: BoardItemDTO[];
}

interface State {
  quickAccessBoards: BoardItemDTO[];
}

export class QuickAccessDroppable extends React.PureComponent<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      quickAccessBoards: props.elements,
    };
  }
  componentDidMount(): void {}

  componentDidUpdate(
    prevProps: Readonly<Props>,
    prevState: Readonly<{}>,
    snapshot?: any,
  ): void {
    if (this.props.elements !== prevProps.elements) {
      this.setState({
        quickAccessBoards: this.props.elements,
      });
    }
  }

  handleDragAndDrop = async (result: DropResult) => {
    const { destination, source, draggableId } = result;
    const items = [...this.state.quickAccessBoards];
    if (!destination) return;
    if (window.navigator.vibrate) {
      window.navigator.vibrate(10);
    }
    const [item] = items.splice(source.index, 1);
    try {
      items.splice(destination.index, 0, item);
      this.setState({ quickAccessBoards: items });
      await moveQuickAccess(draggableId, { index: destination.index });
    } catch (err) {
      console.debug(err);
    }
  };

  onDragStart = () => {
    if (window.navigator.vibrate) {
      window.navigator.vibrate(10);
    }
  };
  onDragUpdate = () => {
    if (window.navigator.vibrate) {
      window.navigator.vibrate(10);
    }
  };

  render() {
    return (
      <DragDropContext
        onDragStart={this.onDragStart}
        onDragUpdate={this.onDragUpdate}
        onDragEnd={this.handleDragAndDrop}
      >
        <Droppable droppableId={'quickAccess'}>
          {(
            droppableProvided: DroppableProvided,
            droppableSnapshot: DroppableStateSnapshot,
          ) => (
            <QuickAccessDraggable provided={droppableProvided}>
              {this.state.quickAccessBoards.map(
                (board: BoardItemDTO, index) => {
                  return (
                    <Draggable
                      key={board.id}
                      draggableId={board.id}
                      index={index}
                    >
                      {(
                        provided: DraggableProvided,
                        snapshot: DraggableStateSnapshot,
                      ) => (
                        <BoardListingDraggable
                          key={board.id}
                          provided={provided}
                          snapshot={snapshot}
                          innerRef={provided.innerRef}
                          tabIndex={-1}
                        >
                          <div className="column large px-2xs flex-v-stretch pb-0">
                            <BoardListingCard
                              thumbnail={board.thumbnail}
                              id={board.id}
                              name={board.name}
                              color={board.color}
                              favorite={board.favorite}
                              // className={'py-xs px-xs'}
                              className={unite(
                                'py-xs px-xs',
                                droppableSnapshot.isDraggingOver
                                  ? 'dragging-over'
                                  : '',
                                snapshot.isDragging ? 'card opaque' : '',
                              )}
                              small={true}
                              showReorder={false}
                            />
                          </div>
                        </BoardListingDraggable>
                      )}
                    </Draggable>
                  );
                },
              )}
              {droppableProvided.placeholder}
            </QuickAccessDraggable>
          )}
        </Droppable>
      </DragDropContext>
    );
  }
}

export default QuickAccessDroppable;
