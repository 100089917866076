import React, { useState, useEffect, useContext } from 'react';
import Joi from 'joi';
import {
  createSubtask,
  listSubtasks,
  updateSubtask,
  removeSubtask,
  SubtaskDTO,
} from '../../../../common/api/endpoints/subtask';
import CheckBox from '../../../controls/CheckBox/CheckBox';
import Button from '../../../controls/Button/Button';
import TextBox from '../../../controls/TextBox/TextBox';
import ContextMenu from '../../../controls/ContextMenu/ContextMenu';
import Dialog from '../../../controls/Dialog/Dialog';
import BoardContext from '../../../../common/contexts/BoardContext';
import LinkButton from '../../../controls/LinkButton/LinkButton';
import BoardCard from '../BoardCard/BoardCard';
import { ICard } from '../../../../common/interfaces/BoardContext';
import AppContext from '../../../../common/contexts/AppContext';
import { TCardData } from '../../../../common/types/CardData';

// Validation schema for subtask title
const subtaskSchema = Joi.string().min(1).max(1024).required().label('Title');

interface Props {
  cardId: string; // Card ID to fetch and operate on subtasks
  selectedCardData: TCardData[];
  updateCardActivityItems: () => void;
  handleAddSubtaskWs?: () => void;
  handleUpdateSubtaskWs?: (checked: boolean) => void;
  handleRemoveSubtaskWs?: () => void;
}

const CardSubtask: React.FC<Props> = ({
  cardId,
  selectedCardData,
  updateCardActivityItems,
  handleAddSubtaskWs,
  handleUpdateSubtaskWs,
  handleRemoveSubtaskWs,
}) => {
  const context = useContext(BoardContext);
  const appContext = useContext(AppContext);
  const prefix = context.board.cardNrPrefix; // Dynamic prefix from context

  const [subtasks, setSubtasks] = useState<SubtaskDTO[]>([]);
  const [loading, setLoading] = useState<boolean>(true);
  const [error, setError] = useState<string | null>(null);
  const [newSubtaskTitle, setNewSubtaskTitle] = useState<string>('');
  const [validationError, setValidationError] = useState<string | null>(null);
  const [editingSubtaskId, setEditingSubtaskId] = useState<string | null>(null);
  const [editedTitle, setEditedTitle] = useState<string>('');
  const [subtaskToDelete, setSubtaskToDelete] = useState<string | null>(null);

  useEffect(() => {
    fetchSubtasks();
  }, [cardId]);

  const fetchSubtasks = async () => {
    try {
      const subtasks = await listSubtasks(cardId);
      setSubtasks(subtasks);
    } catch (err) {
      setError('Failed to load subtasks');
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (context.addWsListener) {
      context.addWsListener('add_subtask', fetchSubtasks);
      context.addWsListener('update_subtask', fetchSubtasks);
      context.addWsListener('remove_subtask', fetchSubtasks);
    }

    return () => {
      if (context.removeWsListener) {
        context.removeWsListener('add_subtask', fetchSubtasks);
        context.removeWsListener('update_subtask', fetchSubtasks);
        context.removeWsListener('remove_subtask', fetchSubtasks);
      }
    };
  }, [context]);

  const handleSubtaskToggle = async (id: string) => {
    const subtask = subtasks.find((s) => s.id === id);
    if (!subtask) return;

    try {
      const updatedSubtask = await updateSubtask(
        subtask.id,
        subtask.title,
        !subtask.checked,
      );
      setSubtasks((prev) =>
        prev.map((s) => (s.id === id ? updatedSubtask : s)),
      );
      updateCardActivityItems();
    } catch (err) {
      setError('Failed to update subtask');
    }
  };

  const handleAddSubtask = async () => {
    const validationResult = subtaskSchema.validate(newSubtaskTitle);

    if (validationResult.error) {
      setValidationError(validationResult.error.details[0].message);
      return;
    }

    try {
      const newSubtask = await createSubtask(cardId, newSubtaskTitle);
      setSubtasks((prev) => [...prev, newSubtask]);
      setNewSubtaskTitle('');
      setValidationError(null);
      handleAddSubtaskWs!();
      updateCardActivityItems();
    } catch (err) {
      setError('Failed to add subtask');
    }
  };

  const handleRemoveSubtask = async () => {
    if (!subtaskToDelete) return;

    try {
      await removeSubtask(subtaskToDelete);
      setSubtasks((prev) => prev.filter((s) => s.id !== subtaskToDelete));
      setSubtaskToDelete(null);
      handleRemoveSubtaskWs!();
      updateCardActivityItems();
    } catch (err) {
      setError('Failed to remove subtask');
    }
  };

  const handleEditSubtask = async (id: string) => {
    const validationResult = subtaskSchema.validate(editedTitle);

    if (validationResult.error) {
      setValidationError(validationResult.error.details[0].message);
      return;
    }

    try {
      const updatedSubtask = await updateSubtask(id, editedTitle, false);
      setSubtasks((prev) =>
        prev.map((s) => (s.id === id ? updatedSubtask : s)),
      );
      setEditingSubtaskId(null);
      setEditedTitle('');
      setValidationError(null);
      handleUpdateSubtaskWs!(false);
      updateCardActivityItems();
    } catch (err) {
      setError('Failed to edit subtask');
    }
  };

  const parseTitleWithLinks = (title: string) => {
    const regex = new RegExp(`#${prefix}-(\\d+)`, 'g');

    return title.split(regex).reduce<React.ReactNode[]>((acc, part, index) => {
      if (index % 2 === 0) {
        // Regular text part
        acc.push(
          <span
            key={`text-${index}`}
            className="text-sm"
          >
            {part}
          </span>,
        );
      } else {
        // This part is the card number from our capture group
        const cardNumber = part;
        const card: ICard | undefined = context.board.columns
          .flatMap((column) => column.cards)
          .find((c) => c.number.toString() === cardNumber);

        if (card) {
          acc.push(
            <ContextMenu
              dept={0}
              key={`context-${index}`}
              triggerContent={
                <button className="link-button text-sm">
                  <span
                    className="text"
                    title={card.title}
                  >
                    {' '}
                    {`#${prefix}-${cardNumber}`}
                  </span>
                </button>
              }
              triggerClassDefault="link-button text-sm"
              contextMenuClassName="align-v-start"
            >
              <LinkButton
                to={`/board/${context.board.id}/view/card/${card.id}`}
              >
                <BoardCard
                  boardCard={card}
                  key={`board-card-${index}`}
                  selected={false}
                  disabled={true}
                  cardNrPrefix={prefix}
                  columnId={null}
                  tags={context.board.tags}
                  priorities={context.board.priorities}
                  members={context.board.members}
                  setCardRef={() => {}}
                  selectCard={() => {}}
                  selectedCardData={selectedCardData}
                  isPeekView={true}
                />
              </LinkButton>
            </ContextMenu>,
          );
        } else {
          acc.push(
            <span
              key={`text-sm missing-${index}`}
            >{`#${prefix}-${cardNumber}`}</span>,
          );
        }
      }
      return acc;
    }, []);
  };

  const completedCount = subtasks.filter((subtask) => subtask.checked).length;
  const totalSubtasks = subtasks.length;
  const progressPercentage =
    totalSubtasks > 0 ? (completedCount / totalSubtasks) * 100 : 0;

  if (loading) return <p>Loading subtasks...</p>;
  if (error) return <p>{error}</p>;

  return (
    <>
      {subtasks.length > 0 && (
        <div className="flex-row accordion-row fill">
          <div className="column pb-xs">
            <div
              className="progress-bar-component mb-xs"
              title={`${progressPercentage.toFixed(2)}%`}
              data-title={`${progressPercentage.toFixed(2)}%`}
            >
              <div className="track pe-none">
                <div
                  className="progress"
                  style={{ width: `${progressPercentage}%` }}
                ></div>
              </div>
            </div>
            <div className="flex-h-spread">
              <small className="faint-text">{completedCount} done</small>
              <small className="faint-text">
                {totalSubtasks - completedCount} remaining
              </small>
            </div>
          </div>
        </div>
      )}
      <div className="accordion-row">
        <div className="flex-row fill">
          <div className="column flex-h-start flex-v-start pb-xs">
            <TextBox
              value={newSubtaskTitle}
              onChange={(e) => setNewSubtaskTitle(e.target.value)}
              placeholder="Enter subtask title..."
              className="subtask-input"
              error={validationError as string}
              label="Subtask Title"
              srOnly={true}
              type="text"
              name="subtaskTitle"
              id="subtaskTitle"
              formGroupClassNames="mb-0"
              onKeyDown={(e) => {
                if (e.key === 'Enter') {
                  handleAddSubtask();
                }
                if (e.key === 'Escape' && newSubtaskTitle) {
                  e.stopPropagation();
                  setNewSubtaskTitle('');
                }
              }}
            />
            <Button
              className="ml-xs primary-button"
              onClick={handleAddSubtask}
              disabled={!newSubtaskTitle}
            >
              Add subtask
            </Button>
          </div>
        </div>
        {subtasks.length > 0 && (
          <>
            <hr />
            <div className="flex-row">
              <div className="column large">
                <ul className="control-list-component vertical">
                  {subtasks.map((subtask) => (
                    <li
                      key={subtask.id}
                      className="fill reveal-up-1"
                    >
                      <div className="flex-v-center">
                        <span className="fas fa-grip-vertical faint-text"></span>
                        <div className="card py-xs px-xs opaque flex-v-center fill">
                          {editingSubtaskId === subtask.id ? (
                            <>
                              <TextBox
                                autofocus={true}
                                value={editedTitle}
                                onChange={(e) => setEditedTitle(e.target.value)}
                                placeholder="Edit subtask title"
                                className="subtask-input"
                                error={validationError as string}
                                label="Subtask title"
                                srOnly={true}
                                type="text"
                                name="editSubtaskTitle"
                                id="editSubtaskTitle"
                                formGroupClassNames="mb-0"
                                onKeyDown={(e) => {
                                  if (e.key === 'Enter') {
                                    handleEditSubtask(subtask.id);
                                  }
                                  if (e.key === 'Escape') {
                                    e.stopPropagation();
                                    setEditingSubtaskId(null);
                                  }
                                }}
                              />
                              <Button
                                className="ml-xs primary-button text-center flex-h-center"
                                onClick={() => handleEditSubtask(subtask.id)}
                              >
                                <div className="pe-none">
                                  <span className="fas fa-check"></span>
                                </div>
                              </Button>
                              <Button
                                className="ml-xs ghost-button text-center flex-h-center"
                                onClick={() => setEditingSubtaskId(null)}
                              >
                                <div className="pe-none">
                                  <span className="fas fa-times"></span>
                                </div>
                              </Button>
                            </>
                          ) : (
                            <>
                              <CheckBox
                                className="mb-0"
                                id={subtask.id}
                                name={subtask.title}
                                checked={subtask.checked}
                                changeMethod={() =>
                                  handleSubtaskToggle(subtask.id)
                                }
                                label={
                                  <>{parseTitleWithLinks(subtask.title)}</>
                                }
                                indent={0}
                                labelClassName="mx-xs fill"
                              />
                              <></>
                              <ContextMenu
                                dept={0}
                                triggerContent={
                                  <span className="fas fa-ellipsis-h"></span>
                                }
                                triggerClassDefault="secondary-button flex-h-end-self"
                                contextMenuClassName="align-v-start"
                              >
                                <>
                                  <li>
                                    <Button
                                      className="ghost-button"
                                      onClick={() => {
                                        setEditingSubtaskId(subtask.id);
                                        setEditedTitle(subtask.title);
                                      }}
                                    >
                                      <span className="fal fa-pencil icon"></span>
                                      <span className="text">Edit subtask</span>
                                    </Button>
                                  </li>
                                  <li>
                                    <Button
                                      className="ghost-button"
                                      onClick={() =>
                                        setSubtaskToDelete(subtask.id)
                                      }
                                    >
                                      <span className="fal fa-trash icon"></span>
                                      <span className="text">
                                        Delete subtask
                                      </span>
                                    </Button>
                                  </li>
                                </>
                              </ContextMenu>
                            </>
                          )}
                        </div>
                      </div>
                    </li>
                  ))}
                </ul>
              </div>

              {subtaskToDelete && (
                <Dialog
                  title="Subtask deletion"
                  message={
                    <>
                      Are you sure you want to delete the{' '}
                      <strong>
                        {subtasks.find(
                          (subtask: SubtaskDTO) =>
                            subtask.id === subtaskToDelete,
                        )?.title ?? ''}
                      </strong>{' '}
                      subtask?
                    </>
                  }
                  cancelText="Cancel"
                  confirmText="Permanently delete"
                  info={
                    <p className="text-sm faint-text">
                      <span className="accent-text-red fas fa-exclamation-circle"></span>{' '}
                      <span>This operation is irreversible.</span>
                    </p>
                  }
                  onConfirm={handleRemoveSubtask}
                  onCancel={() => setSubtaskToDelete(null)}
                />
              )}
            </div>
          </>
        )}
      </div>
    </>
  );
};

export default CardSubtask;
