import { TColor } from '../types/Color';

export const COLORS: TColor[] = [
  {
    id: 'pink',
    color: 'pink',
    label: 'Pink',
    symbol: 'fas fa-triangle',
  },
  {
    id: 'red',
    color: 'red',
    label: 'Red',
    symbol: 'fas fa-triangle',
  },
  {
    id: 'orange',
    color: 'orange',
    label: 'Orange',
    symbol: 'fas fa-triangle',
  },
  {
    id: 'yellow',
    color: 'yellow',
    label: 'Yellow',
    symbol: 'fas fa-triangle',
  },
  {
    id: 'green',
    color: 'green',
    label: 'Green',
    symbol: 'fas fa-triangle',
  },
  {
    id: 'cyan',
    color: 'cyan',
    label: 'Cyan',
    symbol: 'fas fa-triangle',
  },
  {
    id: 'blue',
    color: 'blue',
    label: 'Blue',
    symbol: 'fas fa-triangle',
  },
  {
    id: 'purple',
    color: 'purple',
    label: 'Purple',
    symbol: 'fas fa-triangle',
  },
];
