import React, { Component } from 'react';
import { NavLink } from 'react-router-dom';

interface Props {
  className?: string;
  activeClassName?: string;
  to: string;
  disabled?: boolean;
  target?: string;
  isRoute?: boolean;
  id?: string;
  tabIndex?: number;
  value?: string;
  higherRef?: React.RefObject<HTMLButtonElement>;
  styles?: React.CSSProperties;
  title?: string;
  onKeyDown?: React.KeyboardEventHandler<HTMLButtonElement>;
}

class LinkButton extends Component<Props> {
  render() {
    if (this.props.disabled) {
      return (
        <>
          {' '}
          <div
            title={this.props.title}
            className={
              this.props.className + (this.props.disabled ? ' disabled' : '')
            }
            id={this.props.id}
            tabIndex={this.props.tabIndex}
            style={this.props.styles}
          >
            {this.props.children}
          </div>
        </>
      );
    }
    return (
      <>
        {this.props.isRoute !== false ? (
          <NavLink
            to={this.props.to}
            title={this.props.title}
            target={this.props.target}
            className={
              this.props.className + (this.props.disabled ? ' disabled' : '')
            }
            activeClassName={this.props.activeClassName}
            id={this.props.id}
            tabIndex={this.props.tabIndex}
            style={this.props.styles}
            onDragStart={(e) => e.preventDefault()}
          >
            {this.props.children}
          </NavLink>
        ) : (
          <a
            href={this.props.to}
            title={this.props.title}
            target={this.props.target}
            className={
              this.props.className + (this.props.disabled ? ' disabled' : '')
            }
            id={this.props.id}
            tabIndex={this.props.tabIndex}
            style={this.props.styles}
            onDragStart={(e) => e.preventDefault()}
          >
            {this.props.children}
          </a>
        )}
      </>
    );
  }
}

export default LinkButton;
