import { PureComponent } from 'react';
import { NavLink, Route, RouteComponentProps } from 'react-router-dom';
import AppContext from '../../../common/contexts/AppContext';
import Thumbnail from '../../partials/Thumbnail/Thumbnail';
import ContextMenu from '../../controls/ContextMenu/ContextMenu';
import UserMenu from '../../menus/User/UserMenu';
import LegalMenu from '../../menus/Legal/LegalMenu';
import LinkButton from '../../controls/LinkButton/LinkButton';
import HelpDialog from '../Legal/HelpDialog';

export interface Props {
  handleLogout: () => void;
  lockedAccount?: boolean;
}

interface State {
  showHelpDialog: boolean;
}
class Topbar extends PureComponent<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      showHelpDialog: false,
    };
  }

  componentDidMount() {
    document.addEventListener('keydown', this.handleKeyDown);
  }

  componentWillUnmount() {
    document.removeEventListener('keydown', this.handleKeyDown);
  }

  handleKeyDown = (event: KeyboardEvent) => {
    const activeElement = document.activeElement;
    const isInputField =
      activeElement?.tagName.toLowerCase() === 'input' ||
      activeElement?.tagName.toLowerCase() === 'textarea' ||
      activeElement?.hasAttribute('contenteditable');

    if (!isInputField && event.key === '/') {
      event.preventDefault();
      this.openHelpDialog();
    }
  };

  openHelpDialog = () => {
    this.setState({ showHelpDialog: true });
  };

  render() {
    return (
      <>
        {this.state.showHelpDialog && (
          <HelpDialog
            onClose={() => this.setState({ showHelpDialog: false })}
          />
        )}
        <div
          id="toolbarControls"
          className="toolbar-component bar-content-start"
        >
          <LinkButton
            to="/"
            className="brand-wrapper"
          >
            <div
              className={`dynamic-logo-component ${process.env.REACT_APP_FAVICON}`}
            ></div>
            {/* <img
              alt="logo"
              className="brand brand-contain-v"
              src={process.env.PUBLIC_URL + '/' + process.env.REACT_APP_LOGO}
            /> */}
          </LinkButton>
        </div>
        <div className="bar-content-end">
          <nav className="navigation-component horizontal bar-content-start">
            <ul className="nav-list">
              <li className="nav-li">
                <ContextMenu
                  dept={0}
                  title="Legal menu"
                  triggerContent={
                    <>
                      <span className="far fa-question-circle icon"></span>
                    </>
                  }
                  triggerClassDefault="ghost-button"
                  triggerClassActive="secondary-button"
                >
                  <LegalMenu onOpenHelpDialog={this.openHelpDialog} />
                </ContextMenu>
              </li>
              <li className="nav-li">
                <Route
                  render={(props: RouteComponentProps) => {
                    return (
                      <ContextMenu
                        dept={0}
                        triggerContent={
                          <div className="flex-row py-0 px-0 mx-0">
                            <div className="column flex-v-center py-0 px-0 mx-0">
                              <Thumbnail
                                classes="size-24"
                                avatarData={this.context.loggedUser?.avatar}
                                title={this.context.loggedUser.name}
                              />
                              <span
                                className="icon fas fa-angle-down"
                                style={{
                                  height: '0.8em',
                                }}
                              ></span>
                            </div>
                          </div>
                        }
                        triggerClassDefault="ghost-button"
                        triggerClassActive="secondary-button"
                        {...props}
                      >
                        <UserMenu
                          loggedUser={this.context.loggedUser}
                          handleLogout={this.props.handleLogout}
                          lockedAccount={this.props.lockedAccount}
                        />
                      </ContextMenu>
                    );
                  }}
                />
              </li>
            </ul>
          </nav>
        </div>
      </>
    );
  }
}

export default Topbar;
Topbar.contextType = AppContext;
