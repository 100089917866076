import { TColor } from '../types/Color';

export const TAG_COLORS: TColor[] = [
  {
    id: 'pink',
    color: 'pink',
    label: 'pink',
    symbol: 'fas fa-umbrella',
  },
  {
    id: 'red',
    color: 'red',
    label: 'red',
    symbol: 'fas fa-apple-alt',
  },
  {
    id: 'orange',
    color: 'orange',
    label: 'orange',
    symbol: 'fas fa-fire',
  },
  {
    id: 'yellow',
    color: 'yellow',
    label: 'yellow',
    symbol: 'fas fa-lemon',
  },
  {
    id: 'green',
    color: 'green',
    label: 'green',
    symbol: 'fas fa-leaf',
  },
  {
    id: 'cyan',
    color: 'cyan',
    label: 'cyan',
    symbol: 'fas fa-cloud',
  },
  {
    id: 'blue',
    color: 'blue',
    label: 'blue',
    symbol: 'fas fa-tint',
  },
  {
    id: 'purple',
    color: 'purple',
    label: 'purple',
    symbol: 'fas fa-rocket',
  },
];
