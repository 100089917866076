import React from 'react';
import {
  addBoardTag,
  removeBoardTag,
  updateBoardTag,
} from '../../../common/api/endpoints/tag';
import { TRequestStatus } from '../../../common/types/RequestStatus';
import Button from '../../controls/Button/Button';
import TagForm from '../../forms/TagForm/TagForm';
import { IDirectionalButton } from '../../../common/interfaces/DirectionalButton';
import DirectionalButton from '../../controls/DirectionalButton/DirectionalButton';
import AppContext, { IAppContext } from '../../../common/contexts/AppContext';
import { NotificationMessage } from '../../../common/contexts/NotificationsContext';
import { TagDTO } from '../../../common/api/dtos/Tag';
import { showErrorNotifications } from '../../../common/helpers/showNotifications';
import { TContextMenu } from '../../../common/types/ContextMenu';
import { withContextAdapters } from '../../partials/ContextAdapter/withContextAdapter';
import { UserDTO } from '../../../common/api/dtos/User';
import BoardContext from '../../../common/contexts/BoardContext';
import { withStyledTranslation } from '../../partials/StyledTranslation/StyledTranslation';
import { WithTranslation } from 'react-i18next';
import Dialog from '../../controls/Dialog/Dialog';
import {
  IBoardContext,
  UpdateTagData,
} from '../../../common/interfaces/BoardContext';

interface AppContextProps {
  loggedUser: UserDTO;
  setMessages: (messages: NotificationMessage | NotificationMessage[]) => void;
}
interface BoardContextProps {
  boardId: string;
  tags: TagDTO[];
  addTag: (item: TagDTO) => void;
  updateTag: (tagId: string, data: UpdateTagData) => void;
  removeTag: (tagId: string) => void;
}
interface ExternalProps {
  directionalButton?: IDirectionalButton;
  setSelectedContext?: (context: TContextMenu) => void;
  selectedTag?: TagDTO | null;
  legendText: string;
  selectedContext: TContextMenu;
}
interface Props
  extends ExternalProps,
    AppContextProps,
    BoardContextProps,
    WithTranslation {}

interface State {
  status: TRequestStatus;
  showAddTag: boolean;
  serverErrors: string[];
  showDelete: boolean;
  showDeletionPrompt: boolean;
}

class TagDetailMenu extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      status: 'idle',
      showAddTag: false,
      serverErrors: [],
      showDelete: false,
      showDeletionPrompt: false,
    };
  }

  addTag = async (boardTagData: TagDTO) => {
    this.setState({
      status: 'loading',
    });

    try {
      const newTag = await addBoardTag(boardTagData, this.props.boardId);
      this.props.addTag(newTag);

      this.setState({
        status: 'success',
        showAddTag: false,
      });
      this.props.setSelectedContext &&
        this.props.setSelectedContext('TagManageMenus');
    } catch (err) {
      const error = Array.isArray(err) ? err : [err];
      showErrorNotifications(error, this.props.setMessages);
      this.setState({
        status: 'error',
      });
    }
  };

  editTag = async (tagData: TagDTO) => {
    this.setState({
      status: 'loading',
    });

    try {
      const updatedTag = await updateBoardTag(tagData);
      this.props.updateTag(tagData.id, updatedTag);
      this.props.setSelectedContext &&
        this.props.setSelectedContext('TagManageMenus');
    } catch (err) {
      const error = Array.isArray(err) ? err : [err];
      showErrorNotifications(error, this.props.setMessages);
      this.setState({
        status: 'error',
      });
    }
  };

  removeTag = async (tagId: string) => {
    this.setState({
      status: 'loading',
    });

    try {
      await removeBoardTag(tagId);
      this.props.removeTag(tagId);
      this.props.setSelectedContext &&
        this.props.setSelectedContext('TagManageMenus');
    } catch (err) {
      const error = Array.isArray(err) ? err : [err];
      showErrorNotifications(error, this.props.setMessages);
      this.setState({
        status: 'error',
      });
    }
  };

  handleSubmit = (tagData: TagDTO) => {
    if (this.props.selectedContext === 'addTag') {
      this.addTag(tagData);
    } else if (this.props.selectedContext === 'editTag') {
      this.editTag(tagData);
    }
  };

  handleDelete = () => {
    if (this.props.selectedTag) {
      this.removeTag(this.props.selectedTag.id!);
      this.props.setSelectedContext &&
        this.props.setSelectedContext('TagManageMenus');
    }
  };

  render() {
    const { t } = this.props;
    return (
      <>
        {this.props.directionalButton && (
          <li>
            <DirectionalButton
              directionalButton={{
                text: this.props.directionalButton.text,
                direction: this.props.directionalButton.direction,
                onClick: this.props.directionalButton.onClick,
              }}
              className="flex-h-start"
            />
          </li>
        )}
        {this.state.showDeletionPrompt && (
          <Dialog
            title="Tag deletion"
            message={`Permanently delete this tag?`}
            info={
              <p className="text-sm faint-text">
                <span className="accent-text-red fas fa-exclamation-circle"></span>{' '}
                <span>This operation is irreversible.</span>
              </p>
            }
            cancelText="Cancel"
            confirmText="Permanently delete"
            onCancel={() => this.setState({ showDeletionPrompt: false })}
            onConfirm={() => {
              this.setState({
                showDeletionPrompt: false,
              });
              this.handleDelete();
            }}
          />
        )}
        <li>
          <div className="fill">
            <div className="flex-row fill">
              <div className="column py-0">
                <hr />
                <TagForm
                  legendText={this.props.legendText}
                  handleSubmit={this.handleSubmit}
                  serverErrors={this.state.serverErrors}
                  loggedUser={this.props.loggedUser}
                  selectedTag={this.props.selectedTag}
                  setSelectedContext={this.props.setSelectedContext}
                >
                  {this.props.selectedContext === 'editTag' && (
                    <>
                      <hr />
                      <div className="initiate-delete">
                        <Button
                          className="secondary-button"
                          onClick={() =>
                            this.setState({
                              showDeletionPrompt: true,
                            })
                          }
                        >
                          <span className="text">{t('deleteButtonText')}</span>
                        </Button>
                      </div>
                    </>
                  )}
                </TagForm>
              </div>
            </div>
          </div>
        </li>
      </>
    );
  }
}

const AppContextAdapter = {
  ctx: AppContext,
  adapt: (ctx: IAppContext): AppContextProps => {
    return {
      loggedUser: ctx.loggedUser!,
      setMessages: ctx.notifications.setMessages!,
    };
  },
};
const BoardContextAdapter = {
  ctx: BoardContext,
  adapt: (ctx: IBoardContext): BoardContextProps => {
    return {
      boardId: ctx.board.id,
      tags: ctx.board.tags,
      addTag: ctx.addTag,
      updateTag: ctx.updateTag,
      removeTag: ctx.removeTag,
    };
  },
};

export default withContextAdapters<
  ExternalProps,
  IAppContext,
  AppContextProps,
  IBoardContext,
  BoardContextProps
>(
  withStyledTranslation('tagDetailMenu')(TagDetailMenu),
  AppContextAdapter,
  BoardContextAdapter,
);
