import React, { Component } from 'react';

interface Props {
  badge: 'info' | 'warning' | 'danger';
  children: React.ReactNode;
}

interface State {}

class SubscriptionMessage extends Component<Props, State> {
  render() {
    return (
      <div className="flex-row pt-xs">
        <div className="column medium">
          <span
            className={`fas ${
              this.props.badge === 'info'
                ? 'fa-info-circle accent-text-blue'
                : this.props.badge === 'warning'
                ? 'fa-exclamation-circle accent-text-yellow'
                : 'fa-exclamation-circle accent-text-red'
            }`}
          ></span>
          <span>{this.props.children}</span>
        </div>
      </div>
    );
  }
}

export default SubscriptionMessage;
