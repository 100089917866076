import React, { useState, useEffect } from 'react';

const CoinsOff: React.FC = () => {
  const [key, setKey] = useState(0);

  useEffect(() => {
    setKey((prevKey) => prevKey + 1);
  }, []);

  return (
    <svg
      key={key}
      width="100%"
      height="100%"
      viewBox="0 0 100 100"
      xmlns="http://www.w3.org/2000/svg"
      preserveAspectRatio="xMidYMid meet"
      className="icon"
    >
      <rect
        width="100"
        height="100"
        fill="transparent"
      />

      <defs>
        <g id="coin_outline">
          <path
            d="
            M -30,0 a 30,10 0 1,0 60,0 l 0,5 a 30,10 0 1,1 -60,0 l 0,-5 Z"
            fill="transparent"
            className="coin_on_side"
            stroke="transparent"
            strokeWidth="2"
          />

          <ellipse
            cx="0"
            cy="0"
            rx="30"
            ry="10"
            fill="transparent"
            stroke="transparent"
            className="coin_on_top"
            strokeWidth="2"
          />
        </g>
      </defs>

      <style>
        {`
        .coin_on_top {
            stroke-dashoffset: 0;
            fill: currentColor;
            opacity: 1;
        }
        
        .coin_on_side {
            fill: currentColor;
            stroke-dashoffset: 0;
            opacity: 1;
        }
    
        `}
      </style>

      <use
        href="#coin_outline"
        x="45"
        y="60"
      ></use>
      <use
        href="#coin_outline"
        x="50"
        y="50"
      ></use>

      <use
        href="#coin_outline"
        x="45"
        y="40"
      ></use>
    </svg>
  );
};

export default CoinsOff;
