import React from 'react';
import BoardContext from '../../../../../../common/contexts/BoardContext';
import { updateCard } from '../../../../../../common/api/endpoints/card';
import { showErrorNotifications } from '../../../../../../common/helpers/showNotifications';
import { withContextAdapters } from '../../../../ContextAdapter/withContextAdapter';
import AppContext, {
  IAppContext,
} from '../../../../../../common/contexts/AppContext';
import { NotificationMessage } from '../../../../../../common/contexts/NotificationsContext';
import {
  IBoardContext,
  UpdateCardData,
} from '../../../../../../common/interfaces/BoardContext';

interface ExternalProps {
  // columnId: string;
  cardId: string;
}
interface AppContextProps {
  setMessages: (messages: NotificationMessage | NotificationMessage[]) => void;
}
interface BoardContextProps {
  updateCard: (cardId: string, data: UpdateCardData) => void;
}
interface Props extends ExternalProps, AppContextProps, BoardContextProps {}

class RemovePriorityMenu extends React.Component<Props> {
  removePriority = async (e: React.MouseEvent) => {
    e.stopPropagation();
    try {
      const card = await updateCard(this.props.cardId, { priorityId: null });
      this.props.updateCard(this.props.cardId, { priorityId: card.priorityId });
    } catch (err) {
      console.debug(err);
      showErrorNotifications(err, this.props.setMessages);
    }
  };

  render() {
    return (
      <li>
        <button
          className="ghost-button"
          onClick={this.removePriority}
        >
          Remove priority
        </button>
      </li>
    );
  }
}

const AppContextAdapter = {
  ctx: AppContext,
  adapt: (ctx: IAppContext): AppContextProps => {
    return {
      setMessages: ctx.notifications.setMessages!,
    };
  },
};
const BoardContextAdapter = {
  ctx: BoardContext,
  adapt: (ctx: IBoardContext): BoardContextProps => {
    return {
      updateCard: ctx.updateCard,
    };
  },
};
export default withContextAdapters<
  ExternalProps,
  IAppContext,
  AppContextProps,
  IBoardContext,
  BoardContextProps
>(RemovePriorityMenu, AppContextAdapter, BoardContextAdapter);
