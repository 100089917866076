import { Component } from 'react';
import { TRequestStatus } from '../../../../common/types/RequestStatus';
import RequestStatus from '../../../partials/RequestStatus/RequestStatus';
import Button from '../../../controls/Button/Button';
import AppContext, {
  IAppContext,
} from '../../../../common/contexts/AppContext';
import { NotificationMessage } from '../../../../common/contexts/NotificationsContext';
import { RouteComponentProps } from 'react-router';
import { archiveBoard } from '../../../../common/api/endpoints/board';
import Joi from 'joi';
import { withContextAdapters } from '../../ContextAdapter/withContextAdapter';
import BoardContext from '../../../../common/contexts/BoardContext';
import { InviteeDTO, MemberDTO } from '../../../../common/api/dtos/Member';
import Accordion from '../../../controls/Accordion/Accordion';
import convertBytes from '../../../../common/helpers/convertBytes';
import { NavLink } from 'react-router-dom';
import { Trans, WithTranslation } from 'react-i18next';
import { withStyledTranslation } from '../../StyledTranslation/StyledTranslation';
import { customEmailValidation } from '../../../pages/Auth/Registration/helper/customEmailValidation';
import {
  IBoard,
  IBoardContext,
} from '../../../../common/interfaces/BoardContext';

interface RouteParams {
  routeBoardId: string;
}

interface AppContextProps {
  setMessages: (messages: NotificationMessage | NotificationMessage[]) => void;
}
interface BoardContextProps {
  boardId: string;
  currentUserRole: string;
  setArchived: () => void;
  reloadBoard: () => void;
  board: IBoard;
  boardMembers: (MemberDTO | InviteeDTO)[];
}

interface BoardInfo {
  storageUsage?: number;
}

interface ExternalProps extends RouteComponentProps<RouteParams> {
  boardInfo: BoardInfo;
}
interface Props
  extends ExternalProps,
    AppContextProps,
    BoardContextProps,
    WithTranslation {}

interface State {
  archiveStatus: TRequestStatus;
  transferStatus: TRequestStatus;
  serverErrors: string[];
  transferProcessActive: boolean;
  memberInput: string;
  isValidEmail: boolean;
}

class HouseKeepingTab extends Component<Props, State> {
  static contextType = AppContext;
  emailSchema = Joi.string()
    .required()
    .trim(true)
    .email({ minDomainSegments: 2, tlds: { allow: false } })
    .custom(customEmailValidation);

  constructor(props: Props) {
    super(props);

    this.state = {
      archiveStatus: 'idle',
      transferStatus: 'idle',
      serverErrors: [],
      transferProcessActive: false,
      memberInput: '',
      isValidEmail: false,
    };
  }

  componentDidMount() {}

  archiveBoard = async (id: string) => {
    this.setState({
      archiveStatus: 'loading',
    });

    try {
      await archiveBoard(id);

      this.setState({
        archiveStatus: 'success',
      });

      // update board context
      this.props.setArchived();

      this.props.reloadBoard();
    } catch (err) {
      const error = Array.isArray(err) ? err : [err];

      this.setState({
        archiveStatus: 'error',
        serverErrors: error,
      });
    }
  };

  setIsValidEmail = () => {
    const result = this.emailSchema.validate(this.state.memberInput, {
      abortEarly: false,
    });

    this.setState({
      isValidEmail:
        this.props.currentUserRole === 'owner' ||
        this.props.currentUserRole === 'admin'
          ? !Boolean(result.error)
          : false,
    });
  };

  setMemberInput = (value: string) => {
    this.setState(
      {
        memberInput: value,
      },
      () => {
        this.setIsValidEmail();
      },
    );
  };

  renderSize = (size: number) => {
    let temp = convertBytes(size);
    return temp.value + ' ' + temp.unit;
  };

  renderOcuppiedStorage = (usedStorage: number) => {
    return (
      (usedStorage * 100) / Number(process.env.REACT_APP_TEAM_STORAGE_LIMIT)
    );
  };

  computeStorageLimit = (planType: 'team' | 'pro' | 'free'): number => {
    switch (planType) {
      case 'team':
        return process.env.REACT_APP_TEAM_STORAGE_LIMIT
          ? Number(process.env.REACT_APP_TEAM_STORAGE_LIMIT)
          : 0;
      case 'pro':
        return process.env.REACT_APP_PRO_STORAGE_LIMIT
          ? Number(process.env.REACT_APP_PRO_STORAGE_LIMIT)
          : 0;
      case 'free':
      default:
        return process.env.REACT_APP_FREE_STORAGE_LIMIT
          ? Number(process.env.REACT_APP_FREE_STORAGE_LIMIT)
          : 0;
    }
  };

  computeBoardPlan = (): 'free' | 'pro' | 'team' => {
    let BOARD_PLAN: 'free' | 'pro' | 'team' = 'free';
    if (this.props.board?.teamId && this.props.board.paid) {
      BOARD_PLAN = 'team';
    } else if (!this.props.board?.teamId && this.props.board?.paid) {
      BOARD_PLAN = 'pro';
    }
    return BOARD_PLAN;
  };

  renderAttachmentSpace = () => {
    const { t } = this.props;
    const STORAGE_LIMIT = this.computeStorageLimit(this.computeBoardPlan());

    return (
      <div className="flex-row fill">
        <div className="column">
          <p className="mb-xs">{t('boardAttachments')}</p>
          <div
            className="progress-bar-component mb-xs"
            title={`${this.renderOcuppiedStorage(
              this.props.boardInfo.storageUsage!,
            )}%`}
          >
            <div className="track pe-none">
              <div
                className="progress"
                style={{
                  width: `${this.renderOcuppiedStorage(
                    this.props.boardInfo.storageUsage!,
                  )}%`,
                }}
              ></div>
            </div>
          </div>
          <div className="flex-h-spread">
            <small className="faint-text">
              {this.renderSize(this.props.boardInfo.storageUsage!)} {t('used')}
            </small>
            <small className="faint-text">
              {this.renderSize(
                STORAGE_LIMIT - this.props.boardInfo.storageUsage!,
              )}{' '}
              {t('free')}
            </small>
          </div>
        </div>
      </div>
    );
  };

  render() {
    const { t } = this.props;
    const BOARD_PLAN = this.computeBoardPlan();
    const STORAGE_LIMIT = this.computeStorageLimit(BOARD_PLAN);

    return (
      <>
        <div className="flex-row fill">
          <div className="column pt-2xs">
            <Accordion
              accordionSlug="board-storage"
              isOpen={true}
              iconClasses="fal fa-database icon"
              title={t('storage')}
              subheading={t('checkSpace')}
              disabled={true}
              softDisabled={true}
              hideArrow={true}
              translucent={true}
            >
              {BOARD_PLAN === 'team' && (
                <div className="accordion-row py-sm">
                  {this.renderAttachmentSpace()}
                </div>
              )}

              {BOARD_PLAN === 'free' && (
                <div className="accordion-row py-sm">
                  {this.renderAttachmentSpace()}
                  <div className="flex-row">
                    <div className="column medium">
                      <p className="text-sm">
                        <Trans
                          i18nKey="houseKeepingTab:tip"
                          components={{
                            tip: <span />,
                            increase: <span className="no-wrap" />,
                            increaseLimit: <span />,
                            limitValue: <span className="no-wrap" />,
                            perBoard: <span />,
                            pro: <span className="badge-text accent-yellow" />,
                            plan: <span />,
                            period: <span />,
                          }}
                        />
                      </p>
                      <form
                        target="_blank"
                        method="POST"
                        action={`${process.env.REACT_APP_API_BASE_URL}/self/upgrade`}
                      >
                        <button className="secondary-button">
                          <span className="text">{t('upgradeToPro')}</span>
                          <span className="far fa-external-link icon"></span>
                        </button>
                      </form>
                    </div>
                  </div>
                </div>
              )}

              {BOARD_PLAN === 'pro' && (
                <div className="accordion-row py-sm">
                  {this.renderAttachmentSpace()}
                  <div className="flex-row">
                    <div className="column medium">
                      <p className="text-sm">
                        <Trans
                          i18nKey="houseKeepingTab:tipTeam"
                          components={{
                            tip: <span />,
                            moreThan: <span className="no-wrap" />,
                            perBoard: <span />,
                            team: <span className="badge-text accent-blue" />,
                            offers: <span />,
                            space: <span className="no-wrap" />,
                            teamBoard: <span />,
                          }}
                        />
                      </p>
                      <p className="text-sm faint-text">
                        <span className="fas fa-info-circle"></span>
                        <span>{t('importantNotice')}</span>
                      </p>
                      <NavLink
                        to="/team-add"
                        className="secondary-button"
                      >
                        <span className="text">{t('createATeam')}</span>
                      </NavLink>
                    </div>
                  </div>
                </div>
              )}
            </Accordion>
            <Accordion
              accordionSlug="board-archival"
              isOpen={false}
              iconClasses="fal fa-archive icon"
              title={t('boardArchival')}
              subheading={t('pauseAndHide')}
              translucent={true}
            >
              <div className="accordion-row pt-sm">
                <div className="flex-row">
                  <div className="column">
                    <p className="text-sm">{t('archivingThisBoard')}</p>
                  </div>
                </div>
                <div className="flex-row fill">
                  <div className="column">
                    <Button
                      className="secondary-button"
                      type="submit"
                      disabled={this.state.archiveStatus === 'loading'}
                      onClick={() =>
                        this.archiveBoard(this.props.match.params.routeBoardId)
                      }
                    >
                      <RequestStatus status={this.state.archiveStatus} />
                      <span className="text">{t('archiveThisBoard')}</span>{' '}
                    </Button>
                  </div>
                </div>
              </div>
            </Accordion>
          </div>
        </div>
      </>
    );
  }
}

const AppContextAdapter = {
  ctx: AppContext,
  adapt: (ctx: IAppContext): AppContextProps => {
    return {
      setMessages: ctx.notifications.setMessages!,
    };
  },
};
const BoardContextAdapter = {
  ctx: BoardContext,
  adapt: (ctx: IBoardContext): BoardContextProps => {
    return {
      boardId: ctx.board.id,
      currentUserRole: ctx.board.user.role,
      setArchived: ctx.setArchived!,
      reloadBoard: ctx.reloadBoard,
      board: ctx.board,
      boardMembers: ctx.board.members,
    };
  },
};
export default withContextAdapters<
  ExternalProps,
  IAppContext,
  AppContextProps,
  IBoardContext,
  BoardContextProps
>(
  withStyledTranslation('houseKeepingTab')(HouseKeepingTab),
  AppContextAdapter,
  BoardContextAdapter,
);
