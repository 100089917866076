import React from 'react';
import TagManageMenu from '../../menus/Tag/TagManageMenu';
import { TagDTO } from '../../../common/api/dtos/Tag';
import { TContextMenu } from '../../../common/types/ContextMenu';
import { withContextAdapter } from '../ContextAdapter/withContextAdapter';
import BoardContext from '../../../common/contexts/BoardContext';
import { IBoardContext } from '../../../common/interfaces/BoardContext';

interface ContextProps {
  tags: TagDTO[];
}
interface ExternalProps {
  setSelectedContext?: (context: TContextMenu) => void;
  setSelectedTag: (tag: TagDTO) => void;
}
interface Props extends ExternalProps, ContextProps {}

class TagsListUnorderable extends React.Component<Props> {
  render() {
    return (
      <ul className="control-list-component vertical">
        {this.props.tags.map((tag: TagDTO) => {
          return (
            <TagManageMenu
              key={tag.id}
              tag={tag}
              setSelectedContext={this.props.setSelectedContext}
              setSelectedTag={this.props.setSelectedTag}
            />
          );
        })}
      </ul>
    );
  }
}

export default withContextAdapter<ExternalProps, IBoardContext, ContextProps>(
  TagsListUnorderable,
  BoardContext,
  (ctx: IBoardContext) => {
    return {
      tags: ctx.board.tags,
    };
  },
);
