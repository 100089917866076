import { Component } from 'react';
import { RouteComponentProps } from 'react-router-dom';
import MenuTabs from '../../../controls/MenuTabs/MenuTabs';
import TeamGeneral from '../../../partials/Team/TeamGeneral';
import TeamMembers from '../../../partials/Team/TeamMembers';
import TeamPlan from '../../../partials/Team/TeamPlan';
import TeamHousekeeping from '../../../partials/Team/TeamHousekeeping';
import { getTeam } from '../../../../common/api/endpoints/team';
import { TeamDTO } from '../../../../common/api/dtos/Team';
import { TRequestStatus } from '../../../../common/types/RequestStatus';
import { listBoards } from '../../../../common/api/endpoints/board';
import { BoardDTO } from '../../../../common/api/dtos/Board';
import Toolbar from '../../../partials/Toolbar/Toolbar';
import { withStyledTranslation } from '../../../partials/StyledTranslation/StyledTranslation';
import { WithTranslation } from 'react-i18next';
import AppContext from '../../../../common/contexts/AppContext';
import CircleOff from '../../../icons/circle_off';
import CircleOn from '../../../icons/circle_on';

interface RouteParams {
  id?: string;
}

interface State {
  status: TRequestStatus;
  team: TeamDTO | null;
  loadingTeamBoards: TRequestStatus;
  teamBoards: BoardDTO[];
}

export interface Props
  extends RouteComponentProps<RouteParams>,
    WithTranslation {}

class TeamSettings extends Component<Props, State> {
  constructor(props: Props) {
    super(props);

    this.state = {
      status: 'loading',
      team: null,
      teamBoards: [],
      loadingTeamBoards: 'loading',
    };
  }
  componentDidMount() {
    this.fetchTeam();
    this.fetchTeamBoards();
  }

  fetchTeam = async () => {
    this.setState({
      status: 'loading',
    });

    try {
      const data = await getTeam(this.props.match.params.id!);

      this.setState({
        status: 'success',
        team: data,
      });
    } catch (err: any) {
      console.debug(err);
      if (err[0].code === 'not_found') {
        this.props.history.push('/not-found');
      }
    }
  };

  fetchTeamBoards = async () => {
    try {
      const data = await listBoards({
        name: 'boards',
        params: [
          {
            param: 'teamId',
            value: this.props.match.params.id!,
          },
        ],
      });

      this.setState({
        teamBoards: data.boards,
        loadingTeamBoards: 'success',
      });
    } catch (err) {
      console.debug(err);
    }
  };

  setData = (data: TeamDTO) => {
    this.setState({
      team: data,
    });
  };

  render() {
    const { t } = this.props;
    if (!this.state.team) {
      return <></>;
    }

    return (
      <div className="flex-row fill">
        <Toolbar>{this.state.team?.name}</Toolbar>
        <div className="column pt-0">
          <div className="flex-row">
            <div className="column pb-xs">
              <h2 className="primary-title h3 normalcase">
                {t('settingsTitle')}
              </h2>
            </div>
          </div>
          <MenuTabs
            parentUrl={this.props.match.url}
            vertical={true}
            tabs={[
              {
                id: 'general',
                label: t('generalTabLabel'),
                icon: 'fal fa-circle',
                animatedOffIcon: <CircleOff />,
                animatedOnIcon: <CircleOn />,
                content: (
                  <TeamGeneral
                    data={this.state.team}
                    setData={this.setData}
                    history={this.props.history}
                  />
                ),
              },
              {
                id: 'team-members',
                label: t('membersTabLabel'),
                icon: 'fal fa-users',
                content: (
                  <TeamMembers
                    data={this.state.team}
                    fetchTeam={this.fetchTeam}
                    history={this.props.history}
                  />
                ),
              },
              ...(this.state.team?.owned
                ? [
                    {
                      id: 'plan-and-billing',
                      label: t('planBillingTabLabel'),
                      icon: 'fal fa-coins',
                      content: (
                        <TeamPlan
                          data={this.state.team}
                          fetchTeam={this.fetchTeam}
                          status={this.state.status}
                          history={this.props.history}
                        />
                      ),
                    },
                    {
                      id: 'housekeeping',
                      label: t('housekeepingTabLabel'),
                      icon: 'fal fa-wrench',
                      content: (
                        <TeamHousekeeping
                          data={this.state.team}
                          status={this.state.status}
                          teamBoards={this.state.teamBoards}
                          loadingTeamBoards={this.state.loadingTeamBoards}
                          history={this.props.history}
                        />
                      ),
                    },
                  ]
                : []),
            ]}
            defaultTab="general"
            {...this.props}
          />
        </div>
      </div>
    );
  }
}

export default withStyledTranslation('teamSettings')(TeamSettings);
TeamSettings.contextType = AppContext;
