import React, { useMemo, useCallback } from 'react';
import { PriorityDTO } from '../../../../../common/api/dtos/Priority';
import ContextMenu from '../../../../controls/ContextMenu/ContextMenu';
import { getColorSymbol } from '../../../../../common/helpers/getColorSymbol';
import { PRIORITY_COLORS } from '../../../../../common/configs/priority';
import { TCardData } from '../../../../../common/types/CardData';
import RemovePriorityMenu from './partial/RemovePriorityMenu';
import { WithTranslation } from 'react-i18next';
import { withStyledTranslation } from '../../../StyledTranslation/StyledTranslation';

interface Props extends WithTranslation {
  cardId: string;
  title: string;
  number: number;
  prefix: string;
  description: string;
  selectedCardData: TCardData[];
  priority?: PriorityDTO;
  numberOfAttachments?: number;
  numberOfComments?: number;
  subtasks?: {
    checked: number;
    unchecked: number;
  };
}

const BoardCardDescription: React.FC<Props> = ({
  cardId,
  title,
  number,
  prefix,
  description,
  selectedCardData,
  priority,
  numberOfAttachments = 0,
  numberOfComments = 0,
  subtasks = {
    checked: 0,
    unchecked: 0,
  },
  t,
}) => {
  // Generate card ID
  const cardIdString = useMemo(
    () => (prefix ? `#${prefix}-${number}` : `#${number}`),
    [prefix, number],
  );

  // Check if the icon bar should be visible
  const isIconBarVisible = useMemo(() => {
    return (
      (selectedCardData.includes('description') && description) ||
      (selectedCardData.includes('attachment') && numberOfAttachments > 0) ||
      (selectedCardData.includes('comment') && numberOfComments > 0)
    );
  }, [selectedCardData, description, numberOfAttachments, numberOfComments]);

  // Get color symbol for priority
  const symbol = useMemo(() => {
    return getColorSymbol(PRIORITY_COLORS, priority?.color || '');
  }, [priority]);

  const color = useMemo(() => {
    return PRIORITY_COLORS.find((color) => color.id === priority?.color)?.color;
  }, [priority]);

  const renderSubtaskProgress = useCallback(() => {
    const progressPercentage =
      subtasks.checked + subtasks.unchecked > 0
        ? (subtasks.checked / (subtasks.checked + subtasks.unchecked)) * 100
        : 0;

    if (
      !selectedCardData.includes('subtaskProgress') ||
      subtasks.checked + subtasks.unchecked === 0
    ) {
      return null;
    }

    return (
      <div
        className="flex-row fill"
        style={{ marginBottom: '-2px' }}
      >
        <div className="column pb-0 flex-v-center">
          <div
            className="progress-bar-component mb-xs"
            title={`${progressPercentage.toFixed(2)}%`}
            data-title={`${progressPercentage.toFixed(2)}%`}
            style={{ width: '100%' }}
          >
            <div className="track pe-none">
              <div
                className="progress"
                style={{ width: `${progressPercentage}%` }}
              ></div>
            </div>
          </div>
        </div>
      </div>
    );
  }, [subtasks, selectedCardData]);

  // Render the icon bar if necessary
  const renderIconBar = useCallback(() => {
    if (!isIconBarVisible) return null;
    return (
      <div className="flex-row fill">
        <div className="column pb-0 flex-h-start">
          <ul className="icon-list-component">
            {selectedCardData.includes('description') && description && (
              <li>
                <span
                  className="far fa-text"
                  title={t('hasDescription')}
                ></span>
              </li>
            )}
            {selectedCardData.includes('attachment') &&
              numberOfAttachments >= 1 && (
                <li>
                  <span
                    className="far fa-paperclip"
                    title={t('hasAttachments', {
                      count: numberOfAttachments,
                    })}
                  ></span>
                </li>
              )}
            {selectedCardData.includes('comment') && numberOfComments > 0 && (
              <li>
                <span
                  className="fal fa-comment"
                  title={t('hasComments', {
                    count: numberOfComments,
                  })}
                ></span>
              </li>
            )}
            {selectedCardData.includes('subtask') &&
              subtasks.checked + subtasks.unchecked !== 0 && (
                <li>
                  <span
                    className="fal fa-tasks"
                    title={t('hasSubtasks', {
                      count: Number(subtasks.checked + subtasks.unchecked || 0),
                    })}
                  ></span>
                </li>
              )}
          </ul>
        </div>
      </div>
    );
  }, [
    isIconBarVisible,
    selectedCardData,
    description,
    numberOfAttachments,
    numberOfComments,
    subtasks,
    t,
  ]);

  return (
    <>
      <div className="flex-row fill">
        <div className="column pb-2xs pt-0 flex-v-center">
          {selectedCardData.includes('priority') &&
            (priority ? (
              <ContextMenu
                dept={0}
                contextId={String(number)}
                contextMenuClassName="align-h-start"
                triggerClassDefault="ghost-button"
                triggerClassActive="secondary-button"
                isDisabled={!priority}
                title={priority ? priority.name : 'None'}
                triggerContent={
                  <div className={`pe-none text-xs accent-text-${color}`}>
                    {priority && (
                      <>
                        <span className={`${symbol} pe-none`}></span>
                        &nbsp;
                      </>
                    )}
                  </div>
                }
                rightClickTrigger={true}
              >
                <RemovePriorityMenu cardId={cardId} />
              </ContextMenu>
            ) : (
              <div className={`pe-none text-xs`}>
                <span className="fal fa-circle pe-none"></span>
                &nbsp;
              </div>
            ))}
          {selectedCardData.includes('cardNumber') && (
            <small className="faint-text">{cardIdString}</small>
          )}
        </div>
      </div>

      <p
        className="mb-0"
        style={{ whiteSpace: 'normal' }}
      >
        {title}
      </p>
      {renderIconBar()}
      {renderSubtaskProgress()}
    </>
  );
};

export default withStyledTranslation('boardCardDescription')(
  BoardCardDescription,
);
