/**
 * Default values for user experiments.
 */
export function getDefaultExperiments() {
  return {
    imageBoardThumbs: false,
    detachableFlyout: false,
    showTranslucency: true,
  };
}
