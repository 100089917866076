import React, { memo, useEffect, useRef, useState, useCallback } from 'react';
import {
  DraggableProvided,
  DraggableStateSnapshot,
} from '../../../../vendors/wavemyth/react-beautiful-dnd/src';
import { InviteeDTO, MemberDTO } from '../../../../common/api/dtos/Member';
import { PriorityDTO } from '../../../../common/api/dtos/Priority';
import { TagDTO } from '../../../../common/api/dtos/Tag';
import { ICard } from '../../../../common/interfaces/BoardContext';

import { unite } from '../../../../common/helpers/unite';
import { getDropStyle } from '../../../../common/helpers/getDropStyle';
import { TCardData } from '../../../../common/types/CardData';
import BoardCardDescription from './BoardCardContent/BoardCardDescription';
import BoardCardMembers from './BoardCardContent/BoardCardMembers';
import BoardCardTags from './BoardCardContent/BoardCardTags';
import scrollToCard from '../../../../common/helpers/scrollToCard';
import { getScrollBehavior } from '../../../../common/helpers/getScrollBehavior';

interface BoardCardProps {
  selected: boolean;
  disabled: boolean;
  cardNrPrefix: string;
  boardCard: ICard;
  columnId: string | null;
  tags: TagDTO[];
  priorities: PriorityDTO[];
  members: (MemberDTO | InviteeDTO)[];
  selectCard: (columnId: string | null, cardId: string) => void;
  setCardRef: (
    cardId: string,
    cardRef: React.RefObject<HTMLDivElement>,
  ) => void;
  provided?: DraggableProvided;
  innerRef?: (element: HTMLElement | null) => void;
  snapshot?: DraggableStateSnapshot;
  selectedCardData: TCardData[];
  isClone?: boolean;
  isPeekView?: boolean;
}

const BoardCard: React.FC<BoardCardProps> = memo(
  ({
    boardCard,
    selected,
    disabled,
    provided,
    innerRef,
    snapshot,
    selectedCardData,
    members,
    tags,
    priorities,
    cardNrPrefix,
    selectCard,
    columnId,
    setCardRef,
    isPeekView = false,
  }) => {
    const cardRef = useRef<HTMLDivElement>(null);

    // Event handler for clicking the card
    const handleCardClick = useCallback(() => {
      const contextMenu = document.querySelector(
        '.context-menu-component',
      ) as HTMLElement;
      if (contextMenu && contextMenu.dataset.contextId === boardCard.id) return;
      selectCard(columnId, boardCard.id);
    }, [selectCard, columnId, boardCard.id]);

    // Keyboard "Enter" event handler
    const handleCardEnter = useCallback(
      (e: React.KeyboardEvent) => {
        if (e.key === 'Enter') {
          handleCardClick();
        }
      },
      [handleCardClick],
    );

    // Handle focus via keyboard
    const handleCardFocus = useCallback(
      (e: KeyboardEvent) => {
        if (
          e.key === 'Tab' &&
          !selected &&
          e.target === cardRef.current?.parentNode
        ) {
          scrollToCard(cardRef.current, getScrollBehavior());
        }
      },
      [selected],
    );

    // Effect: Setting card reference and adding focus listener
    useEffect(() => {
      setCardRef(boardCard.id, cardRef);
      document.addEventListener('keyup', handleCardFocus);
      return () => {
        document.removeEventListener('keyup', handleCardFocus);
      };
    }, [boardCard.id, setCardRef, handleCardFocus]);

    // Retrieve selected tags and priority
    const selectedTags = boardCard.tagIds.map((tagId) =>
      tags.find((tag) => tag.id === tagId),
    ) as TagDTO[];
    const priority = priorities.find((p) => p.id === boardCard.priorityId);

    return (
      <div
        ref={innerRef}
        {...provided?.draggableProps}
        {...provided?.dragHandleProps}
        style={getDropStyle(provided?.draggableProps.style, snapshot)}
        onClick={handleCardClick}
        onKeyDown={handleCardEnter}
        className={`card-drag-helper ${isPeekView === true ? 'peekview' : ''}`}
      >
        <div
          className={unite('form-group', 'card-board-button', 'tight', {
            dragging: snapshot?.isDragging,
            'flashing-border': selected,
          })}
          ref={cardRef}
          role="button"
        >
          <BoardCardDescription
            priority={priority}
            cardId={boardCard.id}
            prefix={cardNrPrefix}
            number={boardCard.number}
            title={boardCard.title}
            description={boardCard.description}
            selectedCardData={selectedCardData}
            numberOfAttachments={boardCard.numberOfAttachments}
            numberOfComments={boardCard.numberOfComments}
            subtasks={boardCard.subtasks}
          />
          {selectedCardData.includes('member') && (
            <BoardCardMembers
              cardId={boardCard.id}
              assigneeIds={boardCard.assigneeIds}
              members={members}
              isArchived={disabled}
            />
          )}
          {selectedCardData.includes('tag') && (
            <BoardCardTags
              tags={selectedTags}
              cardId={boardCard.id}
              tagIds={boardCard.tagIds}
              disabled={disabled}
            />
          )}
        </div>
      </div>
    );
  },
  (prevProps, nextProps) => {
    // Memoize the component based on shallow prop comparison
    return (
      prevProps.boardCard === nextProps.boardCard &&
      prevProps.selected === nextProps.selected &&
      prevProps.disabled === nextProps.disabled &&
      prevProps.snapshot === nextProps.snapshot &&
      prevProps.selectedCardData === nextProps.selectedCardData &&
      prevProps.members === nextProps.members &&
      prevProps.tags === nextProps.tags &&
      prevProps.priorities === nextProps.priorities &&
      prevProps.cardNrPrefix === nextProps.cardNrPrefix &&
      prevProps.columnId === nextProps.columnId &&
      prevProps.provided === nextProps.provided
    );
  },
);

export default BoardCard;
