import React, { Component, FormEvent } from 'react';
import { RouteComponentProps } from 'react-router-dom';
import TextBox from '../../../controls/TextBox/TextBox';
import CheckBox from '../../../controls/CheckBox/CheckBox';
import Button from '../../../controls/Button/Button';
import { RegisterUserDTO } from '../../../../common/api/dtos/User';
import {
  register,
  resendConfirmation,
} from '../../../../common/api/endpoints/register';
import { TRequestStatus } from '../../../../common/types/RequestStatus';
import RequestStatus from '../../../partials/RequestStatus/RequestStatus';
import LinkButton from '../../../controls/LinkButton/LinkButton';
import LegalFooter from '../../../partials/Legal/LegalFooter';
import { withStyledTranslation } from '../../../partials/StyledTranslation/StyledTranslation';
import { Trans, WithTranslation } from 'react-i18next';
import Joi from 'joi';
import { processJoiFieldError } from '../../../../common/helpers/processJoiFieldError';
import { processJoiError } from '../../../../common/helpers/processJoiError';
import { showErrorNotifications } from '../../../../common/helpers/showNotifications';
import AppContext from '../../../../common/contexts/AppContext';
import { FormErrorMsgs } from '../../../../common/configs/FormErrors';
import { customPasswordValidation } from './helper/customPasswordValidation';
import Stepper from '../../../controls/Stepper/Stepper';
import { login } from '../../../../common/api/endpoints/user';
import { customEmailValidation } from './helper/customEmailValidation';
import style from '../../../../vendors/wavemyth/react-beautiful-dnd/src/state/middleware/style';

interface FormData {
  name: string;
  email: string;
  password: string;
  emailConsent: boolean;
}

interface PasswordMessage {
  message: string;
  isValid: boolean;
}

interface State {
  currentStep: string;
  formData: FormData;
  formErrors: FormErrors;
  status: TRequestStatus;
  serverErrors: string[];
  sendConfirmationStatus: TRequestStatus;
  togglePass: boolean;
  passwordMessages: PasswordMessage[];
}

type FormErrors = {
  [key in keyof FormData]?: string | JSX.Element;
};

export interface Props extends RouteComponentProps, WithTranslation {
  unlockApp: () => void;
}

class Registration extends Component<Props, State> {
  static contextType = AppContext;

  step1Schema = Joi.object({
    name: Joi.string().required().trim().messages(FormErrorMsgs.string),
    email: Joi.string()
      .email({ minDomainSegments: 2, tlds: { allow: false } })
      .custom(customEmailValidation)
      .required()
      .trim()
      .messages(FormErrorMsgs.string),
    password: Joi.string()
      .empty('')
      .custom((value) => customPasswordValidation(this.props.t, value)),
  });

  step2Schema = Joi.object({
    emailConsent: Joi.boolean()
      .label('Accepting the terms, conditions, and privacy policy')
      .messages(FormErrorMsgs.boolean),
  });

  constructor(props: Props) {
    super(props);
    this.state = {
      currentStep: 'step1',
      formData: {
        name: '',
        email: '',
        password: '',
        emailConsent: false,
      },
      formErrors: {},
      status: 'idle',
      serverErrors: [],
      sendConfirmationStatus: 'idle',
      togglePass: false,
      passwordMessages: [],
    };
  }

  handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value, type, checked } = event.target;
    this.setState(
      (prevState) => ({
        formData: {
          ...prevState.formData,
          [name]: type === 'checkbox' ? checked : value,
        },
      }),
      () => {
        if (name === 'password') {
          this.validatePasswordField(value);
        } else {
          this.validateFormField(name as keyof FormData);
        }
      },
    );
  };

  handleBlur = (event: React.FocusEvent<HTMLInputElement>) => {
    if (event.target.name === 'password') {
      this.validatePasswordField(event.target.value);
    } else {
      this.validateFormField(event.target.name as keyof FormData);
    }
  };

  validatePasswordField = (value: string) => {
    const passwordMessages = this.step1Schema.validate(
      { password: value },
      { abortEarly: false },
    );

    if (!passwordMessages.value.password) {
      const defaultMessages = customPasswordValidation(this.props.t, value);
      this.setState({ passwordMessages: defaultMessages });
    } else {
      this.setState({ passwordMessages: passwordMessages.value.password });
    }

    if (passwordMessages.value.hasOwnProperty('password')) {
      return !!!passwordMessages.value.password?.filter(
        (set: { message: string; isValid: boolean }) => !set.isValid,
      ).length;
    } else {
      return false;
    }
  };

  validateFormField = <K extends keyof FormData>(field: K) => {
    const schema = this.getCurrentStepSchema();
    const result = schema.validate(
      { [field]: this.state.formData[field] },
      { abortEarly: false },
    );

    if (result.error) {
      const errorMessage = processJoiFieldError(result.error, field);
      this.setState((prevState) => ({
        formErrors: {
          ...prevState.formErrors,
          [field]: errorMessage,
        },
      }));
    } else {
      this.setState((prevState) => ({
        formErrors: {
          ...prevState.formErrors,
          [field]: undefined,
        },
      }));
    }
  };

  validateForm = () => {
    const schema = this.getCurrentStepSchema();
    const formData = this.getFilteredFormData();
    this.setState({ formErrors: {} });
    const result = schema.validate(formData, {
      abortEarly: false,
    });

    if (result.error) {
      const formErrors = processJoiError(result.error);
      this.setState({ formErrors: formErrors as FormErrors });
      return false;
    }
    return true;
  };

  getFilteredFormData = () => {
    const { currentStep, formData } = this.state;
    if (currentStep === 'step1') {
      return {
        name: formData.name,
        email: formData.email,
        password: formData.password,
      };
    } else if (currentStep === 'step2') {
      return {
        emailConsent: formData.emailConsent,
      };
    }
    return formData;
  };

  getCurrentStepSchema = () => {
    const { currentStep } = this.state;
    return currentStep === 'step1' ? this.step1Schema : this.step2Schema;
  };

  handleNext = () => {
    const isValid = this.validateForm();
    const isPassValid = this.validatePasswordField(
      this.state.formData.password,
    );
    if (isValid && isPassValid) {
      this.setState((prevState) => ({
        currentStep: prevState.currentStep === 'step1' ? 'step2' : 'step3',
      }));
    }
    return isValid;
  };

  handlePrev = () => {
    this.setState((prevState) => ({
      currentStep: prevState.currentStep === 'step2' ? 'step1' : 'step2',
    }));
  };

  handleSubmit = async (e: FormEvent) => {
    e.preventDefault();
    const isValid = this.validateForm();

    if (isValid) {
      this.setState({ status: 'loading' });
      try {
        const response = await register(this.state.formData as RegisterUserDTO);
        await this.handleLogin(response);
        this.setState({ status: 'success' });
      } catch (error) {
        const err = Array.isArray(error) ? error : [error];

        let uniqueErrorMessage: string | undefined;

        // Check if any error has validationCode "request.unique"
        const hasUniqueError = err.some((e: any) => {
          if (e.validationCode === 'request.unique') {
            uniqueErrorMessage = e.message; // Store the specific error message
            return true;
          }
          return false;
        });

        if (hasUniqueError) {
          this.setState((prevState) => ({
            status: 'error',
            serverErrors: [
              uniqueErrorMessage || 'Registration failed. Please try again.',
            ],
            currentStep: 'step1',
            formErrors: {
              ...prevState.formErrors,
              email: (
                <Trans
                  i18nKey="registration:emailAlreadyTaken"
                  components={{
                    a: (
                      <LinkButton
                        to="/login"
                        className="link-button"
                      />
                    ),
                    span: <span className="text"></span>,
                  }}
                />
              ),
            },
          }));
        } else {
          this.setState({
            status: 'error',
            serverErrors: ['Registration failed. Please try again.'],
          });

          showErrorNotifications(err, this.context.notifications.setMessages);
        }
      }
    }
  };

  handleLogin = async (registerResponse: any) => {
    const { email, password } = this.state.formData;
    const body = {
      email,
      password,
    };
    try {
      const loginData = await login(body);
      const sessionExpirationMs = Date.now() + loginData.expiresIn;
      localStorage.setItem(
        'session_expiration_ms',
        String(sessionExpirationMs),
      );

      const { teamIds, boardIds } = registerResponse;
      if (boardIds && boardIds.length > 0) {
        this.props.history.push(`/board/${boardIds[0]}/view`);
      } else if (teamIds && teamIds.length > 0) {
        this.props.unlockApp();
        this.props.history.push('/');
      } else {
        this.props.history.push('/onboarding');
      }
    } catch (error) {
      showErrorNotifications([error], this.context.notifications.setMessages);
    }
  };

  resendConfirmationLink = async () => {
    this.setState({ sendConfirmationStatus: 'loading' });
    try {
      await resendConfirmation(this.state.formData.email);
      this.setState({ sendConfirmationStatus: 'success' });
    } catch (error) {
      const err = Array.isArray(error) ? error : [error];
      showErrorNotifications(err, this.context.notifications.setMessages);
      this.setState({
        sendConfirmationStatus: 'error',
        serverErrors: ['Failed to resend confirmation link.'],
      });
    } finally {
      setTimeout(() => {
        this.setState({ sendConfirmationStatus: 'idle' });
      }, 5000);
    }
  };

  renderSteps = () => {
    const { formData, formErrors, status, passwordMessages, togglePass } =
      this.state;
    const { t } = this.props;

    return {
      step1: (
        <div className="step">
          <div className="flex-row fill">
            <div className="column flex-v-center flex-h-center">
              <div>
                <div style={{}}>
                  <div>
                    <img
                      className="mt-sm mb-xs"
                      style={{
                        filter: 'drop-shadow(2px 2px 8px #fffc)',
                      }}
                      src={
                        process.env.PUBLIC_URL +
                        '/' +
                        process.env.REACT_APP_LOGO
                      }
                      alt="Borddo"
                    />
                    <div className="text-left mt-md hidden-mobile">
                      <p className="text-md mb-sm">
                        <span className="fas fa-reply fa-flip-horizontal fa-flip-vertical"></span>{' '}
                        <span>{t('allYourStuffOneSpot')}</span>{' '}
                      </p>
                      <p className="text-lg mb-sm">
                        <span>{t('teamworkMadeEasy')}</span>{' '}
                        <span className="fas fa-reply fa-flip-vertical"></span>
                      </p>
                      <p className="text-xl mb-sm">
                        <span className="fas fa-reply fa-flip-horizontal fa-flip-vertical"></span>{' '}
                        <span>{t('projectsOnTheGo')}</span>{' '}
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="column large text-left">
              <h1 className="text-xl text-500 mb-sm">
                <span>{t('embraceSimplicity')}</span>{' '}
                <span
                  className="text-700"
                  style={{
                    textDecoration: 'underline',
                  }}
                >
                  {t('achieveMore')}
                </span>
                <span>!</span>
              </h1>
              <p className="text-sm mb-md">
                <span className="fas fa-check-circle"></span>{' '}
                <span>{t('noCommitment')}</span>{' '}
                <span className="pl-xs fas fa-check-circle"></span>{' '}
                <span>{t('noCreditCardRequired')}</span>
              </p>
              <TextBox
                label={t('nameLabel')}
                type="text"
                name="name"
                id="name"
                value={formData.name}
                onChange={this.handleChange}
                disabled={status === 'loading'}
                error={formErrors.name as string}
                br={true}
                autoComplete="new-password"
                maxLength={50}
              />
              <TextBox
                label={t('emailLabel')}
                type="email"
                name="email"
                id="email"
                value={formData.email}
                onChange={this.handleChange}
                disabled={status === 'loading'}
                error={formErrors.email as string}
                br={true}
                autoComplete="new-password"
              />
              <TextBox
                label={t('passwordLabel')}
                type={togglePass ? 'text' : 'password'}
                name="password"
                id="password"
                value={formData.password}
                onChange={this.handleChange}
                onBlur={this.handleBlur}
                onFocus={this.handleBlur}
                disabled={status === 'loading'}
                error={formErrors.password as string}
                br={true}
                autoComplete="new-password"
                inputIcon={togglePass ? 'far fa-eye-slash' : 'far fa-eye'}
                onIconClick={() => this.setState({ togglePass: !togglePass })}
                togglePass={togglePass}
                passErrors={passwordMessages}
              />
              <ul className="control-list-component horiontal">
                <li>
                  <Button
                    className="primary-button"
                    onClick={this.handleNext}
                  >
                    {t('next')}
                  </Button>
                </li>
              </ul>
            </div>
          </div>
        </div>
      ),
      step2: (
        <div className="step">
          <div className="flex-row fill">
            <div className="column flex-v-center flex-h-center">
              <span className="oobe-icon icon bg-text fad fa-envelope"></span>
            </div>
            <div className="column large text-left flex-v-center">
              <div>
                <h1 className="text-xl text-500 mb-sm">
                  <span>{t('stayingInTouch')}</span>
                </h1>
                <CheckBox
                  id="emailConsent"
                  name="emailConsent"
                  checked={formData.emailConsent}
                  changeMethod={this.handleChange}
                  disabled={status === 'loading'}
                />
                <label htmlFor="emailConsent">
                  {t('communicationAgreement')}
                </label>
                <ul className="control-list-component horiontal mt-sm">
                  <li>
                    <Button
                      className="primary-button"
                      onClick={this.handlePrev}
                    >
                      {t('back')}
                    </Button>
                  </li>
                  <li>
                    <Button
                      type="submit"
                      className="primary-button"
                      onClick={this.handleSubmit}
                    >
                      <RequestStatus status={status} />
                      <span className="text"> </span> {t('register')}
                    </Button>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      ),
      step3: (
        <div className="step">
          <div className="flex-row fill">
            <div className="column flex-v-center flex-h-center">
              <span className="oobe-icon icon bg-text fad fa-envelope-open-text"></span>
            </div>
            <div className="column large text-left flex-v-center">
              <div>
                <h1 className="text-xl text-500 mb-sm">
                  <span>{t('confirmationLinkSentTitle')}</span>
                </h1>
                <p>{t('confirmationLinkSentMessage')}</p>
                <Button
                  className="primary-button"
                  onClick={this.resendConfirmationLink}
                >
                  <RequestStatus status={this.state.sendConfirmationStatus} />
                  <span className="text">{t('sendAgainButtonText')}</span>
                </Button>
              </div>
            </div>
          </div>
        </div>
      ),
    };
  };

  render() {
    const { status, currentStep } = this.state;
    const { t } = this.props;

    return (
      <div className="oobe-component">
        <div className="oobe-inner">
          <Stepper
            steps={this.renderSteps()}
            currentStep={currentStep}
          />
          <div className="my-xs">
            <LinkButton
              to="/"
              className="link-button"
            >
              <span className="text">{t('alreadyHaveAccountText')}</span>
            </LinkButton>
          </div>
          <div className="flex-row fill">
            <div className="column flex-h-center">
              <LegalFooter />
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default withStyledTranslation('registration')(Registration);
