import { ChangeEvent, Component, FormEvent } from 'react';
import AppContext from '../../../../common/contexts/AppContext';
import { FormErrorMsgs } from '../../../../common/configs/FormErrors';
import { processJoiFieldError } from '../../../../common/helpers/processJoiFieldError';
import { processJoiError } from '../../../../common/helpers/processJoiError';
import { TRequestStatus } from '../../../../common/types/RequestStatus';
import TextBox from '../../../controls/TextBox/TextBox';
import Joi from 'joi';
import RequestStatus from '../../../partials/RequestStatus/RequestStatus';
import Button from '../../../controls/Button/Button';
import { NavLink, RouteComponentProps } from 'react-router-dom';
import { showErrorNotifications } from '../../../../common/helpers/showNotifications';
import NumberControl from '../../../controls/NumberControl/NumberControl';
import Breadcrumb from '../../../partials/Breadcrumb/Breadcrumb';

interface FormData {
  name: string;
  seats: number;
}

type FormErrors = {
  [key in keyof FormData]?: string;
};

type InputValue = ChangeEvent<HTMLInputElement> | number;

interface Props extends RouteComponentProps {}

interface State {
  formStatus: TRequestStatus;
  formData: FormData;
  formErrors: FormErrors;
}

class TeamAdd extends Component<Props, State> {
  formSchema = Joi.object({
    name: Joi.string()
      .pattern(/^[a-zA-Z0-9 ]+$/)
      .required()
      .trim(true)
      .messages({
        'string.pattern.base': FormErrorMsgs.teamName['string.pattern.base'],
        'string.empty': FormErrorMsgs.string['string.empty'],
        'any.required': FormErrorMsgs.string['any.required'],
      }),
    seats: Joi.number()
      .required()
      .min(2)
      .max(99)
      .messages(FormErrorMsgs.number),
  });

  constructor(props: Props) {
    super(props);

    this.state = {
      formStatus: 'idle',
      formData: {
        name: '',
        seats: 2,
      },
      formErrors: {},
    };
  }

  validateFormField = <K extends keyof FormData>(field: K) => {
    const result = this.formSchema.validate(this.state.formData, {
      abortEarly: false,
    });
    let errorMessage = '';

    if (result.error) {
      errorMessage = processJoiFieldError(result.error, field);
    }

    this.updateFormError(field, errorMessage);
  };

  validateForm = () => {
    // reset form errors
    this.setState({
      formErrors: {},
    });

    const result = this.formSchema.validate(this.state.formData, {
      abortEarly: false,
    });

    if (result.error) {
      const formErrors = processJoiError(result.error);
      this.setState({
        // Assume type based on formSchema and Joi's error
        formErrors: formErrors as FormErrors,
      });

      return false;
    }

    return true;
  };

  updateFormError<K extends keyof FormErrors>(field: K, value: FormErrors[K]) {
    this.setState((prevState) => {
      return {
        formErrors: {
          ...prevState.formErrors,
          [field]: value,
        },
      };
    });
  }

  updateForm<K extends keyof FormData>(field: K, value: FormData[K]) {
    const formData = this.state.formData;
    this.setState(
      {
        formData: {
          ...formData,
          [field]: value,
        },
      },
      () => {
        this.validateFormField(field);
      },
    );
  }

  setName = (ev: ChangeEvent<HTMLInputElement>) =>
    this.updateForm('name', ev.target.value);

  setSeats = (value: InputValue) => {
    const seatsValue =
      typeof value === 'number' ? value : Number(value.target.value);
    this.updateForm('seats', seatsValue);
  };

  handleSubmit = async (e: FormEvent) => {
    const isValid = this.validateForm();

    if (isValid) {
      this.setState({
        formStatus: 'loading',
      });

      try {
        this.setState({
          formStatus: 'success',
        });
      } catch (err) {
        this.setState(
          {
            formStatus: 'error',
          },
          () => {
            showErrorNotifications(err, this.context.notifications.setMessages);
          },
        );
      }
    } else {
      e.preventDefault();
    }
  };

  render() {
    return (
      <div className="flex-row pt-0">
        <div className="column py-0 small">
          <div style={{ position: 'sticky', top: '46px' }}>
            <div className="flex-row fill flex-v-center flex-h-spread">
              <div className="column pt-xs pb-0">
                <h2 className="primary-title h4 normalcase">
                  <img
                    alt="starred boards icon"
                    className="icon"
                    src={`${process.env.REACT_APP_ICONS_PATH}/starred-boards.svg`}
                  />{' '}
                  <span>Useful links</span>
                </h2>
              </div>
            </div>
            <div className="flex-row fill py-0">
              <div className="column navigation-component px-0">
                <ul className="nav-list">
                  <li className="nav-li">
                    <NavLink
                      to="/board-add"
                      className="nav-link"
                    >
                      <div className="content-wrapper py-3xs">
                        <span className="fal fa-layer-plus icon"></span>
                        <span className="link-text">Create board</span>
                      </div>
                    </NavLink>
                  </li>
                  <li className="nav-li">
                    <NavLink
                      to="/team-add"
                      className="nav-link"
                    >
                      <div className="content-wrapper py-3xs">
                        <span className="fal fa-plus-square icon"></span>
                        <span className="link-text">Create team</span>
                      </div>
                    </NavLink>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
        <div className="column py-0 largest">
          <div
            className="fill"
            style={{
              maxWidth: '1024px',
              width: '100%',
            }}
          >
            <div className="flex-row fill">
              <div className="column pb-0">
                <Breadcrumb
                  items={[
                    { label: 'Home', to: '/' },
                    {
                      label: (
                        <>
                          Creating team{' '}
                          <strong>{this.state.formData.name || ''}</strong>
                        </>
                      ),
                      isCurrent: true,
                    },
                  ]}
                />
              </div>
            </div>
            <div className="flex-row fill">
              <div className="column pb-xs">
                <form
                  onSubmit={(e) => this.handleSubmit(e)}
                  autoComplete="off"
                  noValidate={true}
                  method="POST"
                  action={`${process.env.REACT_APP_API_BASE_URL}/team`}
                >
                  <p className="mb-2xs">
                    <small>General Information</small>
                  </p>
                  <div className="card translucent mb-sm">
                    <TextBox
                      label="Name"
                      type="text"
                      name="name"
                      id="name"
                      maxLength={255}
                      disabled={this.state.formStatus === 'loading'}
                      onChange={this.setName}
                      value={this.state.formData.name}
                      required={true}
                      br={true}
                      placeholder="My awesome team"
                      onBlur={(ev) =>
                        this.validateFormField(ev.target.name as keyof FormData)
                      }
                      error={this.state.formErrors.name}
                    />
                    <NumberControl
                      label="Seats (min. 2)"
                      name="seats"
                      id="seats"
                      minValue={2}
                      maxValue={99}
                      placeholder="Number of seats"
                      disabled={this.state.formStatus === 'loading'}
                      onChange={this.setSeats}
                      onBlur={(ev) =>
                        this.validateFormField(ev.target.name as keyof FormData)
                      }
                      value={this.state.formData.seats}
                      required={true}
                      br={true}
                      error={this.state.formErrors.seats}
                    />
                  </div>
                  <p className="mb-2xs">
                    <small>Billing options</small>
                  </p>
                  <div className="card translucent mb-sm">
                    <small className="accent-text-blue">
                      (Not implemented)
                    </small>{' '}
                    <small>Monthly or Yearly . . . ?</small>
                  </div>
                  <p className="mb-2xs">
                    <small>Order summary</small>
                  </p>
                  <div className="card translucent mb-sm">
                    <small className="accent-text-blue">
                      (Not implemented)
                    </small>{' '}
                    <small>Calculate the price here . . .</small>
                  </div>
                  <ul className="control-list-component">
                    <li className="">
                      <Button
                        className="primary-button"
                        type="submit"
                        disabled={this.state.formStatus === 'loading'}
                      >
                        <RequestStatus status={this.state.formStatus} />
                        <span className="fas fa-shopping-bag icon"></span>
                        <span className="text">Checkout</span>
                      </Button>
                    </li>
                    <li className="flex-v-center">
                      <span className="pr-2xs text-xs">Secured by </span>
                      <span className="fab fa-stripe text-3xl"></span>
                    </li>
                  </ul>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default TeamAdd;
TeamAdd.contextType = AppContext;
