//A function for combining multiple classes into a single string.

export function unite(
  ...values: (string | Record<string, boolean | undefined>)[]
): string {
  return values.map(uniteInternal).join(' ');
}

function uniteInternal(
  value: string | Record<string, boolean | undefined>,
): string {
  if (typeof value === 'string') {
    return value;
  }
  if (typeof value === 'object') {
    return Object.keys(value)
      .filter((key) => value[key])
      .join(' ');
  }
  throw new Error(`Unexpected type for unite: ${typeof value}`);
}
