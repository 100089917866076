//Touched by Copilot
import React, { Component, KeyboardEvent, ReactNode, RefObject } from 'react';
import ReactDOM from 'react-dom';
import Button from '../../../controls/Button/Button';
import { AttachmentDTO } from '../../../../common/api/dtos/Card';
import isSupportedImageMimeType from '../../../../common/helpers/isSupportedImageMimeType';
import ImageZoomer from './ImageZoomer';
import convertBytes from '../../../../common/helpers/convertBytes';
import { WithTranslation } from 'react-i18next';
import { withStyledTranslation } from '../../StyledTranslation/StyledTranslation';
import ContextMenu from '../../../controls/ContextMenu/ContextMenu';

interface ImageViewerProps extends WithTranslation {
  activeAttachment: AttachmentDTO | null;
  attachments: AttachmentDTO[] | null;
  imageURL?: string;
  onClose: () => void;
}

interface ImageViewerState {
  scale: number;
  activeAttachment: AttachmentDTO | null;
  showReel: boolean;
  introDirection: 'prev' | 'next' | null;
  lastDirection: 'prev' | 'next' | null;
}

class ImageViewer extends Component<ImageViewerProps, ImageViewerState> {
  private thumbRefs: { [key: string]: RefObject<HTMLLIElement> };

  private modalRef: RefObject<HTMLDivElement>;

  constructor(props: ImageViewerProps) {
    super(props);
    this.thumbRefs = {};
    this.modalRef = React.createRef();
    this.state = {
      scale: 1,
      activeAttachment: null,
      showReel: true,
      introDirection: null,
      lastDirection: null,
    };
  }

  componentDidMount(): void {
    window.addEventListener(
      'keydown',
      this.handleKeyboard as unknown as EventListener,
    );
    document.addEventListener(
      'keydown',
      this.handleKeyDown as unknown as EventListener,
    );
    this.setState({
      activeAttachment: this.props.activeAttachment,
    });
  }

  componentWillUnmount(): void {
    window.removeEventListener(
      'keydown',
      this.handleKeyboard as unknown as EventListener,
    );
    document.removeEventListener(
      'keydown',
      this.handleKeyDown as unknown as EventListener,
    );
  }

  private handleKeyboard = (event: KeyboardEvent): void => {
    if (event.code === 'ArrowLeft') {
      event.preventDefault();
      this.navigateAttachments('prev');
    } else if (event.code === 'ArrowRight') {
      event.preventDefault();
      this.navigateAttachments('next');
    }
    if (event.key === 'Escape') {
      this.props.onClose();
    }
  };

  private handleKeyDown = (e: KeyboardEvent): void => {
    // trap focus
    if (e.key === 'Tab') {
      const modalItems = this.modalRef.current
        ? this.modalRef.current.querySelectorAll('button:not([disabled]), a')
        : [];

      const firstModalItem = modalItems[0] as HTMLElement;
      const lastModalItem = modalItems[modalItems.length - 1] as HTMLElement;

      if (
        e.shiftKey &&
        Array.prototype.indexOf.call(modalItems, document.activeElement) === -1
      ) {
        firstModalItem.focus();
        e.preventDefault();
      } else if (
        !e.shiftKey &&
        Array.prototype.indexOf.call(modalItems, document.activeElement) === -1
      ) {
        firstModalItem.focus();
        e.preventDefault();
      }
      if (!e.shiftKey && document.activeElement === lastModalItem) {
        firstModalItem.focus();
        e.preventDefault();
      } else if (e.shiftKey && document.activeElement === firstModalItem) {
        lastModalItem.focus();
        e.preventDefault();
      }
    }
  };

  private updateActiveAttachment = (attachment: AttachmentDTO): void => {
    this.setState(
      {
        scale: 1,
        activeAttachment: { ...attachment },
      },
      () => {
        this.scrollActiveThumbnailIntoView();
      },
    );
  };

  private scrollActiveThumbnailIntoView = (): void => {
    const activeAttachmentId = this.state.activeAttachment?.id;
    if (activeAttachmentId && this.thumbRefs[activeAttachmentId]?.current) {
      this.thumbRefs[activeAttachmentId].current!.scrollIntoView({
        behavior: 'smooth',
        block: 'nearest',
        inline: 'nearest',
      });
    }
  };

  private jumpToAttachment = (targetIndex: number): void => {
    if (this.state.introDirection !== null) {
      return;
    }

    if (!this.props.attachments || !this.state.activeAttachment) {
      return;
    }

    const currentIndex = this.props.attachments.findIndex(
      (attachment: AttachmentDTO) =>
        attachment.id === this.state.activeAttachment!.id,
    );

    if (currentIndex === targetIndex) {
      return;
    }

    const direction: 'prev' | 'next' =
      targetIndex < currentIndex ? 'prev' : 'next';

    this.updateActiveAttachment(this.props.attachments[targetIndex]);

    this.setState(
      {
        introDirection: direction,
        lastDirection: direction,
      },
      () => {
        setTimeout(() => {
          this.setState({
            introDirection: null,
          });
        }, 200);
      },
    );
  };

  private navigateAttachments = (direction: 'prev' | 'next'): void => {
    if (this.state.introDirection !== null) {
      return;
    }

    if (
      !this.props.attachments ||
      !this.state.activeAttachment ||
      this.props.attachments.length <= 1
    ) {
      return;
    }

    const currentIndex = this.props.attachments.findIndex(
      (attachment: AttachmentDTO) =>
        attachment.id === this.state.activeAttachment!.id,
    );

    let targetIndex;
    if (direction === 'next') {
      targetIndex =
        currentIndex < this.props.attachments.length - 1 ? currentIndex + 1 : 0;
    } else {
      targetIndex =
        currentIndex > 0 ? currentIndex - 1 : this.props.attachments.length - 1;
    }

    this.updateActiveAttachment(this.props.attachments[targetIndex]);

    this.setState(
      {
        introDirection: direction,
        lastDirection: direction,
      },
      () => {
        setTimeout(() => {
          this.setState({
            introDirection: null,
          });
        }, 200);
      },
    );
  };

  private toggleShowReel = (): void => {
    this.setState((prevState) => {
      return {
        showReel: !prevState.showReel,
      };
    });
  };

  private renderSize = (size: number): string => {
    const temp = convertBytes(size);
    return temp.value + ' ' + temp.unit;
  };

  render(): ReactNode {
    const { t } = this.props;
    const container = document.getElementById('imageViewer');
    if (!container) {
      return null;
    }

    const renderAttachmentInfo = () => {
      if (!this.state.activeAttachment || !this.state.activeAttachment.id) {
        return null;
      }

      return (
        <li>
          <small
            title={this.state.activeAttachment.filename}
            style={{
              maxWidth: 'calc(100vw - 270px)',
              marginTop: '-2px',
              display: 'block',
              textOverflow: 'ellipsis',
              overflow: 'hidden',
              whiteSpace: 'nowrap',
              lineHeight: '1.2',
            }}
          >
            {this.state.activeAttachment.filename}
          </small>
        </li>
      );
    };

    const renderDownloadButton = () => {
      const { t } = this.props;

      if (!this.state.activeAttachment || !this.state.activeAttachment.id) {
        return null;
      }

      return (
        <li>
          <ContextMenu
            dept={0}
            title="Attachment options"
            triggerContent={
              <>
                <span className="fas fa-ellipsis-h"></span>
              </>
            }
            triggerClassDefault="ghost-button"
            triggerClassActive="secondary-button"
          >
            <li>
              <a
                download
                target="_blank"
                rel="noreferrer"
                className="ghost-button"
                href={`${process.env.REACT_APP_API_BASE_URL}/attachment/${this.state.activeAttachment.id}`}
              >
                <span className="fal fa-download icon" />
                <span className="text">{t('download')}</span>
              </a>
            </li>

            <li>
              <hr />
            </li>
            <li>
              <small
                className="pe-none faint-text fill"
                style={{
                  maxWidth: '120px',
                  display: 'block',
                  textOverflow: 'ellipsis',
                  overflow: 'hidden',
                  whiteSpace: 'nowrap',
                }}
              >
                {this.state.activeAttachment.contentType}
                <br />
                {this.renderSize(this.state.activeAttachment.size)}
              </small>
            </li>
          </ContextMenu>
        </li>
      );
    };

    const renderShowReelButton = () => {
      const { t } = this.props;

      return (
        <li>
          <Button
            className="secondary-button"
            title={t(this.state.showReel ? 'hideReel' : 'showReel')}
            onClick={this.toggleShowReel}
          >
            <span
              className={`${
                this.state.showReel ? 'fas' : 'far'
              } fa-images pe-none`}
            ></span>
          </Button>
        </li>
      );
    };

    const renderCloseButton = () => {
      const { t } = this.props;

      return (
        <li>
          <Button
            className="secondary-button"
            title={t('closeViewer')}
            onClick={this.props.onClose}
          >
            <span className="fas fa-times pe-none"></span>
          </Button>
        </li>
      );
    };

    const renderImageZoomer = () => {
      return (
        <ImageZoomer
          src={
            this.props.activeAttachment
              ? `${process.env.REACT_APP_API_BASE_URL}/attachment/${this.state.activeAttachment?.id}`
              : `${this.props.imageURL}`
          }
          alt={this.state.activeAttachment?.filename}
          goToPrevious={() => this.navigateAttachments('prev')}
          goToNext={() => this.navigateAttachments('next')}
          introDirection={this.state.introDirection}
        />
      );
    };

    const renderNavigationButtons = () => {
      const { t, attachments } = this.props;

      if (!attachments || attachments.length <= 1) {
        return null;
      }

      return (
        <>
          <Button
            className="secondary-button nav-button left px-xs"
            title={t('previousImage')}
            onClick={() => this.navigateAttachments('prev')}
          >
            <span className="pe-none fal fa-angle-left text-3xl"></span>
          </Button>
          <Button
            title={t('nextImage')}
            className="secondary-button nav-button right px-xs"
            onClick={() => this.navigateAttachments('next')}
          >
            <span className="pe-none fal fa-angle-right text-3xl"></span>
          </Button>
        </>
      );
    };

    const renderThumbnailList = () => {
      if (!this.props.attachments) {
        return null;
      }

      return (
        <div className="navigation-component horizontal">
          <ul className="nav-list thumblist px-2xs pb-2xs">
            {this.props.attachments.map((attachment: AttachmentDTO, index) => {
              if (!this.thumbRefs[attachment.id]) {
                // Create a ref if it does not exist
                this.thumbRefs[attachment.id] = React.createRef();
              }
              return isSupportedImageMimeType(attachment.contentType) ? (
                <li
                  className="nav-li"
                  ref={this.thumbRefs[attachment.id]}
                  key={attachment.id}
                >
                  <Button
                    onClick={() => this.jumpToAttachment(index)}
                    title={attachment.filename}
                    className={`nav-link ${
                      this.state.activeAttachment &&
                      this.state.activeAttachment.id === attachment.id
                        ? 'active'
                        : ''
                    }
                        ${
                          this.state.lastDirection === 'prev' ? 'reverse' : ''
                        }`}
                  >
                    <div className="content-wrapper py-0 px-0 pe-none">
                      <img
                        src={`${process.env.REACT_APP_API_BASE_URL}/attachment/${attachment.id}`}
                        className="thumbnail cover release radius no-bg"
                      />
                    </div>
                  </Button>
                </li>
              ) : null;
            })}
          </ul>
        </div>
      );
    };

    return ReactDOM.createPortal(
      <div
        ref={this.modalRef}
        className={`image-viewer-component ${
          !this.state.showReel ? 'hide-thumblist' : ''
        }`}
      >
        <div
          className="image-viewer-backdrop"
          style={{
            backgroundImage: `url(${process.env.REACT_APP_API_BASE_URL}/attachment/${this.state.activeAttachment?.id})`,
            filter: `blur(100px) brightness(0.33)`,
          }}
        ></div>
        <ul className="control-list-component top-tight pt-2xs left-tight x-auto">
          <li className="my-0">{renderShowReelButton()}</li>
        </ul>
        <ul className="control-list-component top-tight right-tight x-auto">
          {renderAttachmentInfo()}
          {renderDownloadButton()}
          {renderCloseButton()}
        </ul>
        {renderImageZoomer()}
        {renderNavigationButtons()}
        {renderThumbnailList()}
      </div>,
      container,
    );
  }
}

export default withStyledTranslation('imageViewer')(ImageViewer);
